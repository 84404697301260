export function getInitialValues() {
  return {
    nombreUsuario: '',
    apellidoUsuario: '',
    razonSocialEmpresa: '',
    identificadorFiscalEmpresa: '',
    direccion: '',
    direccionInfo: null,
    actividadPrincipal: '',
    nombreEmpresa: '',
    telefonoUsuario: '',
    tyc: false,
    newsletter: true,
    cuenta: true,
  };
}
