import axios from 'axios';
import { FAVORITE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;

axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getFavorites = async () => {
  return axios.get(`${FAVORITE_PATH}/listado`);
};

export const getVerifiedTransports = async () => {
  return axios.get(`${FAVORITE_PATH}/listado/transportesVerificados`);
};

export const getVerifiedCompanies = async () => {
  return axios.get(`${FAVORITE_PATH}/listado/empresasVerificadas`);
};

export const getVerifiedCompaniesFav = async () => {
  return axios.get(`${FAVORITE_PATH}/listado/empresasVerificadasFav`);
};

export const invite = async (name, email) => {
  return axios.post(`${FAVORITE_PATH}/invitar?nombreInvitado=${name}&email=${email}`);
};

export const inviteUrl = async (url) => {
  return axios.post(`${FAVORITE_PATH}/invitar-url?url=${url}`);
};

export const getInvitation = async () => {
  return axios.get(`${FAVORITE_PATH}/listadoPendientes`);
};

export const enable = async (id) => {
  return axios.post(`${FAVORITE_PATH}/habilitar?idFavorito=${id}`);
};

export const block = async (id) => {
  return axios.put(`${FAVORITE_PATH}/bloquear?idEmpresa=${id}`);
};

export const refused = async (id) => {
  return axios.put(`${FAVORITE_PATH}/rechazar?idFavorito=${id}`);
};

export const addFavorite = async (ids) => {
  return axios.post(`${FAVORITE_PATH}/agregar?ids=${ids}`);
};

export const deleteFavorite = async (id) => {
  return axios.delete(`${FAVORITE_PATH}/eliminar?id=${id}`);
};

export const getOnlyActiveFavorites = async () => {
  const response = await axios.get(`${FAVORITE_PATH}/listado`);
  return response.data.filter(f => f.estado === 'ACTIVO');
};

export const getCheckFavorites = async (idEmpresa, urlEmpresa) => {
  const response = await axios.get(`${FAVORITE_PATH}/verificar?id=${idEmpresa}&url=${urlEmpresa}`);
  return response;
}
