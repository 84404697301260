import React, {useState,useEffect} from 'react'
import { Grid, MenuItem, useTheme } from '@mui/material';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import { getActivities } from '../../../api/enums';
// import useFetch from '../../../hooks/useFetch';


const ActivitySelectProfile = ({ value, disabled, name, label, property, formik }) => {

  // const [actividades, loadingActividades, updateActividades] = useFetch(getActivities);
  const [actividades, setActividades] = useState()
  const [loadingActividades, setLoadingActividades] = useState(false)
  const theme = useTheme();

    	useEffect(() => {
		const abortController = new AbortController();
		const getApiproduct = async () => {
			const {signal} = abortController;
     setLoadingActividades(true)
			try {
        const { data } = await getActivities(signal)
         setActividades(data)
			} catch (error) {
				console.log(error.message);
      } finally {
        setLoadingActividades(false)
      }
		};
		getApiproduct();
		return () => {abortController.abort()};
	}, []);


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} key = {`activity-select-profile-${property.id}`}>
        <CustomFormLabel htmlFor={property.id}>
          {label}
        </CustomFormLabel>
        <CustomSelect
          id = {property.id}
          name={name}
          value={value || ''}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          fullWidth
          size="medium"
          disabled={disabled}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: theme.palette.text.primary
            },
          }}
        >
          {!loadingActividades && actividades && Object.keys(actividades).map((key, index) => {
            return (
              <MenuItem key = {`${key}-${index.toString()}`} value = {key}>
                {actividades[key]}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </Grid>
    </Grid>
  );
};

export default ActivitySelectProfile;