import FILE_ICON_PDF from '../../../../assets/images/logos/pdf.png';
import FILE_ICON_DOC from '../../../../assets/images/logos/doc.png';
import FILE_ICON_XLS from '../../../../assets/images/logos/xls.png';

const validImageFileTypes = ['.jpg', '.jpeg', '.png'];

export function getFileExtension(filepath) {
  return `.${filepath.split('.').pop()}`;
}

export function isValidFileType(filepath) {
  return validImageFileTypes.includes(getFileExtension(filepath));
}

export function getFileExtension2(filepath) {
  return `.${filepath.split(';').pop()}`;
}

export function isValidFileType2(filepath) {
  return validImageFileTypes.includes(getFileExtension2(filepath));
}

export function getProvisionalImage(filepath) {
  const fileExtension = getFileExtension(filepath);

  if (fileExtension === '.pdf') return FILE_ICON_PDF;
  if (fileExtension === '.doc' || fileExtension === '.docx') return FILE_ICON_DOC;
  return FILE_ICON_XLS;
}
