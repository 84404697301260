import React, {useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Slide,
  LinearProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import TableCard from '../../../components/table-card/TableCard';
import ActionsListTable from '../../../components/table-card/ActionsListTable';
import ModalEditUser from './ModalEditUser';
import ModalViewRole from './ModalViewRole';
import {deleteUser } from '../../../api/company';
import ModalConfirm from './ModalConfirm';
import { CheckPermissions } from '../../../utils/relatedToPermissions';
import useAuth from '../../../hooks/useAuth';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ActiveUsersList = ({ users, loadNewInformation, loadingList }) => {
  const [t] = useTranslation();
  const auth = useAuth();
  const p = auth?.allPermissions;
  const [openEditUser, setOpenEditUser] = useState(false);
  const [userMailEdit, setUserMailEdit] = useState('');
  const [userRolEdit, setUserRolEdit] = useState();
  const [userIdEdit, setUseIdEdit] = useState();
  const [choosenRole, setChoosenRole] = useState(null);
  const [viewModalRole, setViewModalRole] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenEditUser = (mail, rol, idUser) => {
    setUserMailEdit(mail);
    setUserRolEdit(rol);
    setUseIdEdit(idUser);
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  function handleViewRol(rol) {
    setChoosenRole(rol);
    setViewModalRole(true);
  }

  function showAlert(msg, alertType) {
    enqueueSnackbar(msg, {
      variant: alertType,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    });
  }

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      await deleteUser(selectedUser.id);
      showAlert(t('Configuration.UserDeleted'), 'success');
      loadNewInformation();
    } catch (error) {
      console.error(error);
      showAlert(error?.response?.data?.error || t('Configuration.ServerError'), 'error');
    } finally {
      setOpenDeleteModal(false);
      setLoadingDelete(false);
    }
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <TableCard title={t('Configuration.SubTitleTeam2')} custompadding="0px" >
      <Box
        sx={{
          overflow: {
            xs: 'auto'
          },         
        }}
      >
        {loadingList ? (
          <LinearProgress />
        ) : (
          <Table
            aria-label="simple table"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h3">{t('Configuration.LastName')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">{t('Configuration.Name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">{t('Configuration.Phone')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">{t('Configuration.Mail')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">{t('Configuration.Role')}</Typography>
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && Array.isArray(users) && users.length > 0 ? (
                users.map((user) => {
                  return (
                    <TableRow hover key={user?.id}>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h4">{user?.apellido}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h4">{user?.nombre}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">{user?.telefono}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">{user?.mail}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">{user?.rol.nombre}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <ActionsListTable
                          item={user}
                          handleViewRol={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.VER_USU])? () => {
                            handleViewRol(user?.rol);
                          }: null}
                          handleEdit={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.EDI_USU])? () => {
                            handleOpenEditUser(user.mail, user?.rol.id, user?.id);
                          }: null}
                          handleDelete={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.ELI_USU])? () => {
                            // handleDelete(user.id);
                            setSelectedUser(user);
                            setOpenDeleteModal(true);
                          }: null}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box display="flex" alignItems="center" justifyContent="center" width={1} p={2}>
                      <Typography variant="body2">{t('Configuration.NoUsers')}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}

        <ModalEditUser
          open={openEditUser}
          Transition={Transition}
          handleClose={handleCloseEditUser}
          userMailEdit={userMailEdit || ''}
          userRolEdit={userRolEdit}
          userIdEdit={userIdEdit || ''}
          loadNewInformation={loadNewInformation}
        />

        <ModalViewRole
          handleClose={() => {
            setChoosenRole(null);
            setViewModalRole(false);
          }}
          viewModal={viewModalRole}
          setViewModal={setViewModalRole}
          role={choosenRole}
        />

        <ModalConfirm
          open={openDeleteModal}
          handleClose={handleClose}
          handleConfirm={handleDelete}
          disabled={loadingDelete}
        />
      </Box>
    </TableCard>
  );
};

export default ActiveUsersList;
