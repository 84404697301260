import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import Breadcrumb from '../../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import UbiIcon from '../../../assets/images/icons/cargo/ubicación.png';
import Company from '../../../assets/images/icons/cargo/empresa.png';

// api
import { getListOfTravelTracking } from '../../../api/viaje';

// utils
import { filterData } from './utils/filter';
import useFetch from '../../../hooks/useFetch';
import useAuth from '../../../hooks/useAuth';

// components
import PageContainer from '../../../components/container/PageContainer';
import CardMap from './components/CardMap';
import CustomSearchBox from '../../../components/forms/custom-elements/CustomSearchbox';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';


const MapView = ({ son }) => {

  const [travels, , refetch] = useFetch(getListOfTravelTracking);
  const {padMarByScreen, mMainCard}= useContext(ScreenSizeContext);
  const [travelsData, setTravelsData] = useState([]);
  const [travelsDataCopy, setTravelsDataCopy] = useState([]);

  const [emptyResult, setEmptyResult] = useState(false);
  const [searchText, setSearchText] = useState('');


  const [showInfoWindow, setShowInfoWindow] = useState(null);

  const [renderMap, setRenderMap] = useState(false); 

  const [noTravelsData, setNoTravelsData] = useState(null);

  const [t] = useTranslation();

  const { authenticatedData } = useAuth();

  const BCrumb = [
    {
      to: '../inicio',
      title: t('Fleet.Fleet'),
    },
    {
      title: t('Fleet.Map.Map'),
    },
  ];

  const filter = (text) => {
    setSearchText(text);
      const result = filterData(text, travelsDataCopy);
      setTravelsData(result);
      setEmptyResult(false);
      if(result === travelsDataCopy) setEmptyResult(true);

  };


  useEffect(() => { 
      if (travels.length > 0) {
        setTravelsData(travels); 
        setTravelsDataCopy(travels);
        
      } else if (authenticatedData.ubicacion?.latitud && authenticatedData.ubicacion?.longitud) {
        setNoTravelsData({
          icon: { url: Company, scaledSize: { width: 70, height: 70 } },
          lat: authenticatedData.ubicacion.latitud,
          lng: authenticatedData.ubicacion.longitud,
        });
       
      } else {
        navigator.geolocation.getCurrentPosition(
          (location) => {
            setNoTravelsData({
              icon: { url: UbiIcon, scaledSize: { width: 70, height: 70 } },
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            });
            
          },
          () => {
            setNoTravelsData({
              icon: { url: UbiIcon, scaledSize: { width: 70, height: 70 } },
              lat: -21.04153,
              lng: -57.87377,
            });
         
          },
        );
      }
      setRenderMap(true);
    
  }, [travels, renderMap]);

  return (
    <>
      {!son  ? (
        <PageContainer title="Mapa" description="Map view travels active">
          <Box display="flex" alignItems="center">
            <Breadcrumb items={BCrumb} />
          </Box>
          <Grid item xs={12} >
            <Grid container p={2} >
              <Grid item  sm={6} xs={12} >
                
              <Button
                endIcon={<RefreshIcon />}
                variant="contained"
                color="primary"
                sx={{ p: 1, pr: 2, pl: 2,  }}
                onClick={() => refetch()}
              >
                {t('Fleet.Map.Update')}
              </Button>
              </Grid>
              <Grid item sm={6} xs={12}  p={1}>
              <CustomSearchBox
                placeHolder={t('Fleet.Map.Search')}
                maxWidth={500}
                doSearch={(text) => filter(text)}
                setCurrentSearchValue={setSearchText}
                activeSearch
              />
              </Grid>
            </Grid>
            {renderMap && (
              <CardMap
                variousMarkers={travelsData} 
                justAMarker={noTravelsData} 
                showInfoWindow={showInfoWindow} 
                setShowInfoWindow={setShowInfoWindow} 
                emptyResult={emptyResult} 
                searchText={searchText} 
              />
            )}
          </Grid>
        </PageContainer>
      ) : (
        <>
         <Grid item xs={12}  p={padMarByScreen} m={mMainCard}>
            {renderMap && (
              <CardMap
                variousMarkers={travelsData}
                justAMarker={noTravelsData} 
                setShowInfoWindow={setShowInfoWindow}
                showInfoWindow={showInfoWindow}
                emptyResult={emptyResult}
                searchText={searchText}
              />
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default MapView;
