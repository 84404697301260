import axios from 'axios';
import { PREGUNTA_MARKET_PLACE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const hacerPregunta = async (pregunta, idPedido) => {
  return axios.post(`${PREGUNTA_MARKET_PLACE_PATH}/preguntar?idPedido=${idPedido}`, pregunta);
};

export const responderPregunta = async (respuesta, idPedido) => {
  return axios.put(`${PREGUNTA_MARKET_PLACE_PATH}/responder?idPedido=${idPedido}`, respuesta);
};