const paisData = {
  id: '',
  nombre: '',
};

const ubicacionData = {
  codigoPostal: '',
  direccion: '',
  latitud: 0,
  localidad: '',
  longitud: 0,
  nombre: '',
  observacion: '',
  pais: {
    ...paisData,
  },
  provincia: '',
  referencia: '',
};

export const getInitialValues = (company) => {

  if (company) {
    if (company.ubicacion === null) {
      const companyData = { ...company };
      // companyData.paisSelect = { ...paisData };
      companyData.pais = company.pais || { ...paisData };
      // companyData.selectCurrency = company.idMoneda || ''
      companyData.idPais = company.idPais || company.pais?.id || '';
      companyData.ubicacion = { ...ubicacionData };
      companyData.sitioWeb = company.sitioWeb !== null ? company.sitioWeb : '';
      companyData.mailInstitucional = company.mailInstitucional ? company.mailInstitucional : '';
      companyData.urlAmigable = company.urlAmigable ? company.urlAmigable : '';
      // companyData.mpPublico = company.mpPublico? company.mpPublico : "NO";
      companyData.linkMarketplace = `https://app.followlsn.com/marketplace/${company.urlAmigable}`;
      companyData.costoPorKilometro = company?.costoPorKilometro;
      companyData.monto = company?.costoPorKilometro?.monto;
      // companyData.costoPorKilometro = company?.costosPorKilometro;
      return companyData;
    }
    const companyData = { ...company };
    // companyData.paisSelect = { ...paisData };
    companyData.idPais = company.idPais || company.pais?.id || company.ubicacion.pais?.id || '';
    // companyData.selectCurrency = company.idMoneda || ''
    companyData.pais = company.pais || company.ubicacion.pais || { ...paisData };
    companyData.sitioWeb = company.sitioWeb !== null ? company.sitioWeb : '';
    companyData.mailInstitucional = company.mailInstitucional ? company.mailInstitucional : '';
    companyData.urlAmigable = company.urlAmigable ? company.urlAmigable : '';
    companyData.linkMarketplace = `https://app.followlsn.com/marketplace/${company.urlAmigable}`;
    companyData.costoPorKilometro = company?.costoPorKilometro;
    companyData.monto = company?.costoPorKilometro?.monto;
    companyData.ubicaciones= company.ubicaciones.map(objeto=> ({ ...objeto, select: true }))
    // companyData.mpPublico = company.mpPublico? company.mpPublico : "NO";
    companyData.ubicacion.select=true
    return companyData;
  }
  return {
    nombre: '',
    razonSocial: '',
    sitioWeb: '',
    urlAmigable: '',
    identificadorFiscal: '',
    paisSelect: null,
    pais: {
      ...paisData,
    },
    ubicacion: {
      ...ubicacionData,
    },
    responsable: '',
    emailContactoSeguimimento: '',
    telefono: '',
    mail: '',
    mailInstitucional: '',
    linkMarketplace: '',
    idPais: '',
    selectCurrency: null,
    costoPorKilometro: '',
    monto: '',
    selectCurrencyMonto: null,
    // {
    //   ...costoKmData,
    // },
    // mpPublico: "NO"
  };
};
