import axios from 'axios';
import { CODE_PATH } from '../config';

export const getDiscountCode = async (code) => {
  return axios.get(`${CODE_PATH}/ver?codigo=${code}`);
};

export const verifyDiscountCode = async (code, idPromocion) => {
  return axios.get(`${CODE_PATH}/verificar?codigo=${code}&idPromocion=${idPromocion}`);
};
