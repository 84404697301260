import React from 'react';

import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
  Slide
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTranslation } from 'react-i18next';
import { Documento } from '../../fleet/documents/components/Logos';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ProfileDocumentsModal = ({
  documentos,
  open,
  handleClose,
  isView,
  setChoosenDocument,
  setViewModal,
  setOpenModalCreate,
}) => {
  const [t] = useTranslation();
  const theme = useTheme();


  function onSelectDocument(document) {
    setChoosenDocument(document);
    setViewModal(true);
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        scroll="paper"
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box display="flex" alignItems="center">
            <AssignmentIcon style={{ fontSize: '32px', color: theme.palette.primary.main }} />
            <Typography variant="h3" ml={1}>
              {t('Menu.ModalDocuments')}
            </Typography>
          </Box>
          <IconButton style={{ float: 'right' }} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            backgroundColor: alpha(theme.palette.primary.dark, 0.8),
            maxHeight: '700px',
            minHeight: '600px',
            overflowY: 'hidden',
          }}
        >
          <Paper elevation={10} sx={{ px: 4, py: 2, minHeight: '500px', maxHeight: '600px', overflowY: 'auto' }}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                {isView === true ? null : (
                  <Button
                    onClick={() => {
                      setOpenModalCreate(true);
                      setChoosenDocument(null);
                    }}
                    size="small"
                    sx={{
                      width: '270px',
                      p: 1,
                      mb: '1rem',
                      [theme.breakpoints.up('sm')]: {
                        float: 'right',
                        mb: 0,
                      },
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<UploadFileIcon style={{ fontSize: '24px' }} />}
                  >
                    <Typography variant="h4" textAlign="center">
                      {t('Loads.Documents_View.Upload_Documents')}
                    </Typography>
                  </Button>
                )}
              </Grid>
              <Grid container spacing={2} sx={{ ml: 2, mr: 2, aspectRatio: '1 / 1' }}>
                {documentos ? (
                  documentos?.map((doc) => {
                    return (
                      <Grid item xs={4} sx={{ mt: 2 }}>
                        <IconButton
                          onClick={() => {
                            onSelectDocument(doc);
                            handleClose();
                          }}
                        >
                          <Documento
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          />
                        </IconButton>
                        <Typography
                          align="center"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            wordWrap: 'break-word',
                          }}
                        >
                          {doc?.titulo}
                        </Typography>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: '100%', 
                    }}
                  >
                    <Grid
                      item
                      xs={4}
                      sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconButton disabled>
                        <ContentPasteOffIcon
                          style={{ fontSize: '100px', color: theme.palette.disabled }}
                          disabled
                        />
                      </IconButton>
                      <Typography
                        align="center"
                        variant="h2"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                          wordWrap: 'break-word',
                        }}
                      >
                        {t('Loads.Documents_View.No_Results')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileDocumentsModal;
