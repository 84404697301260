import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  Typography,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import Menuitems from './Menuitems';
import LogoIcon from '../../../../layouts/fleet-layout/logo/LogoIcon';
import { SidebarWidth } from '../../../../assets/global/Theme-variable';

const Sidebar = ({ isMobileSidebarOpen, onSidebarClose, isSidebarOpen }) => {
  const scrollContainerRef = useRef(null);

  // Desplazar al fondo cuando se monta el componente
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, []);

  const [open, setOpen] = React.useState(true);
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };

  const SidebarContent = (
    <>
      <Grid
        ref={scrollContainerRef}
        style={{
          width: '100%',
          overflowY: 'auto',
          scrollbarWidth: 'none',
          scrollbarColor: '#888 #f1f1f1',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ pl: '25%' }}>
            <LogoIcon />
          </Box>
          <Box>
            <List>
              {Menuitems().map((item, index) => {
                // {/********SubHeader**********/}
                if (item.subheader) {
                  return (
                    <li key={item.subheader}>
                      <Typography
                        variant="subtitle2"
                        fontWeight="500"
                        sx={{ my: 2, mt: 4, opacity: '0.4' }}
                      >
                        {item.subheader}
                      </Typography>
                    </li>
                  );
                  // {/********If Sub Menu**********/}
                  /* eslint no-else-return: "off" */
                } else if (item.children) {
                  return (
                    <React.Fragment key={item.title}>
                      <ListItem
                        disabled={item.disabled && item.disabled}
                        button
                        component="li"
                        onClick={() => handleClick(index)}
                        selected={
                          pathWithoutLastPart === item.href ||
                          (item.hasSubRoutes && pathDirect.includes(item.href))
                        }
                        sx={{
                          mb: 1,
                          ...((pathWithoutLastPart === item.href ||
                            (item.hasSubRoutes && pathDirect.includes(item.href))) && {
                            color: 'white',
                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
                          }),
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...((pathWithoutLastPart === item.href ||
                              (item.hasSubRoutes && pathDirect.includes(item.href))) && {
                              color: 'white',
                            }),
                          }}
                        >
                          {item.image !== '' ? (
                            <img
                              alt="Menu icon"
                              src={item.image}
                              style={{
                                width: 20,
                                height: 20,
                                filter:
                                  pathWithoutLastPart === item.href ||
                                  (item.hasSubRoutes && pathDirect.includes(item.href))
                                    ? 'brightness(0) invert(100%)'
                                    : 'none',
                              }}
                            />
                          ) : (
                            <FeatherIcon icon={item.icon} width="20" height="20" />
                          )}
                        </ListItemIcon>
                        <ListItemText>{item.title}</ListItemText>
                        {index === open ||
                        pathWithoutLastPart === item.href ||
                        (item.hasSubRoutes && pathDirect.includes(item.href)) ? (
                          <FeatherIcon icon="chevron-down" size="16" />
                        ) : (
                          <FeatherIcon icon="chevron-right" size="16" />
                        )}
                      </ListItem>
                      <Collapse in={index === open} timeout="auto" unmountOnExit>
                        <List component="li" disablePadding>
                          {item.children.map((child) => {
                            return (
                              <ListItem
                                key={child.title}
                                button
                                component={NavLink}
                                to={child.href}
                                onClick={onSidebarClose}
                                selected={
                                  pathDirect === child.href ||
                                  (child.hasSubRoutes && pathDirect.includes(child.href))
                                }
                                sx={{
                                  mb: 1,
                                  ...((pathDirect === child.href ||
                                    (child.hasSubRoutes && pathDirect.includes(child.href))) && {
                                    color: 'primary.main',
                                    backgroundColor: 'transparent!important',
                                  }),
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    svg: { width: '14px', marginLeft: '3px' },
                                    ...((pathDirect === child.href ||
                                      (child.hasSubRoutes && pathDirect.includes(child.href))) && {
                                      color: 'primary.main',
                                    }),
                                  }}
                                >
                                  <FeatherIcon icon={child.icon} width="20" height="20" />
                                </ListItemIcon>
                                <ListItemText>{child.title}</ListItemText>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                  // {/********If Sub No Menu**********/}
                } else {
                  return (
                    <List component="li" disablePadding key={item.title}>
                      <Grid className={item.className}>
                        <ListItem
                          disabled={item.disabled && item.disabled}
                          onClick={() => handleClick(index)}
                          button
                          component={NavLink}
                          to={item.href}
                          selected={
                            pathDirect === item.href ||
                            (item.hasSubRoutes && pathDirect.includes(item.href))
                          }
                          sx={{
                            mb: 1,
                            ...((pathDirect === item.href ||
                              (item.hasSubRoutes && pathDirect.includes(item.href))) && {
                              color: 'white',
                              backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
                            }),
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              ...((pathDirect === item.href ||
                                (item.hasSubRoutes && pathDirect.includes(item.href))) && {
                                color: 'white',
                              }),
                            }}
                          >
                            {item.image !== '' ? (
                              <img
                                alt="Menu icon"
                                src={item.image}
                                style={{
                                  width: 20,
                                  height: 20,
                                  filter:
                                    pathDirect === item.href ||
                                    (item.hasSubRoutes && pathDirect.includes(item.href))
                                      ? 'brightness(0) invert(100%)'
                                      : 'none',
                                }}
                              />
                            ) : (
                              <FeatherIcon icon={item.icon} width="20" height="20" />
                            )}
                          </ListItemIcon>
                          <ListItemText onClick={onSidebarClose}>{item.title}</ListItemText>
                        </ListItem>
                      </Grid>
                    </List>
                  );
                }
              })}
            </List>
          </Box>
        </Box>
      </Grid>
    </>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
            border: '0 !important',
            boxShadow: '0px 7px 30px 0px rgb(113 122 131 / 11%)',
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
          border: '0 !important',
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );
};

Sidebar.propTypes = {
  isMobileSidebarOpen: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default Sidebar;
