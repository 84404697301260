import React from 'react';

export default function Diamond() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 512 512">
      <path d="m512 178.746-5.239 5.772-221.786 244.226-26.967 29.697-.586.648-.021-.021-1.223-1.317-1.349-1.475-28.85-31.401L5.103 184.518 0 178.976l17.546-25.828 52.921-77.86 15.204-22.377h337.594l17.713 25.127 52.974 75.11z" style={{fill: "#85c9ff"}}/>
      <path d="m512 178.746-5.239 5.772-221.786 244.226-26.967 29.697-.586.648-.021-.021V52.911h165.864l17.713 25.127 52.974 75.11z" style={{fill: "#00a6ff"}}/>
      <path d="M493.952 153.148H407.57l33.409-75.11-17.714-25.127h-5.427l-42.329 95.156-97.08-95.156h-42.046l-100.018 93.347-41.523-93.347h-9.171L70.467 75.288l34.622 77.86H17.546L0 178.976l5.103 5.542H119.06l106.919 240.357 28.85 31.401 1.349 1.474 1.223 1.318.021.021.586-.648 26.968-29.697L393.62 184.518h113.141l5.239-5.772-18.048-25.598zM257.401 76.229l.01-.01 77.85 76.93H174.396l83.005-76.92zm0 339.706L153.389 184.518h205.902l-101.89 231.417z" style={{fill: "#d7e9ff"}}/>
      <path d="m512 178.746-5.239 5.772H393.62L284.975 428.744l-26.967 29.697-.586.648-.021-.021v-43.133l101.89-231.417h-101.89v-31.37h77.861l-77.85-76.929-.011.01V52.911h21.029l97.079 95.155 42.329-95.155h5.427l17.713 25.127-33.409 75.11h86.383z" style={{fill:"#bdf"}}/>
    </svg>
  );
}