export function getTranslation(i18, object) {
  if (!object) return '';

  const defaultValue = object.nombre || '';

  return i18.language === 'es'
    ? defaultValue
    : i18.language === 'en'
    ? object?.nombreIngles || defaultValue
    : object?.nombrePortugues || defaultValue;
}
