import { useCallback, useEffect, useState, React, Fragment } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  CardContent,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  DialogActions,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';

import { getPermissionsCategory, getPermissions } from '../../../api/permissions';
import { createRol, editRol } from '../../../api/rol';

const NewRol = ({ handleApplyModalClose, choosenRole, setChoosenRole }) => {
  const [t, i18next] = useTranslation();
  const [permissionsList, setPermissionsList] = useState();
  // console.log(permissionsList);
  const [permissions, setPermissions] = useState();
  const [open, setOpen] = useState({});
  const [checked, setChecked] = useState([]);
  const [todosCheckeados, setTodosCheckeados] = useState(false);
  const [categoriaCheckeada, setCategoriaCheckeada] = useState([]);
  const [subCategoriaCheckeada, setSubCategoriaCheckeada] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const showAlert = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const fetchPermissions = useCallback(async () => {
    const categoryPermissions = await getPermissionsCategory();
    setPermissionsList(categoryPermissions.data);

    const permissionslist = await getPermissions();
    setPermissions(permissionslist.data);
  }, [permissionsList, permissions]);

  const handleClick = (idList) => {
    if (Array.isArray(idList)) {
      idList.forEach((id) => {
        setOpen((prevState) => ({ ...prevState, [id]: true }));
      });
    } else {
      setOpen((prevState) => ({ ...prevState, [idList]: !prevState[idList] }));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nombre: choosenRole === null || choosenRole === undefined ? '' : choosenRole.nombre,
      permiso: [],
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string()
        .min(2, t('Message.2char'))
        .max(60)
        .required(t('Message.nameRequired'))
        .matches(
          /^[a-zA-Z0-9ÁÉÍÓÚáéíóúñÑÇçãêõ\s]+$/,
          t('Fleet.Validate.VehicleCreate.Only-letters-and-numbers-are-allowed'),
        ),
    }),
  });

  const handleClose = () => {
    formik.resetForm();
    handleApplyModalClose();
    setChoosenRole(null);
  };

  const handleSubmit = async () => {
    const filterPermissions = () => {
      const permisoFiltrado = permissions.filter((permisssion) => {
        const res = checked.find((selected) => {
          return selected === permisssion.id;
        });
        return res !== undefined;
      });
      return permisoFiltrado;
    };

    const permissionsObject = filterPermissions();

    let isError = false;

    if (permissionsObject.length === 0) {
      showAlert(t('Loads.Setting.NoPermissions'), 'error');
      isError = true;
    }

    if (formik.values.nombre.length < 2) {
      showAlert(t('Loads.Setting.NameError'), 'error');
      isError = true;
    }

    if (isError) return;

    const isCreate = choosenRole === null || choosenRole === undefined;

    try {
      if (isCreate) {
        await createRol({
          nombre: formik.values.nombre,
          isAdmin: false,
          permiso: permissionsObject,
          administrador: false,
        });
      } else {
        await editRol({
          id: choosenRole.id,
          nombre: formik.values.nombre,
          isAdmin: false,
          permiso: permissionsObject,
          administrador: false,
        });
      }
      showAlert(
        `${t('Loads.Setting.Start')} ${
          isCreate ? t('Loads.Setting.created') : t('Loads.Setting.modified')
        } ${t('Loads.Setting.Success')}`,
        'success',
      );
      handleClose();
    } catch (error) {
      console.log(error);
      console.log(new Error({ error }));
      showAlert(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          t('Configuration.ServerError'),
        'error',
      );
    }
  };

  const recorridSubCategoria = (subCategoria, newChecked) => {
    for (let i = 0; i < subCategoria.permisos?.length; i++) {
      if (!newChecked.includes(subCategoria.permisos[i].id)) {
        return false;
      }
    }
    return true;
  };

  const recorridoCategoria = (categoria, newChecked) => {
    for (let i = 0; i < categoria.subcategorias.length; i++) {
      if (!recorridSubCategoria(categoria.subcategorias[i], newChecked)) {
        return false;
      }
    }
    return true;
  };

  const recorridoTodasLasCategorias = (categorias, newChecked) => {
    const { length } = categorias;

    for (let i = 0; i < length; i++) {
      if (!recorridoCategoria(categorias[i], newChecked)) {
        return false;
      }
    }

    return true;
  };

  const checkearCategorias = (newChecked) => {
    const categorias = [];
    const subCategorias = [];
    let categoriaChecked = 0;

    for (let i = 0; i < permissionsList.length; i++) {
      if (recorridoCategoria(permissionsList[i], newChecked)) {
        categorias.push(permissionsList[i].id);
        categoriaChecked++;
      }
      for (let j = 0; j < permissionsList[i].subcategorias.length; j++) {
        if (recorridSubCategoria(permissionsList[i].subcategorias[j], newChecked)) {
          subCategorias.push(permissionsList[i].subcategorias[j].id);
        }
      }
    }

    if (categoriaChecked === permissionsList.length) setTodosCheckeados(true);
    else setTodosCheckeados(false);

    setCategoriaCheckeada(categorias);
    setSubCategoriaCheckeada(subCategorias);
  };

  const handleToggle = (value) => {
    let currentIndex = checked.indexOf(value.id);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      currentIndex = categoriaCheckeada.indexOf(value.id);
      if (currentIndex === -1) {
        currentIndex = subCategoriaCheckeada.indexOf(value.id);
      }
    }

    if (value?.subcategorias?.length > 0) {
      value.subcategorias.forEach((subCategoria) => {
        subCategoria.permisos.forEach((permiso) => {
          if (currentIndex === -1 && !newChecked.includes(permiso.id)) {
            newChecked = [...newChecked, permiso.id];
          } else if (currentIndex !== -1) {
            newChecked = newChecked.filter((data) => permiso.id !== data);
          }
        });
      });
    } else if (value?.permisos?.length > 0) {
      value.permisos.forEach((permiso) => {
        if (currentIndex === -1 && !newChecked.includes(permiso.id)) {
          newChecked = [...newChecked, permiso.id];
        } else if (currentIndex !== -1) {
          newChecked = newChecked.filter((data) => permiso.id !== data);
        }
      });
    } else if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    checkearCategorias(newChecked);
  };

  const resetAll = () => {
    setCategoriaCheckeada([]);
    setSubCategoriaCheckeada([]);
    setTodosCheckeados(false);
    setChecked([]);
  };

  const checkearTodos = () => {
    const categorias = [];
    const subCategorias = [];
    const permisos = [];

    for (let i = 0; i < permissionsList.length; i++) {
      categorias.push(permissionsList[i].id);
      for (let j = 0; j < permissionsList[i].subcategorias.length; j++) {
        subCategorias.push(permissionsList[i].subcategorias[j].id);
        for (let k = 0; k < permissionsList[i].subcategorias[j].permisos.length; k++) {
          permisos.push(permissionsList[i].subcategorias[j].permisos[k].id);
        }
      }
    }

    setCategoriaCheckeada(categorias);
    setSubCategoriaCheckeada(subCategorias);
    setChecked(permisos);
    setTodosCheckeados(true);
  };

  const handleToggleAll = () => {
    if (todosCheckeados) resetAll();
    else checkearTodos();
  };

  const handleToggleEditar = (value) => {
    if (value !== null && value !== undefined) {
      const permisos = [];
      const subCategorias = [];
      const categorias = [];

      value.permiso.forEach((permiso) => {
        permisos.push(permiso.id);
        categorias.push(permiso.idCategoria);
        subCategorias.push(permiso.idSubCategoria);
      });

      handleClick(categorias);
      handleClick(subCategorias);
      setChecked(permisos);
    }
  };

  useEffect(() => {
    if (permissionsList && permissions && choosenRole && checked) checkearCategorias(checked);
  }, [permissionsList, permissions, choosenRole, checked, t]);

  useEffect(() => {
    fetchPermissions();
    handleToggleEditar(choosenRole);
  }, [t]);

  return (
    <CardContent>
      <Typography variant="h2" py={2}>
        {choosenRole === null || choosenRole === undefined
          ? t('Loads.Setting.NewRole')
          : t('Loads.Setting.EditRole')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <CustomFormLabel htmlFor="nombre">{t('Loads.Setting.RoleName')}</CustomFormLabel>
        <CustomTextField
          id="nombre"
          name="nombre"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.nombre}
          error={Boolean(formik.touched.nombre && formik.errors.nombre)}
          helperText={formik.touched.nombre && formik.errors.nombre}
          variant="outlined"
          fullWidth
          autoComplete="off"
        />

        <Divider sx={{ mt: 4, mb: 3 }} />

        <Typography variant="h4" ml={1} mb={2}>
          <TouchAppIcon
            style={{
              fontSize: '32px',
              color: theme.palette.primary.main,
              marginRight: '8px',
            }}
          />
          {t('Loads.Setting.RolePermissions')}
        </Typography>

        {/* <FormGroup>
          <FormControlLabel control = {<Checkbox defaultChecked sx = {{ pl: '25px' }} />} label = "Todas las categorias" />
        </FormGroup> */}

        <List
          sx={{
            // width: '100%',
            // maxWidth: 1000,
            bgcolor: 'background.paper',
          }}
          component="nav"
          // subheader={<ListSubheader component="div" sx = {{ ml: 0, pl: 0 }}>Categorías</ListSubheader>}
        >
          <ListItemButton sx={{ ml: 0, pl: 0 }}>
            <ListItem role={undefined} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={todosCheckeados}
                  onClick={() => {
                    handleToggleAll();
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t('Loads.Setting.RoleCategory')} />
            </ListItem>
          </ListItemButton>

          {permissionsList?.map((categoria, indexCategoria) => {
            const labelIdCategoria = categoria.id;
            return (
              <Fragment
                key={`List-item-categoria-${labelIdCategoria}-${indexCategoria.toString()}`}
              >
                <ListItemButton
                  key={`categoria-${categoria.id}-${indexCategoria.toString()}`}
                  sx={{ ml: 0, pl: 0 }}
                >
                  <ListItem role={undefined} dense onClick={() => handleClick(categoria.id)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={categoriaCheckeada.some((c) => c === categoria.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          name: labelIdCategoria,
                        }}
                        onClick={() => {
                          handleToggle(categoria);
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        i18next.language === 'es'
                          ? categoria?.nombre
                          : i18next.language === 'pt'
                          ? categoria?.nombrePortugues
                          : i18next.language === 'en'
                          ? categoria?.nombreIngles
                          : ''
                      }
                    />
                  </ListItem>
                  {open[categoria.id] ? (
                    <ExpandLess
                      onClick={() => handleClick(categoria.id)}
                      // sx={{ width: '80%', paddingLeft: '70%' }}
                    />
                  ) : (
                    <ExpandMore
                      onClick={() => handleClick(categoria.id)}
                      // sx={{ width: '80%', paddingLeft: '70%' }}
                    />
                  )}
                </ListItemButton>
                <Collapse
                  in={open[categoria.id]}
                  timeout="auto"
                  unmountOnExit
                  key={`collapse-categoria-${categoria.id}-${indexCategoria.toString()}`}
                >
                  <List
                    sx={{
                      // width: '100%',
                      // maxWidth: 1000,
                      bgcolor: 'background.paper',
                      paddingLeft: '25px',
                      paddingRight: '25px',
                    }}
                  >
                    {categoria.subcategorias?.map((subCategoria, indexSubcategoria) => {
                      const labelIdSubCategoria = subCategoria.id;
                      return (
                        <Fragment
                          key={`
                            List-item-sub-categoria-${labelIdCategoria}-${labelIdSubCategoria}-
                            ${indexCategoria.toString()}-${indexSubcategoria.toString()}`}
                        >
                          <ListItemButton
                            key={`subcategoria-${subCategoria.id}-${indexSubcategoria.toString()}`}
                          >
                            <ListItem
                              role={undefined}
                              dense
                              onClick={() => handleClick(subCategoria.id)}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={subCategoriaCheckeada.some((c) => c === subCategoria.id)}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    name: labelIdSubCategoria,
                                  }}
                                  onClick={() => {
                                    handleToggle(subCategoria);
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  i18next.language === 'es'
                                    ? subCategoria?.nombre
                                    : i18next.language === 'pt'
                                    ? subCategoria?.nombrePortugues
                                    : i18next.language === 'en'
                                    ? subCategoria?.nombreIngles
                                    : ''
                                }
                              />
                            </ListItem>
                            {open[subCategoria.id] ? (
                              <ExpandLess
                                onClick={() => handleClick(subCategoria.id)}
                                // sx={{ width: '80%', paddingLeft: '70%' }}
                              />
                            ) : (
                              <ExpandMore
                                onClick={() => handleClick(subCategoria.id)}
                                // sx={{ width: '80%', paddingLeft: '70%' }}
                              />
                            )}
                          </ListItemButton>
                          <Collapse
                            in={open[subCategoria.id]}
                            timeout="auto"
                            unmountOnExit
                            key={`collapse-subcategoria-${
                              subCategoria.id
                            }-${indexSubcategoria.toString()}`}
                          >
                            <List
                              sx={{
                                // width: '100%',
                                // maxWidth: 1000,
                                bgcolor: 'background.paper',
                                paddingLeft: '40px',
                                paddingRight: '40px',
                              }}
                            >
                              {subCategoria.permisos?.map((rol, indexRol) => {
                                const labelId = rol.id;
                                return (
                                  <ListItem
                                    key={`
                                      List-item-permiso-${labelIdCategoria}-${labelIdSubCategoria}-${
                                      rol.id
                                    }-
                                      ${indexCategoria.toString()}-${indexSubcategoria.toString()}-${indexRol.toString()}`}
                                  >
                                    <ListItemButton
                                      role={undefined}
                                      dense
                                      onClick={() => {
                                        handleToggle(rol);
                                      }}
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          name="permiso"
                                          value={rol.id}
                                          edge="start"
                                          checked={checked.indexOf(rol.id) !== -1}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{
                                            name: labelId,
                                          }}
                                          onChange={formik.handleChange}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          i18next.language === 'es'
                                            ? rol?.nombre
                                            : i18next.language === 'pt'
                                            ? rol?.nombrePortugues
                                            : i18next.language === 'en'
                                            ? rol?.nombreIngles
                                            : ''
                                        }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                          <Divider />
                        </Fragment>
                      );
                    })}
                  </List>
                </Collapse>
                <Divider />
              </Fragment>
            );
          })}
        </List>
        <DialogActions display="flex" sx={{ justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{
              mt: 2,
              mr: 2,
            }}
            variant="outlined"
          >
            {t('Button.Close')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              mt: 2,
            }}
          >
            {choosenRole === null || choosenRole === undefined
              ? t('Button.Create-Rol')
              : t('Button.Save')}
          </Button>
        </DialogActions>
      </form>
      {/* {showRolesCreate ? <SettingView /> : <></>} */}
    </CardContent>
  );
};

export default NewRol;
