import React from 'react';
import _ from "lodash";
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import FeatherIcon from 'feather-icons-react';

import 'react-circular-progressbar/dist/styles.css';

function Separator(props) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;
  return _.range(props.count).map(index => (
    <Separator turns={index * turns} style={props.style} key = {`radial-separator-${props.type}-${index.toString()}`}/>
  ));
}

const DashedCircularProgressbar = ({ 
  type, value, text, subtext, icon, total, capacidad, disabled = false, showOnlyPercentage = false, bg
}) => {

  const theme = useTheme();
  const mdUp = useMediaQuery((th) => th.breakpoints.up('md'));

  return (
    <CircularProgressbarWithChildren
      value={value}
      strokeWidth={10}
      styles={buildStyles({
        pathColor: value >= 100 ? 
          theme.palette.error.main : value > 70 ? 
          theme.palette.warning.main :
          theme.palette.success.main,
        strokeLinecap: "butt",
      })}
    >
      <RadialSeparators
        type = {type}
        count = {12}
        style={{
          background: "#fff",
          width: "4px",
          // This needs to be equal to props.strokeWidth
          height: `${10}%`
        }}
      />
      {
        !showOnlyPercentage ?
        <Box
          p = {2}
          display = 'flex' alignItems = 'center' justifyContent = 'center' flexDirection = 'column'
          width = {0.9}
          color = {theme.palette.text.secondary}
        >
          <img src = {bg} style = {{ position: 'absolute', zIndex: '5', width: '75%', height: '75%' }} alt = 'bg-percentage'/>
          <Box style = {{ position: 'absolute', zIndex: '10', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            icon &&
            <FeatherIcon icon = {icon} style = {{ marginBottom: '5px' }}/>
          }
          {
            text &&
            <Typography textAlign = 'center' variant= 'h6'>{text}</Typography>
          }
          {
            subtext &&
            <Typography textAlign = 'center' variant = 'h6' lineHeight={1}>{subtext}</Typography>
          }
          {   
            !disabled &&
            <>
            <Typography textAlign = 'center' variant = 'h6' mt = {1}>{`${total} de`}</Typography>
            <Divider sx={{ backgroundColor: 'gray', width:'100%' }} />
            <Typography textAlign = 'center' variant = 'h6'>{capacidad}</Typography>
            </>
          }
          </Box>
        </Box>
        :
        <Box
          p = {2}
          display = 'flex' alignItems = 'center' justifyContent = 'center' flexDirection = 'column'
          width = {0.9}
          color = {theme.palette.text.secondary}
        >
          <img src = {bg} style = {{ position: 'absolute', zIndex: '5', width: '75%', height: '75%' }} alt = 'bg-percentage'/>
          <Box style = {{ position: 'absolute', zIndex: '10' }}>
          {
            disabled ?
            <FeatherIcon icon = 'slash' size = { mdUp ? '50px' : '25px'}/>
            :
            value > 100 ?
            <FeatherIcon icon = 'x' size = { mdUp ? '50px' : '25px'}/>
            :
            <Typography textAlign = 'center' variant = { mdUp ? 'h2' : 'h6'}>{`${value}%`}</Typography>
          }
          </Box>
        </Box>
      }
    </CircularProgressbarWithChildren>
  );
};

export default DashedCircularProgressbar;