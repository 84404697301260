import React, { useState, useEffect } from 'react';
import { experimentalStyled, useMediaQuery, Container, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../../layouts/fleet-layout/header/Header';
import Sidebar from './sidebar/Sidebar';
import { TopbarHeight } from '../../../assets/global/Theme-variable';
import useTour from '../../../hooks/useTour';
import { verifyToken } from '../../../api/auth';
import { activateTour } from '../../../api/users';

const MainWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  width: '100%',
}));
const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    paddingTop: TopbarHeight,
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '64px',
  },
}));

const MapLayout = () => {
  
  const [tourStart, setTourStart] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const customizer = useSelector((state) => state.CustomizerReducer);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [t] = useTranslation();


  const STEPS = [
    {
      title: <Typography variant="h2">{t('Tour.WelcomeFleet-Modal')}</Typography>,
      // content: <FeatherIcon icon="truck" size="35" />,
      // content: <LocalShippingIcon sx={{ fontSize: 35 }} />,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Menu')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
      },
      placement: 'center',
      target: '.fleet-os',
    },
    {
      title: <Typography variant="h2">{t('Menu.control tower')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Control')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.control',
    },
    {
      title: <Typography variant="h2">{t('Fleet.Home.Service-Orders.Service-Orders')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.ServiceOrders')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-os',
    },
    {
      title: <Typography variant="h2">{t('Fleet.My-Travels.My-Travels')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.MyTravels')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-ot',
    },
    {
      title: <Typography variant="h2">{t('Fleet.Map.Map')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Map')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-map',
    },
    {
      title: <Typography variant="h2">{t('Fleet.My-assets.My-assets')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.MyAssets')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-assets',
    },
    {
      title: <Typography variant="h2">{t('Fleet.Assets-Organizer.Assets-Organizer')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Organizer')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-org',
    },
    {
      title: <Typography variant="h2">{t('OM.orderM')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.OM')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-om',
    },
    {
      title: <Typography variant="h2">{t('Fleet.Inactive-Periods.Inactive-Periods')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Inactive')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-inactive',
    },
    {
      title: <Typography variant="h2">{t('Fleet.Planning.Planning')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Planing')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-planing',
    },
    {
      title: <Typography variant="h2">{t('Loads.Documents_View.Page_Title')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Docs')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-docs',
    },
    {
      title: <Typography variant="h2">{t('Loads.Customers.Title')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Customers')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-clients',
    },
    {
      title: <Typography variant="h2">{t('Loads.Favorites.Title')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Favorites')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-favs',
    },
    {
      title: <Typography variant="h2">Checklist</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.Checklist')}
        </Typography>
      ),
      locale: {
        skip: t('Tour.Button.Skip'),
        next: t('Tour.Button.Next'),
        back: t('Tour.Button.Back'),
      },
      placement: 'right',
      target: '.fleet-checks',
    },
    {
      title: <Typography variant="h2">{t('Control-panel')}</Typography>,
      content: (
        <Typography variant="body1" style={{ fontSize: '18px' }}>
          {t('Tour.FleetModule.KPI')}
        </Typography>
      ),
      locale: {
        last: t('Tour.Button.Last'),
        back: t('Tour.Button.Back'),
      },
      placement: 'top',
      target: '.kpi',
    },
  ];

  const tour = useTour(STEPS, tourStart);


  const validateModule = async () => {
    const { modulosTours } = await verifyToken(localStorage.getItem('accessToken'));

    if (!modulosTours.includes('FLOTA')) {
      try {
        setTourStart(true);
        await activateTour(true, 'FLOTA');
      } catch (error) {
        console.log(error);
        setTourStart(false);
      } finally {
        setTourStart(false);
      }
    }
  };

  useEffect(() => {
    validateModule();
  }, [tourStart]);
  
  return (
    <>
    {tour}
    <MainWrapper  className={customizer.activeMode === 'dark' ? 'darkbg' : ''}>
      <Header
        sx={{
          paddingLeft: isSidebarOpen && lgUp ? '265px' : '',
         backgroundColor: customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
         height:"70px"         
        }}
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
        toggleMobileSidebar={() => setMobileSidebarOpen(true)}
        tourStart={tourStart}
        setTourStart={setTourStart}
      />

      <Sidebar
        isSidebardir={customizer.activeDir === 'ltr' ? 'left' : 'right'}
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
        onSidebarClose={() => setMobileSidebarOpen(false)}
        tourStart={tourStart}
        
      />

      <PageWrapper>
        <Container
          maxWidth={ false }
          sx={{
            paddingTop: '20px',
            paddingLeft: isSidebarOpen && lgUp ? '280px!important' : '',
            minWidth:  "95%",
          }}
        >
          <Box sx={{
            minHeight: 'calc(100vh - 170px)',
            
          }}>
            <Outlet />
          </Box>
        </Container>
      </PageWrapper>
    </MainWrapper>
    </>
  );
};

export default MapLayout;
