export function getDataPost (data) {
  const dataPost = {
    ...data,
    idPais: data.paisSelect?.id || data.pais?.id || data.idPais || null,
    pais: data.paisSelect || data.pais || null,
    idMoneda:data?.selectCurrency?.id || null,
    costoPorKilometro: {
      idMoneda: data?.selectCurrencyMonto?.id || null,
      monto: data?.monto || null
    }
  
  };

  delete dataPost.clientes;
  delete dataPost.favoritos;
  delete dataPost.linkMarketplace;
  delete dataPost.paisSelect;
  delete dataPost.actividadPrincipalNombre;
  delete dataPost.identificadorFiscalVerificado;
  return dataPost;
}