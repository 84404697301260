import axios from 'axios';
import { NCM_PATH } from '../config';

export const getNcm = async (page = 0, id, searchParam) => {
  if (id) {
    return axios.get(`${NCM_PATH}/listado-paginado?id=${id}`);
  }
  if (searchParam) {
    return axios.get(`${NCM_PATH}/listado-paginado?searchParam=${searchParam}`);
  }
  return axios.get(`${NCM_PATH}/listado-paginado?page=${page}`);
};

export const getNcmList = async () => {
  return axios.get(`${NCM_PATH}/listado`);
};