import { useEffect, useState } from 'react';

import useIsMountedRef from './useIsMountedRef';

const useFetch = (getFunction, dependencies = [], executionCondition = true) => {
  const [data, setData] = useState([]);
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false);

  const isMountedRef = useIsMountedRef();

  const fetchFunction = async () => {
  if (isMountedRef.current && executionCondition) {
    setLoading(true); 
  
    try {
      
    const response = await getFunction(); 
    if (response?.data) {
      setData(response.data);
      setReady(true);
    }
    } catch (error) { 
      if (error.response?.data?.error==="TokenExpiredException") {
      localStorage.removeItem('accessToken');
      localStorage.setItem('tokenExpired', 'true');
      window.location.href = "/auth/login";
      };
      
    } finally {
      setLoading(false);
    }
  }
};

  useEffect( () => {
     fetchFunction();
  }, [...dependencies]);

  return [data, loading, fetchFunction, ready];
};

export default useFetch;
