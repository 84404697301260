export function getDataPost (data) {
  const {
    id,
    apellido,
    idEmpresa,
    idRol,
    identificador,
    nombre,
    pais,
    pathFoto,
    telefono,
    ubicacion,
    newPassword,
    repeatPassword,
  } = data;

  return {
    id,
    apellido,
    idEmpresa,
    idRol,
    idPais: pais?.id,
    identificador,
    nombre,
    pathFoto,
    telefono,
    ubicacion:ubicacion.direccion? ubicacion : null,
    clave: newPassword && repeatPassword ? newPassword : null
  }
}