import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import GuestGuard from '../guards/GuestGuard';
import Loadable from '../layouts/fleet-layout/loadable/Loadable';
import PublicMarketplace from '../views/viewWithoutLogin/PublicMarketplace';
import PublicOrderView from '../views/viewWithoutLogin/PublicOrderView';
import SubscriptionPaymentNotUser from '../views/Pay/SubscriptionPaymentNotUser';
import ViewCheckoutStrpe from '../views/Pay/ViewCheckoutStrpe';

/* ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
const WelcomeLayout = Loadable(lazy(() => import('../layouts/welcome-layout/WelcomeLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/register/Admin')));
const Register2 =  Loadable(lazy(() => import('../views/register/Register')));
const Request  =  Loadable(lazy(() => import('../views/register/components/Request')));
const ViewWithoutLogin = Loadable(lazy(() => import('../views/viewWithoutLogin/ViewWithoutLogin')));
const TasksAssignedThirdParties = Loadable(
  lazy(() => import('../views/workflow/TasksAssignedThirdParties/TasksAssignedThirdParties')),
);
const AssignedListings = Loadable(
  lazy(() => import('../views/workflow/TasksAssignedThirdParties/AssignedListings')),
);


const RouterThird = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <WelcomeLayout />
      </GuestGuard>
    ),
    children: [
      { path: '/', element: <Login/> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
  
  {
    path: '/auth',
    element: (
        <BlankLayout />
    ),
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      // { path: 'register', element: <Register /> },
      { path: 'register', element: <Register2 /> },
      { path: 'solicitud', element: <Request /> },
      { path: 'recuperar', element: <Login /> },
      { path: 'nueva-contrasena', element: <Login /> },
      { path: '*', element: <Navigate to="/" /> },

    ],
  },
  {
    path: '/marketplace',
    exact: true,
    children: [
      { path: '/:url', element: <PublicMarketplace /> },
      { path: '/detalle/:id', element: <PublicOrderView /> },
    ],
  },
  {
    path: '/pay/:id',
    exact: true,
    element: <SubscriptionPaymentNotUser />,
  },
  {
    path: '/stripe/:id',
    exact: true,
    element: <ViewCheckoutStrpe />,
  },
  {
    path: '/seguimiento/:travelid',
    exact: true,
    element: <ViewWithoutLogin />,
  },
  {
    path: '/seguimiento/:travelid/:clientid',
    element: <ViewWithoutLogin />,
  },
  {
    path: '/workflow/proceso-procedimiento',
    element: <TasksAssignedThirdParties />,
  },
  { path: '/asignados/:mail', element: <AssignedListings /> },
];

export default RouterThird;
