const paisData = {
  id: "",
  nombre: ""
}

const ubicacionData = {
  codigoPostal: "",
  direccion: "",
  latitud: 0,
  localidad: "",
  longitud: 0,
  nombre: "",
  observacion: "",
  pais: {
    ...paisData
  },
  provincia: "",
  referencia: ""
}

export const getInitialValues = (user) => {
  if (user) {
    return {
      ...user,
      ubicacion: user.ubicacion || { ...ubicacionData},
    }
  }

  return {
    email: '',
    nombre: '',
    apellido: '',
    pais: null,
    telefono: '',
    ubicacion: { ...ubicacionData },
    newPassword: '',
    repeatPassword: '',
  };
}