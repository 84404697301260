import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/system';
import Joyride, { STATUS } from 'react-joyride';

export default function useTour(steps, runTour, disableScrolling) {
  const theme = useTheme();

  const joyrideStyles = {
    options: {
      zIndex: 10000,
      primaryColor: theme.palette.primary.dark,
      textColor: '#1E1E1E',
    },
    buttonNext: {
      backgroundColor: '#ffbf3c',
      color: '#1E1E1E',
    },
    buttonBack: {
      color: '#1E1E1E',
    },
    buttonClose: {
      display:'none'
    },
    overlay:{
      position: 'fixed', 
    }
  };

  const [run, setRun] = useState(false);

  useEffect(() => {
    if (runTour) {
      setRun(true);
    }
  }, [runTour]);

  const handleJoyrideCallback = useCallback( (data)=> {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  }, []);

  const tour = useMemo(
    () => (
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={joyrideStyles}
        disableScrolling={disableScrolling}
        // disableOverlayClose
      />
    ),
    [steps, handleJoyrideCallback, run],
  );

  return tour;
}
