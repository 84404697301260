const typography = {
  fontFamily: "'DM Sans', sans-serif",
  body1: {
    fontWeight: 400, // or 'bold'
  },
  h1: {
    fontWeight: 600,
    fontSize: '38.19px',
    lineHeight: '76.38px',
  },
  h2: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '38.19px',
  },
  h3: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '28.64px',
  },
  h4: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  h6: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  button: {
    textTransform: 'none',
    fontWeight: '400',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: '400',
  },
  p: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '19.09px',
  },
  small: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '14.32px',
  },
  icons: {
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: ' 11.93px',
  },
};

export default typography;
