import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router';
import { Grid,Typography, Button,Divider,Box, FormControlLabel, Checkbox, CardHeader } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';

import { getPromotion } from '../../api/planPromotions';
import { getTranslation } from '../../utils/translation';
import { newCompanyUser } from '../../api/auth';
import { getInitialValues } from '../registerV2/utils/initialize';
import { validation } from '../registerV2/utils/validation';
import { getList } from '../registerV2/utils/listValues';

import LogoIcon from '../../layouts/fleet-layout/logo/LogoIcon';
import useAuth from '../../hooks/useAuth';
import LocalCardPayment from './LocalCardPayment'
import PlanDetail from '../../components/planDetails/PlanDetails';
import RegisterForm from '../registerV2/components/RegisterForm';
import ViewCheckoutStrpe from './ViewCheckoutStrpe';



const SubscriptionPaymentNotUser = () => {
  const [plan, setPlan] = useState('')
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchBox, setSearchBox] = useState(null);
  const [listValues, setListValues] = useState({});
  const { id: planId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [t, i18]=useTranslation()
  const [total, setTotal]= useState(plan.monto)

  const [counter, setCounter] = useState(15);
  const handleRedirect =  () => window.location.replace('/inicio');


  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const handleCreate = async (companyValues) => {
    setLoadingSubmit(true);
    try {
      const objeto = {
        actividadPrincipal: companyValues.actividadPrincipal.id.id,
        apellidoUsuario: companyValues.apellidoUsuario,
        identificadorFiscalEmpresa: companyValues.identificadorFiscalEmpresa,
        Identificador: companyValues.identificadorFiscalEmpresa,
        mailEmpresa: companyValues.mailEmpresa,
        mailInstitucionalEmpresa: companyValues.mailEmpresa,
        mailUsuario: companyValues.mailEmpresa,
        nombreEmpresa: companyValues.nombreEmpresa,
        nombreUsuario: companyValues.nombreUsuario,
        razonSocialEmpresa: companyValues.razonSocialEmpresa,
        telefonoUsuario: companyValues.telefonoUsuario,
        suscripcion: companyValues.suscripcion,
        free: false,
        tyc: companyValues.tyc,
        newsletter: companyValues.newsletter,
        cuenta: companyValues.cuenta,
        ubicacion: {
          direccion: companyValues.direccion,
        },
      };
      newCompanyUser(objeto).then((res) => {
        /* auth.login(objeto.mailEmpresa, objeto.identificadorFiscalEmpresa)
          .then( () => {
            navigate('/configuracion/usuario')
            showMessage('Usted se ha registrado correctamente a Follow®', 'success');
          })
          .catch( (error) => {
            console.log(error);
      showMessage(error?.response?.data?.error || `${t('Errors.Server')}`, 'error');
          }); */
      })
      .catch( (error) => {
        console.log(error);
  showMessage(error?.response?.data?.error || `${t('Errors.Server')}`, 'error');
      });
      
    } catch (error) {
      console.log(error);
      showMessage(error?.response?.data?.error || `${t('Errors.Server')}`, 'error');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    validationSchema: validation(listValues),
    validateOnChange: false,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      handleCreate(values);
    },
  });

  const loadListValues = async () => {
    try {
      const listValuesLoaded = await getList();
      const actividadPrincipal = listValuesLoaded.actividadPrincipal
      .map(actividad => {return {id:actividad, label:getTranslation(i18, actividad)}})
      const listValuesLoadedTraslate = {
        ...listValuesLoaded,
        actividadPrincipal
      }
      setListValues(listValuesLoadedTraslate);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  function getLatlong(address) {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        address,
      },
       (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          setCoordinates({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        }
      },
    );
  }

  const onPlacesChanged = async (property) => {
    const places = searchBox.getPlaces();
    const place = places && places[0];

    if (!place) {
      formik.setFieldValue('direccionInfo', null);
      formik.setFieldValue(property.id, '');
    } else {
      formik.setFieldValue('direccionInfo', place.address_components);
      formik.setFieldValue(property.id, place.formatted_address);
      getLatlong(place.formatted_address);
    }
  };

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const handleCheckboxChange = (event) => {
    const checkboxName = event.target.name;
    const isCheckboxChecked = event.target.checked;
    formik.setFieldValue(checkboxName, isCheckboxChecked);
  };


  useEffect(() => {
    loadListValues()
    localStorage.setItem('language','es')
    const init = async() => {
      if(planId) {
       const {data}=  await getPromotion(planId)
        setPlan(data);
      };
    }
    init()
    return () => {
      setPlan({})
    }
  }, [])
   
  return (
           <Grid
            container

            sx={{
              paddingInline: '2rem',
              paddingBottom: '2rem',
              margin: '0 auto',
              borderRadius:"1rem",
              marginTop: '1rem',
              backgroundColor: "rgb(255, 255, 255)",
              maxWidth: '1500px',
              width: '100%',
  
            }}
          >    
          <LogoIcon /> 
          <CardHeader
          titleTypographyProps={{ fontSize: 24 }}
          title={
            <Box display="flex" flexDirection="column" alignItems="left">
              <span>{t('Login.Welcome-to-Follow')}</span>
            </Box>
          }
        />
        <Divider sx={{ backgroundColor: 'orange', width:'100%' }} />
            <Grid item xs={12} textAlign="center" sx={{ margin: '2rem' }}>
              <Typography variant="h2" component="h2">
                {`${t('subscriptions.Preparemos tu suscripción')} ${plan.nombre}`}
              </Typography>
              <Typography variant="p" component="h3">
                 {t("subscriptions.Without obligation.")}
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                  {t("subscriptions.Cancel whenever you want.")}
                </Typography>
              </Typography>
            </Grid>
            <Grid xs={12} container spacing={1}>
              <Grid item xs={6} >
                <RegisterForm
                    formik={formik}
                    listValues={listValues}
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                  />
                <Grid container direction="column">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="tyc"
                        checked={formik.values.tyc}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="h4" fontWeight="500" ml={1}>
                      {t('Login.Acepto los')} <u onClick={handleOpen}>{t('Login.Términos y Condiciones')}</u>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="newsletter"
                        checked={formik.values.newsletter}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="h4" fontWeight="500" ml={1}>
                        {t('Login.Quiero suscribirme al Newsletter')}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} > 
                <PlanDetail promo={plan } total={total} setTotal={setTotal}/>
                <Grid>
                  {plan && formik.values.tyc ? (plan.pais.nombre === 'Argentina' || plan.pais.nombre === 'Chile') ? (<LocalCardPayment plan={plan} formik = {formik} total={total}/>)
                  : (<ViewCheckoutStrpe plan={plan} formik = {formik}/>):""}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
  )
}

export default SubscriptionPaymentNotUser