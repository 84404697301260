import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client'; // Importa createRoot
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './contexts/AuthContext';
import App from './App';
import { configureStore } from './redux/Store';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';
import { GoogleMapsProvider } from './contexts/MapContext';
import TranslateCalendar from './components/Translation/TranslateCalendar';
import { ScreenSizeProvider } from './contexts/ScreenSizeContext';
import './fonts/fonts.css';

// Obtén el elemento root
const container = document.getElementById('root');
const root = createRoot(container); // Crea el root

root.render(
  <Provider store={configureStore()}>
    <ScreenSizeProvider>
      <Suspense fallback={<Spinner />}>
        <GoogleMapsProvider>
            <BrowserRouter>
              <AuthProvider>
                <SnackbarProvider dense maxSnack={3}>
                  <TranslateCalendar>
                    <App />
                  </TranslateCalendar>
                </SnackbarProvider>
              </AuthProvider>
            </BrowserRouter>
        </GoogleMapsProvider>
      </Suspense>
    </ScreenSizeProvider>
  </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();