import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAZrsv4vawVYR5cR4nEmI7Dq7lONVUraGU",
  authDomain: "follow-ef631.firebaseapp.com",
  projectId: "follow-ef631",
  storageBucket: "follow-ef631.appspot.com",
  messagingSenderId: "731091640154",
  appId: "1:731091640154:web:d23ff4258457fe06c95d63"
};

const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

// getToken(messaging, { vapidKey:"BAjKvQ67LYG1yNbRAEUQBibSm5mIfgCknhUiSU39i-zIZmgFRIM4wrnPB2DqpfD5jabHicxj5HHBYh8SOTrtCOo"}).then((currentToken) => {
