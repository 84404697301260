import React,{useContext, useState} from 'react'
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    IconButton,
    Typography,
    DialogContent,
  } from '@mui/material';
  
import DialogContainer from '../modals/DialogContainer';
import InquiryForm from './InquiryForm';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';

const Consultation = () => {
    
    const [t]= useTranslation()
    const { widthScreen } = useContext(ScreenSizeContext);
    const [open, setOpen] = useState(false)

    const onClose = () =>{
        setOpen(false)
    }
    const title=t("send-consult.Send us your query")
  return (
    <>
    <Box>
        <IconButton sx={{ mr: '0.5px', display:'flex'}} onClick={()=>setOpen(true)}>
        {widthScreen >= 650 && <Typography sx={{ paddingX:"5px" }} >{t("send-consult.consultation")}</Typography>}    
          <FeatherIcon icon="message-circle" width="20" height="20" />
      </IconButton>
    </Box>

    <DialogContainer
    open = {open}
    handleClose = {onClose}
    title = {title}
    dialogProps = {{
      width: 'md',
      maxWidth: 'md',
    }}
    
  >
    <DialogContent>
          < InquiryForm  onClose={onClose}/>
        </DialogContent>
    </DialogContainer>
    
    </>
  )
}

export default Consultation