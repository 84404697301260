import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ShowAlert = ({ open, handleClose, msg, title }) => {

  const [t] = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      sx = {{ zIndex: '3000' }}
    >
      <DialogTitle id="alert-dialog-slide-title" variant="h4">
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" component="div">
          {msg}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          {t('Button.Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShowAlert.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ShowAlert;