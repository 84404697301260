import { t } from 'i18next';
import * as Yup from 'yup';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
// const webRegExp = /^(www)?.+\.[a-z]{2,6}(\.[a-z]{2,6})?.+\.[a-z]{2,4}$/;
const webRegExp = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_+.~#?&//=]*)/;
const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validation = (listValues) => {
  return Yup.object().shape({
    nombreUsuario: Yup.string()
      .min(3, `${t('Validation.La cant min es 3')}`)
      .max(60, `${t('Validation.La cant max es 60')}`)
      .required(`${t('Poster.Required-field')}`),
    apellidoUsuario: Yup.string()
      .min(3, `${t('Validation.La cant min es 3')}`)
      .max(60, `${t('Validation.La cant max es 60')}`)
      .required(`${t('Poster.Required-field')}`),
    razonSocialEmpresa: Yup.string()
      .min(3, `${t('Validation.La cant min es 3')}`)
      .max(60, `${t('Validation.La cant max es 60')}`)
      .required(`${t('Poster.Required-field')}`),
    identificadorFiscalEmpresa: Yup.string()
      .min(4, `${t('Validation.La cant min es 4')}`)
      .max(60, `${t('Validation.La cant max es 60')}`)
      .required(`${t('Poster.Required-field')}`),
      direccion: Yup.string().required(`${t('Poster.Required-field')}`),
      direccionInfo: Yup.array()
        .required(`${t('Validation.The address has not been verified')}`)
        .nullable(),
    actividadPrincipal: Yup.object()
      .required(`${t('Poster.Required-field')}`)
      .nullable(),
    nombreEmpresa: Yup.string()
      .min(3, `${t('Validation.The minimum number of characters is')}3`)
      .max(60, `${t('Validation.La cant max es 60')}`)
      .required(`${t('Poster.Required-field')}`),
    telefonoUsuario: Yup.string()
      .matches(phoneRegExp, `${t('Validation.Num tel no val')}`)
      .required(`${t('Poster.Required-field')}`),
    mailEmpresa: Yup.string()
      .matches(emailRegExp, `${t('Validation.Invalid-email')}`)
      .required(`${t('Poster.Required-field')}`),
      // .email(`${t('Validation.Invalid-email')}`)
  });
};
