import * as React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Typography, Stepper, Step, StepLabel } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

const OrderLineState = ({ detailOrder }) => {
  const [t] = useTranslation();
  const [state, setState] = React.useState(null);
  // console.log(detailOrder);

  const formatDateTime = (e, status) => {
    if (e) {
      const dateTime = new Date(e);
      dateTime.setHours(dateTime.getHours() - 3);

      const date = status === detailOrder.estado ? e.split('T') : dateTime.toISOString().split('T');
      const hour = date[1].split(':');
      return moment(`${date[0]} ${hour[0]}:${hour[1]}`).format('YYYY-MM-DD - H:mm');
    }
    return null;
  };

  const sortArrayByDate = (x, y) => {
    const firstDate = new Date(x.date);
    const secondDate = new Date(y.date);

    if (firstDate < secondDate) return -1;
    if (firstDate > secondDate) return 1;
    return 0;
  };

  const convertObjectToArray = (obj) => {
    const keys = Object.keys(obj.historialEstados);
    const values = Object.values(obj.historialEstados);
    const { historialUbicacionEstados } = obj;

    let array = [];

    for (let i = 0; i < keys.length; i++) {
      array[i] = {
        date: keys[i],
        status: values[i],
      };
    }

    array = array.map((obj1) => {
      const matchingObj2 =
        historialUbicacionEstados &&
        historialUbicacionEstados.find((obj2) => obj2.estado === obj1.status);
      if (matchingObj2) {
        return {
          ...obj1,
          lat: matchingObj2.lat,
          lng: matchingObj2.lon,
          coordinates: { lat: matchingObj2.lat, lng: matchingObj2.lon },
        };
      }
      return obj1;
    });
    array.sort(sortArrayByDate).push({
      date:
        obj.fechaFinReal && obj.estado === 'Finalizado'
          ? obj.fechaFinReal
          : (new Date(), 'YYYY-MM-DD - H:mm'),
      status: obj.estado,
    });

    for (let i = 0; i < array.length; i++) {
      if (i < array.length - 1) {
        array[i].date = formatDateTime(array[i].date);
      }

      array[i].next =
        array[i].status === 'Pendiente'
          ? 'Planificado'
          : array[i].status === 'Planificado'
          ? 'En Ejecucion'
          : array[i].status === 'En Ejecucion'
          ? 'Finalizado'
          : null;
    }
    return array;
  };

  React.useEffect(() => {
    if (detailOrder?.historialEstados) {
      setState(convertObjectToArray(detailOrder));
    }
  }, []);

  // console.log(state);

  const estadosAsignacionPlanificado = state && state
    ?.map((item, index) => (item.status === 'Planificado' ? item.date : null))
    .filter((date) => date !== null);

  // console.log(estadosAsignacionPlanificado);

  const estadoAsignacionEjecucion = state && state
    ?.map((item, index) => (item.status === 'En Ejecucion' ? item.date : null))
    .filter((date) => date !== null);

  // console.log(estadoAsignacionEjecucion[0]);

  const timelineData = [
    {
      id: 'pendiente',
      time: `${detailOrder.alta.slice(0, 10)}`,
      title: t('OM.Status.Pendiente'),
      description: `${detailOrder.alta ? detailOrder.alta.slice(0, 16).replace('T', ' - '):''}`,
      color: 'success.main',
      status: detailOrder.estado !== 'Pendiente',
      icon:
        detailOrder.estado === 'Pendiente' ? (
          <FeatherIcon icon="circle" />
        ) : (
          <FeatherIcon icon="check-circle" />
        ),
    },
    {
      id: 'planificado',
      time: 
      `${estadosAsignacionPlanificado && estadosAsignacionPlanificado.length > 0 ? 
        estadosAsignacionPlanificado[0]?.slice(0, 10) : ''}`,
      title: t('OM.Status.Planificada'),
      description:  
      `${estadosAsignacionPlanificado && estadosAsignacionPlanificado.length > 0 ? 
        estadosAsignacionPlanificado[0]?.slice(0, 18) : ''}`,
      color:
        detailOrder.estado === 'Planificado' ||
        detailOrder.estado === 'En Ejecucion' ||
        detailOrder.estado === 'Finalizado'
          ? 'success.main'
          : 'warning.main',
      status: detailOrder.estado === 'En Ejecucion' || detailOrder.estado === 'Finalizado',
      icon:
        detailOrder.estado === 'En Ejecucion' || detailOrder.estado === 'Finalizado' ? (
          <FeatherIcon icon="check-circle" />
        ) : (
          <FeatherIcon icon="circle" />
        ),
    },
    {
      id: 'ejecucion',
      time: 
      `${estadoAsignacionEjecucion && estadoAsignacionEjecucion.length > 0 ? 
        estadoAsignacionEjecucion[0]?.slice(0, 10) : ''}`,
      title: t('OM.Status.En ejecución'),
      description:
      `${estadosAsignacionPlanificado !== null && estadoAsignacionEjecucion > 0
        ?  estadoAsignacionEjecucion[0]?.slice(0, 18) : ''}`,
      color:
        detailOrder.estado === 'En Ejecucion' || detailOrder.estado === 'Finalizado'
          ? 'success.main'
          : 'warning.main',
      status: detailOrder.estado === 'Finalizado',
      icon:
        detailOrder.estado === 'Finalizado' ? (
          <FeatherIcon icon="check-circle" />
        ) : (
          <FeatherIcon icon="circle" />
        ),
    },
    {
      id: 'finalizada',
      time: `${ detailOrder?.fechaFinReal ? detailOrder?.fechaFinReal?.slice(0, 10):''}`,
      title: t('OM.Status.Finalizada'),
      description: `${detailOrder?.fechaFinReal ? detailOrder?.fechaFinReal?.slice(0, 16): ''}`,
      color:
        detailOrder.estado === 'Finalizado' ? 'success.main' : 'warning.main' || detailOrder.estado !== null,
      status: detailOrder.estado === 'Finalizado',
      icon:
        detailOrder.estado === 'Finalizado' ? (
          <FeatherIcon icon="check-circle" />
        ) : (
          <FeatherIcon icon="circle" />
        ),
    },
  ];

  return (
    <>
        <Stepper >
      {timelineData.map(({ id, time, title, description, color, icon, status }) => (
          <Step key={id} completed={status} >
          <StepLabel icon={icon} sx={{ py: '12px', px: 2, color: `${color}` }}>
          <Typography variant="h5" component="span">
              {title}
            </Typography>
            </StepLabel>
            <Typography >{description}</Typography>
          </Step>
      ))}
      </Stepper>
    </>
  );
};

export default OrderLineState;
