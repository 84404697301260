import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Box, Table, TableBody, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../../components/container/PageContainer';
import Breadcrumb from '../../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import TableCard from '../../../components/table-card/TableCard';
import useFetch from '../../../hooks/useFetch';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { getFavorites } from '../../../api/favorite';

const FavoriteDetails = () => {
  const [t] = useTranslation();
  const formItems = [
    {
      id: 'razonSocial',
      label: 'Razón social',
      xs: 12,
    },
    {
      id: 'nombre',
      label: 'Nombre',
      xs: 12,
    },
    {
      id: 'apellido',
      label: 'Apellido',
      xs: 12,
    },

    {
      id: 'pais',
      label: 'Pais',
      xs: 12,
    },
    {
      id: 'provincia',
      label: 'Provincia',
      xs: 12,
    },
    {
      id: 'identificador',
      label: 'Identificador fiscal',
      xs: 12,
    },
    {
      id: 'mail',
      label: 'Email',
      xs: 12,
    },
    {
      id: 'telefono',
      label: 'Teléfono',
      xs: 12,
    },
    {
      id: 'nombreContacto',
      label: t('Loads.Setting.Contact-person'),
      xs: 12,
    },
    {
      id: 'direcciones',
      label: 'Direcciones',
      xs: 12,
    },
  ];

  const BCrumb = [
    {
      to: '/carga/inicio',
      title: 'Home',
    },
    {
      title: 'Favoritos',
    },
    {
      title: 'Detalle Favorito',
    },
  ];

  const [favorites] = useFetch(getFavorites);
  const { id: favoriteId } = useParams();
  const [favorite, setFavorite] = useState();

  useEffect(() => {
    if (favoriteId) {
      const favoriteSelected = favorites?.content?.find(
        (favoriteSearched) => favoriteSearched.id === favoriteId,
      );
      setFavorite(favoriteSelected);
    }
  }, [favorites]);

  return (
    <PageContainer title="Detalle de Favorito" description="this is Favorite Details page">
      <Box display="flex" alignItems="center">
        <Breadcrumb items={BCrumb} />
      </Box>
      <TableCard title={`${favorite?.contacto?.razonSocial}`} custompadding="0px">
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <TableBody>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: 40, marginTop: 20, marginLeft: 2 }}
              >
                {formItems.map((property) => {
                  return (
                    <Grid
                      item
                      xs={property.xs}
                      key={property.id}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Grid item xs={2}>
                        <CustomFormLabel htmlFor={property?.id}>{property?.label}:</CustomFormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        {property?.label === 'Pais' ? (
                          <TextField
                            id="demo-helper-text-misaligned-no-helper"
                            label={`${favorite?.contacto?.[`${property?.id}`]?.nombre}`}
                            disabled
                            sx={{
                              width: '95%',
                            }}
                          />
                        ) : (
                          <TextField
                            id="demo-helper-text-misaligned-no-helper"
                            label={`${favorite?.contacto?.[`${property?.id}`]}`}
                            disabled
                            sx={{
                              width: '95%',
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                <Box width="100%" display="flex" justifyContent="flex-end" mr={3} mt={2}>
                  <Button
                    to="/carga/favoritos"
                    component={Link}
                    sx={{
                      mt: 2,
                      mr: 2,
                      pt: '10px',
                      pb: '10px',
                      color: '#000',
                    }}
                  >
                    Volver
                  </Button>
                </Box>
              </Grid>
            </TableBody>
          </Table>
        </Box>
      </TableCard>
    </PageContainer>
  );
};

export default FavoriteDetails;
