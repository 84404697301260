import React, { useState } from "react";

import { FormControl, Input, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";


const CustomSearchBox = ({ doSearch, placeHolder, width, setCurrentSearchValue, ...props }) => {
    const [searching, setSearching] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const keyUp = (event) => {
        if (props?.activeSearch) {
            doSearch(searchValue);
            // setSearching(true);
        }

        if (event.key === "Enter") {
            doSearch(searchValue);
            setSearching(true);
        }
    };

    const getEndAdornment = () => {
        if (searching) {
            return (
                <InputAdornment
                    position="end"
                    onClick={() => {
                        setSearching(false);
                        setSearchValue("");
                        setCurrentSearchValue("");
                        doSearch("");
                    }}
                />
            );
        }
        return <></>;
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                flex: 1,          
                width:"100%"      
            }}
        >
            <FormControl style={{ width }}>
                <Input
                    id="input-with-icon-adornment"
                    placeholder={placeHolder}
                    value={searchValue}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    endAdornment={getEndAdornment()}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setCurrentSearchValue(e.target.value)
                    }}
                    onKeyUp={keyUp}
                    disabled={props.disabled}
                    onClick={() => {
                        setSearching(false);
                    }}
                    autoComplete = 'off'
                    type = {props?.type}
                    sx = {{
                      '& input[type=number]': {
                          mozAppearance: 'textfield'
                      },
                      '& input[type=number]::-webkit-outer-spin-button': {
                          webkitAppearance: 'none',
                          margin: 0
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                          webkitAppearance: 'none',
                          margin: 0
                      },
                   
                      width:"100%"
                    }}
                />
            </FormControl>
        </div>
    );
};

export default CustomSearchBox;


CustomSearchBox.defaultProps = {
    width: 400,
}
