const listValidAutocomplete = [
  // PRE-REGISTRO
  { id: "country", optionLabel: "nombre", listValue: "countryList", placeholder: "Seleccione un país" },
  { id: "userType", optionLabel: "label", listValue: "userTypes" },

  // REGISTRO NUEVA EMPRESA
  { id: "pais", optionLabel: "nombre", listValue: "countryList", placeholder: "Seleccione un país" },
  { id: "actividadPrincipal", optionLabel: "label", listValue: "actividadPrincipal", placeholder: "Seleccione una actividad" },

  // UNIRSE A UNA EMPRESA
  { id: "company", optionLabel: "razonSocial", listValue: "companyList", placeholder: "Seleccione una compañia"  },
]

export const validAutocomplete = (value) => {
  const valid = listValidAutocomplete.find(item => item.id === value);
  return valid || null
}