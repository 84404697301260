import { Button, Grid } from '@mui/material';
import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import useAuth from '../../../hooks/useAuth'
import { deleteUser } from '../../../api/users';
import DeleteAccount from './DeleteAccount';

const Account = ({ history }) => {
  const [t] = useTranslation();
  const { authenticatedData, logout } = useAuth();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userDelete, setUserDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  

  const handleDeleteUser = async () => {
    setUserDelete(true);
    try {
      await deleteUser(authenticatedData?.id);
      enqueueSnackbar(t('Configuration.UserDelete'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
      logout();
    } catch (e) {
      enqueueSnackbar(t('Configuration.UserDeleteError'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    } finally {
      setOpenDeleteModal(false);
      setUserDelete(false);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={100}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}
            sx={{
              mt: 4,
            }}
          >
            Eliminar cuenta
          </Button>
        </Grid>
      </Grid>
      <DeleteAccount
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleConfirm={handleDeleteUser}
        disabled={userDelete}
      />
    </>
  );
};

export default Account;