import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Slide, LinearProgress } from '@mui/material';

import TableCard from '../../../components/table-card/TableCard';
import ActionsListTable from '../../../components/table-card/ActionsListTable';
import ModalEditUser from './ModalEditUser';

import { inviteUser, deleteUser, acceptUser } from '../../../api/company';
import ModalAcceptUser from './ModalAcceptUser';
import { CheckPermissions } from '../../../utils/relatedToPermissions';
import useAuth from '../../../hooks/useAuth';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PendingUsersList = ({ users, url, setUrl, loadNewInformation, showAlert, loadingList, setReload }) => {
  const [t] = useTranslation();
  const auth = useAuth();
  const p = auth?.allPermissions;
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openAcceptUser, setOpenAcceptUser] = useState(false);
  const [userMailEdit, setUserMailEdit] = useState('');
  const [userRolEdit, setUserRolEdit] = useState();
  const [userIdEdit, setUseIdEdit] = useState();

  const [loading, setLoading] = useState();

  const handleOpenEditUser = (mail, rol, idUser) => {
    setUserMailEdit(mail);
    setUserRolEdit(rol);
    setUseIdEdit(idUser);
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleCloseAcceptUser = () => {
    setOpenAcceptUser(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteUser(id);
      loadNewInformation();
      showAlert('Usuario eliminado con exito', 'success');
    } catch (error) {
      console.log(error);
      showAlert(error?.response?.data?.error || 'Ocurrio un error en el servidor', 'error');
    }
    finally {
      setLoading(false);
    }
  };

  const handleAccept = async (user) => {
    setLoading(true);
    setUserMailEdit(user.mail);
    setUseIdEdit(user.id)
    if(user.rol?.nombre === 'Chofer') {
      try {
        const { id, idRol } = user;
        acceptUser(id, idRol)
        setReload(true)
      } catch (error) {
        console.log(error);
        showAlert(error?.response?.data?.error || 'Ocurrio un erron en el servidor', 'error');
      }
    } else {
      setOpenAcceptUser(true);
    }
    try {
      /* await acceptUser(id, idRol); */
      loadNewInformation();
      /* showAlert('Usuario aceptado con exito', 'success'); */
    } 
    catch (error) {
      console.log(error);
      showAlert(error?.response?.data?.error || 'Ocurrio un erron en el servidor', 'error');
    }
    finally {
      setLoading(false);
      setReload(false)
    }
  }

  const resendInvitation = async (user) => {
    try {
      const respUrl = await inviteUser(user.mail.replace("+","%2B"), user.rol.id);
      setUrl(respUrl.data.slice(0, respUrl.data.indexOf('=') + 1));
    } catch (error) {
      console.error(new Error(error));
    }
  };

  return (
    <TableCard title={t('Configuration.SubTitleTeam')} custompadding="0px">

      <Box
        sx={{
          overflow: {
            xs: 'auto',
            sm: 'unset',
          },
        }}
      >

        {
          loadingList ?
          <LinearProgress />
          :
          <Table
            aria-label="simple table"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h3" width = '50%'>{t('Configuration.Mail')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3" width = '40%'>{t('Configuration.Role')}</Typography>
                </TableCell>
                <TableCell width = '10%' />
              </TableRow>
            </TableHead>
            <TableBody>
            {
              users && Array.isArray(users) && users.length > 0 ? users.map((user) => {
              return (
                <TableRow hover key={user?.id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h4">
                        {user?.mail}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">
                        {user?.rol.nombre}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <ActionsListTable
                      conditionals={user?.estado}
                      data={user}
                      resendInvitation={resendInvitation}
                      textToCopy={`${url}${user.id}`}
                      handleAccept = {(user.rol?.nombre === 'Asociado' || user.rol?.nombre === 'Chofer') && CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.CRE_USU])? () => handleAccept(user):null}
                      handleEdit={user.rol?.nombre !== 'Asociado' && CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.EDI_USU])? 
                      () => handleOpenEditUser(user.mail, user?.rol.id, user?.id):null}
                      handleDelete={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.ELI_USU])? () => {
                        handleDelete(user.id)
                      }: null}
                      disabled = {loading}
                    />
                  </TableCell>
                </TableRow>
              );
            })
            : 
            <TableRow>
              <TableCell colSpan={3}>
                <Box display = "flex" alignItems = "center" justifyContent = "center" width = {1} p = {2}>
                  <Typography variant="body2">
                  {t('Loads.Setting.There-are-no-pending-users')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            }
            </TableBody>
          </Table>
        }

        <ModalEditUser
          open={openEditUser}
          Transition={Transition}
          handleClose={handleCloseEditUser}
          userMailEdit={userMailEdit}
          userRolEdit={userRolEdit}
          userIdEdit={userIdEdit}
          loadNewInformation={loadNewInformation}
        />
        <ModalAcceptUser 
          open={openAcceptUser}
          Transition={Transition}
          handleClose={handleCloseAcceptUser}
          userMailEdit={userMailEdit}
          userRolEdit={userRolEdit}
          userIdEdit={userIdEdit}
          loadNewInformation={loadNewInformation}
          setReload={setReload}
        />
      </Box>
    </TableCard>
  );
};

export default PendingUsersList;
