import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getPromotionsList } from '../../../api/planPromotions';

const ListItemPlan = ({ item }) => {
  return (
    <ListItem
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ListItemText
        primary={item}
        textAlign="center"
        primaryTypographyProps={{ fontWeight: 'bold', textAlign: 'center' }}
        sx={{
          height: '3rem',
          maxWidth: '250px',
          minWidth: '150px',
        }}
      />
    </ListItem>
  );
};

const PlansSection = ({ promocion }) => {
  const [plans, setPlanes] = useState();
  const [loading, setLoading] = useState(false);

  const [t] = useTranslation();

  const getActivePromo = (plan) => {
    const activePlan = promocion?.some((objeto2) => objeto2.nombre === plan.nombre);
    return activePlan;
  };

  const theme = useTheme();
  const navigate = useNavigate();

  const getPlans = async () => {
    setLoading(true);
    try {
      const { data } = await getPromotionsList('COMPLETA');
      setPlanes(data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleSuscribir = (plan) => {
    navigate(`/configuracion/pay/${plan.id}?monto=${plan.monto}`);
  };

  if (loading)
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );

  return (
    <Grid
      sx={{
        border: '5px solid rgb(255,191,60)',
        borderRadius: '1rem',
      }}
    >
      <Typography variant="h2" align="center" color={theme.palette.grey[500]} marginY="1rem">
        {t('Subscribe to plan')}
      </Typography>

      <Grid container sx={{ overflowX: 'auto', flexWrap: 'nowrap' }}>
        <Grid
          width="100%"
          sx={{
            mx: '0.5rem',
          }}
        >
          <Typography
            px="0.5rem"
            variant="h2"
            sx={{
              height: '3rem',
              backgroundColor: 'rgb(255,191,60)',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '5px solid rgb(255,191,60)',
            }}
          >
            {t('Characteristics')}
          </Typography>

          <List sx={{ backgroundColor: 'transparent', fontWeight: 'bold' }}>
            <ListItemPlan key="Espacio de almacenamiento" item={t('Storage space')} />
            <ListItemPlan key="Tiempo de almacenamiento" item={t('Storage time')} />
            <ListItemPlan key="Cargas mensuales" item={t('Monthly charges')} />
            <ListItemPlan key="Cantidad de transportistas" item={t('Number of carriers')} />
            <ListItemPlan key="Entrenamiento" item={t('Training')} />
            <ListItemPlan key="Renovacion" item={t('Renewal')} />
          </List>
        </Grid>
        {plans?.map((plan) => (
          <Grid
            key={plan.id}
            width="100%"
            sx={{
              mx: '0.5rem',
            }}
          >
            <Typography
              px="0.5rem"
              variant="h3"
              sx={{
                height: '3rem',
                backgroundColor: 'rgb(255,191,60)',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {plan.nombre !== 'Free' ? plan.nombre.slice(11) : plan.nombre}
            </Typography>

            <List sx={{ backgroundColor: 'transparent', fontWeight: 'bold' }}>
              <ListItemPlan item={`${plan.almacenamiento} GB`} label="Espacio de almacenamiento" />
              <ListItemPlan
                item={plan.diasAlmacenamiento ? `${plan.diasAlmacenamiento} ${t('days')}` : '-'}
                label="Tiempo de almacenamiento"
              />
              <ListItemPlan item={plan.cantidadAOperar} label="Cargas mensuales" />
              <ListItemPlan item={t('unlimited')} label="Cantidad de transportistas" />
              <ListItemPlan item={`${plan.entrenamiento} Hs`} label="Entrenamiento" />
              <ListItemPlan key={plan.id} item={t('Monthly')} label="Renovacion" />
            </List>
            <Grid display="flex" justifyContent="center" width="100%">
              <Typography variant="h2">${plan.monto.toLocaleString('es-ES')} </Typography>
            </Grid>
            <Grid display="flex" justifyContent="center" my="1rem" width="100%">
              <Button
                variant="contained"
                size="small"
                style={{ fontSize: '1rem' }}
                disabled={getActivePromo(plan)}
                onClick={() => handleSuscribir(plan)}
              >
                {getActivePromo(plan) ? t('ACTIVE') : t('SUBSCRIBED')}
              </Button>
            </Grid>
          </Grid>
        ))}
        <Grid
          width="100%"
          sx={{
            mx: '0.5rem',
          }}
        >
          <Typography
            px="0.5rem"
            variant="h3"
            sx={{
              height: '3rem',
              backgroundColor: 'rgb(255,191,60)',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '5px solid rgb(255,191,60)',
            }}
          >
            CUSTOM
          </Typography>

          <List sx={{ backgroundColor: 'transparent', fontWeight: 'bold' }}>
            <ListItemPlan item="1 GB" />
            <ListItemPlan item="-" />
            <ListItemPlan item="-" />
            <ListItemPlan item={t('unlimited')} />
            <ListItemPlan item="1 hs" />
            <ListItemPlan item={t('Monthly')} />
          </List>
          <Grid display="flex" justifyContent="center" width="100%">
            <Typography variant="h2"> - </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" my="1rem" width="100%">
            <Button
              variant="contained"
              size="small"
              style={{ fontSize: '1rem' }}
              onClick={() => navigate('/configuracion/contactanos')}
            >
              {t('CONTACT')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlansSection;
