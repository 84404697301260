import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Marker, InfoWindow } from '@react-google-maps/api';
import {
  Card,
  Alert,
  Box,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
} from '@mui/material';

import TruckIcon from '../../../../assets/images/icons/cargo/truck.png';
import Map from '../../../../components/Map';

const CardMap = ({
  variousMarkers,
  justAMarker,
  showInfoWindow,
  setShowInfoWindow,
  emptyResult,
  searchText,
}) => {
  // console.log(variousMarkers);
  // console.log(justAMarker);
  const [t] = useTranslation();

  const navigate = useNavigate();

  const truckCenter = variousMarkers.map((item) => item.nextWP);
  // console.log(truckCenter)
  // console.log(truckCenter.length > 0)

  const infoRender = (numero, destino, transporte, descripcion, location, id) => {
    return (
      <InfoWindow onCloseClick={() => setShowInfoWindow(null)} position={location}>
        <Card style={{ margin: 3 }}>
          <CardHeader title={`${t('Loads.Planning_View.Order-number')} #${numero}`} />
          <Divider />
          <CardContent variant="outlined">
            <p>
              {t('Loads.Planning_View.Destination')} {destino}
            </p>
            <p>
              {t('Loads.Planning_View.Transport')} {transporte}{' '}
            </p>
            <p>
              {t('Loads.Planning_View.Description')} {descripcion}
            </p>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate(`/flota/viajes-detalles/${id}`)}
            >
              {t('Map.See detail')}
            </Button>
          </CardActions>
        </Card>
      </InfoWindow>
    );
  };

  const showInfo = (key) => {
    if (key === showInfoWindow) {
      return;
    }
    setShowInfoWindow(key);
  };

  // variables para mapa
  const onMapLoad = (dataMap) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (variousMarkers.length > 0) {
      variousMarkers.map((item) => {
        const { points, ubicacionInicial } = item;

        if (points && points.lat && points.lon) {
          return bounds.extend({
            lat: points.lat,
            lng: points.lon,
          });
        }

        if (ubicacionInicial?.latitud && ubicacionInicial?.longitud) {
          return bounds.extend({
            lat: ubicacionInicial?.latitud,
            lng: ubicacionInicial?.longitud,
          });
        }
        return null;
      });
      dataMap.fitBounds(bounds);
    }
  };
  const center =
    truckCenter.length > 0
      ? { lat: truckCenter[0]?.latitud, lng: truckCenter[0]?.longitud }
      : { lat: justAMarker?.lat, lng: justAMarker?.lng };

  // console.log(center);

  const zoom = truckCenter.length > 0 ? 5 : 13;

  return (
    <Card style={{ marginBottom: 100, margin: 0 }}>
      {emptyResult && searchText.length > 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert severity="info"> {t('Map.No results found')}</Alert>
        </Box>
      ) : null}
      <Map location={center} zoom={zoom} onMapLoad={onMapLoad}>
        <div>
          {variousMarkers.length > 0 ? (
            variousMarkers?.map((travel) => {
              const {
                ubicacionInicial,
                ubicacionFinal,
                cargas,
                points,
                id,
                empresaTransportadora,
              } = travel;

              const destino = ubicacionFinal ? ubicacionFinal.direccion : '-';
              const transporte = empresaTransportadora;
              const numero = cargas ? cargas[0]?.nroCarga : '-';
              const descripcion = cargas ? cargas[0]?.descripcion : '-';
              let validPoints;

              if (points) {
                validPoints = points?.lat !== null && points?.lon !== null;
              } else {
                validPoints =
                  ubicacionInicial?.latitud !== null && ubicacionInicial?.longitud !== null;
              }
              const currentLocation = points
                ? { lat: points?.lat, lng: points?.lon }
                : { lat: ubicacionInicial?.latitud, lng: ubicacionInicial?.longitud };

              return (
                <div key={id}>
                  {validPoints && (
                    <Marker
                      onClick={() => showInfo(id)}
                      icon={{ url: TruckIcon, scaledSize: { width: 40, height: 40 } }}
                      position={currentLocation}
                    >
                      {showInfoWindow === id
                        ? infoRender(numero, destino, transporte, descripcion, currentLocation, id)
                        : null}
                    </Marker>
                  )}
                </div>
              );
            })
          ) : (
            <Marker
              icon={justAMarker?.icon}
              position={{ lat: justAMarker?.lat, lng: justAMarker?.lng }}
            />
          )}
        </div>
      </Map>
    </Card>
  );
};

export default CardMap;
