import React, { createContext, useState } from 'react';

const SelectdViewContext = createContext();

export const SelectdViewProvider = ({ children }) => {
  const [infoSelectd, setInfoSelectd] = useState({});

  return (
    <SelectdViewContext.Provider
      value={{
        infoSelectd,
        setInfoSelectd,
      }}
    >
      {children}
    </SelectdViewContext.Provider>
  );
};


export default SelectdViewContext;
