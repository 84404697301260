import React from 'react';
import { Box, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';

import CHECKPOINT from '../assets/images/icons/markers/CheckpointMarker.png';
import CHECKPOINTSTART from '../assets/images/icons/markers/CheckpointMarkerStart.png';
import CHECKPOINTEND from '../assets/images/icons/markers/CheckpointMarkerEnd.png';

const RouteDetails = ({ routes, fullWidth = false, sx = {} }) => {

  const theme = useTheme();

  const total = routes?.reduce((acum, route) => acum + route.distanceNumber, 0);
  const totalKm = (total / 1000).toFixed(2);


  return (
    <Grid container spacing = {2} sx = {sx}>
      {
        routes.map((route, index) =>
          
          <Grid
            item xs = {12} lg = { !fullWidth ? 6 : 12 } key = {`detalle-ruta-${index.toString()}`}
            display = 'flex' alignItems = 'stretch' justifyContent = 'space-between'
          >
            <Paper
              variant = "outlined"
              sx = {{
                bgcolor: theme.palette.background.default,
                width: 1, p: 2, height: 1,
                display: 'flex', justifyContent: 'space-between', flexDirection: 'column'
              }}
            >
              <Box sx = {{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <Box display = 'flex' alignItems = 'center'>
                  <img 
                    src = { index === 0 ? CHECKPOINTSTART : CHECKPOINT }
                    alt = {`marcador-ruta-${index.toString()}-A`}
                    style = {{ height: '32px', width: '32px' }}
                  />
                  <Typography variant = 'h4' ml = {1}>{String.fromCharCode(index + 65)}</Typography>
                </Box>

                <Box display = 'flex' flexDirection = 'column'>
                  <Typography variant = 'body2' textAlign = 'center' color = {theme.palette.text.secondary} mb = {1}>
                    {route.start}
                  </Typography>
                  <Typography variant = 'body2' textAlign = 'center' color = {theme.palette.text.secondary}>
                    {route.end}
                  </Typography>
                </Box>

                <Box display = 'flex' alignItems = 'center'>
                  <Typography variant = 'h4' mr = {1}>{String.fromCharCode(index + 66)}</Typography>
                  <img 
                    src = { index === routes.length - 1 ? CHECKPOINTEND : CHECKPOINT }
                    alt = {`marcador-ruta-${index.toString()}-B`}
                    style = {{ height: '32px', width: '32px' }}
                  />
                </Box>
              </Box>

              <Box>
                <Divider sx = {{ mt: 1, mb: 2 }}/>
                <Typography variant = 'h4' textAlign = 'center'>{route.distanceText}</Typography>
              </Box>
            </Paper>
          </Grid>
        )
      }
      <Grid item xs = {12}>
        <Paper
          variant = "outlined"
          sx = {{
            bgcolor: theme.palette.background.default,
            width: 1, p: 2
          }}
        >
          <Typography variant = 'h4' textAlign = 'center'>Total: {totalKm} km</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RouteDetails;
