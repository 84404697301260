import axios from 'axios';
import { COUNTRY_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getCountries = async (searchParam, signal = null) => {
  return axios.get(`${COUNTRY_PATH}/listado`, {
    params: {
      searchParam,
    },
    signal,
  });
};

export const createCountry = async (feature) => {
  return axios.post(`${COUNTRY_PATH}/guardar`, feature);
};

export const getCountriesListPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${COUNTRY_PATH}/listado?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};
export const getAllCountriesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${COUNTRY_PATH}/listado?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deleteCountry = async (id) => {
  return axios.delete(`${COUNTRY_PATH}/eliminar?id=${id}`);
};
