import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
} from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { useSnackbar } from 'notistack';

import ActionsListRole from './ActionsListRole';
import ModalViewRole from './ModalViewRole';
import ModalViewTeamUserRole from './ModalViewTeamUserRole';
import EnchancedModal from './EnchancedModal';
import NewRol from './NewRol';
import ModalConfirm from '../../../components/modals/ModalConfirm';

import { getRolList, deleteRol } from '../../../api/rol';
import { CheckPermissions } from '../../../utils/relatedToPermissions';
import useAuth from '../../../hooks/useAuth';

const RolesCreate = () => {
  const [t] = useTranslation();
  const auth = useAuth();
  const p = auth?.allPermissions;
  const [rolList, setRolList] = useState();
  const [openRolCreateDialog, setOpenRolCreateDialog] = useState();
  const [choosenRole, setChoosenRole] = useState(null);
  const [viewModalRole, setViewModalRole] = useState(false);
  const [viewModalTeamRole, setViewModalTeamRole] = useState(false);
  const [viewModalDelete, setViewModalDelete] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const showAlert = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type, anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  }

  async function loadRoles () {
    try {
      const { data } = await getRolList();
      setRolList(data);
    }
    catch (error) {
      console.log(error);
      setRolList([]);
    }
    finally {
      setLoading(false);
    }
  }

  const handleApplyModalClose = () => {
    setOpenRolCreateDialog(false);
    setLoading(true);
    loadRoles();
  };

  function handleView(rol) {
    setChoosenRole(rol);
    setViewModalRole(true);
  }

  function handleViewUsers(rol) {
    setChoosenRole(rol);
    setViewModalTeamRole(true);
  }

  function handleEdit(rol) {
    setChoosenRole(rol);
    setOpenRolCreateDialog(true);
  }

  function handleDelete(rol) {
    setChoosenRole(rol);
    setViewModalDelete(true);
  }

  async function handleConfirmDelete() {
    setLoadingDelete(true);
    try {
      await deleteRol(choosenRole.id);
      setViewModalDelete(false);
      setLoading(true);
      loadRoles();
      showAlert(t('Loads.Setting.NewRoleDeleted'), 'success');
    } catch (error) {
      console.log(error);
      setViewModalDelete(false)
      showAlert(
        error?.response?.data?.error || 
        error?.response?.data?.message ||
        t('Configuration.ServerError'),
        'error'
      );
    } finally {
      setLoadingDelete(false);
    }
  }

  useEffect(() => {
    loadRoles();
  }, []);

  return (
    <Box>
      <Card>
        {
          !openRolCreateDialog ? (
          <CardContent>
            <Typography variant="h2" p={2}>

            {t("Loads.Setting.My-roles")}
            </Typography>
            <Grid container mb={2}>
              <Grid item xs={12}>
                <List
                  dense
                  sx={{ width: '100%', bgcolor: (theme) => theme.palette.background.paper }}
                >
                {
                  loading ?
                  <LinearProgress />
                  :
                  rolList?.length > 0 ?
                  rolList.map((rol) => {
                    return (
                      <ListItem
                        key={rol.id}
                        sx={{
                          '&:hover': { bgcolor: (theme) => theme.palette.background.default },
                        }}
                      >
                        <ListItemIcon>
                          <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${rol.nombre}`} />
                        <ActionsListRole
                          handleView={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.VER_ROL])? () => handleView(rol): null}
                          handleEdit={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.EDI_ROL])? () => handleEdit(rol): null}
                          handleDelete={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.ELI_ROL])? () => handleDelete(rol): null}
                          handleViewUsers={CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.VER_USU])? () => handleViewUsers(rol): null}
                        />
                      </ListItem>
                    );
                  })
                  : <></>
                }
                </List>
              </Grid>
            </Grid>
            <Button
              disabled={!CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.CRE_ROL])}
              sx={{
                ml: 2,
                p: 2,
              }}
              variant="contained"
              onClick={() => setOpenRolCreateDialog(true)}
            >
              <Box fontWeight="400">{t("Button.Add-role")}</Box>
            </Button>
          </CardContent>
          )
        : 
        (
          <NewRol
            handleApplyModalClose = {handleApplyModalClose}
            choosenRole = {choosenRole}
            setChoosenRole = {setChoosenRole}
          />
        )}
      </Card>
      <ModalViewRole
        handleClose={() => {
          setChoosenRole(null);
          setViewModalRole(false);
        }}
        viewModal={viewModalRole}
        setViewModal={setViewModalRole}
        role={choosenRole}
      />
      <ModalViewTeamUserRole
        setChoosenRole={setChoosenRole}
        setViewModalTeamRole={setViewModalTeamRole}
        viewModal={viewModalTeamRole}
        setViewModal={setViewModalTeamRole}
        role={choosenRole}
      />
      <ModalConfirm
        open = {viewModalDelete}
        handleClose = {() => setViewModalDelete(false)}
        handleConfirm = {handleConfirmDelete}
        msg = {`${t('Loads.Setting.RoleNameConfirmation')} ${choosenRole && choosenRole.nombre ? choosenRole.nombre : ''}`}
        title = {t('Loads.Setting.DeleteRolConfirmation')}
        disabled = {loadingDelete}
      />
    </Box>
  );
};

export default RolesCreate;
