import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography, useTheme, alpha,Paper} from '@mui/material';

import DialogContainerPlans from '../../../components/modals/DialogContainerPlans';

const ModalEnableModule = ({ open, handleClose, loading, plans, selectedPlan, handleClick, handleContinue, planActivo}) => {


  const defaultSelection=  plans.find((objeto) => objeto.monto === 0);
  
  const [t] = useTranslation();
  const theme = useTheme();
  const [choosenPlan, setChoosenPlan] = useState(defaultSelection?.id);



  function handleChange (e) {
    const { value } = e.target;
    setChoosenPlan(value);
  }

  useEffect(() => {
    if (!open) return
    if (selectedPlan) {
      setChoosenPlan(selectedPlan);
    }
    else {
      setChoosenPlan(defaultSelection?.id);
    }
  }, [open]);
  
  return (
    <DialogContainerPlans
      open = {open}
      handleClose = {handleClose}
      title = 'Selecciona tu plan'
      loading = {loading}
      dialogProps = {{
        width: 'xs',
        maxWidth: 'xs',
      }}
    >
      <Grid>
        <Grid display="flex">
        <Typography
        variant='h3'
        sx={{color:alpha(theme.palette.primary.dark, 0.8), 
         backgroundColor:"#fff", p:"0 0.5rem",
         fontWeight: "600",
      }}>{t("Plans.Plans")} </Typography>
        <Typography 
         variant='h3'
        sx={{color:"#fff", 
          p:"0 0.5rem",
          fontWeight: "600",
          }}> 
          {plans[0]?.tipoPromocion === "CARGA" && t("Plans.for load givers")}
          {plans[0]?.tipoPromocion === "FLOTA" && t("Plans.for fleets")}
          </Typography>
        </Grid>

      <Paper elevation = {10} sx = {{ p:"1rem 0 1rem 0", borderRadius:"0"}}>
      <FormControl variant = 'standard' disabled = {loading} >
        <RadioGroup
          value = {choosenPlan}
          onChange={handleChange}
        >
          <Grid >
          {
            plans?.map((plan, index) =>
              <Grid item xs = {12} key = {`plan-${plan.id}-${index.toString()}`} p="5px 0" >
                
                  <Grid xs={12} sx={{  display:"flex", justifyContent:"space-between" }} >
                    
                      <FormControlLabel
                        xs={12}
                        value={plan.id}
                        control={
                        <Radio 
                          sx={{ display: "none",}}
                           disabled={planActivo && plan.nombre === planActivo.nombre || plan.nombre === "Custom"} />
                          }
                        label={
                          <Typography variant = 'h3' ml = {1} sx={{ 
                            backgroundColor: plan.id === choosenPlan ? "green" : alpha(theme.palette.primary.dark, 0.8),
                            padding:"0 1rem 0 1rem",
                            borderBottomRightRadius:"1rem",
                            color:"#ffff",
                            "&:hover": {
                              backgroundColor: plan.nombre === "Custom" ? alpha(theme.palette.primary.dark, 0.8) : "green",
                              height: plan.nombre === "Custom" ? "auto" : "120%",
                            },
                            }}>
                              {plan.nombre}
                            </Typography>
                       
                        }
                        sx={{
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.text.primary
                          }
                        }}
                      />
                    
                    
                    <Typography variant = 'h3' mr = {1} color="green">{planActivo && plan.nombre === planActivo.nombre && t("Plans.Asset") } </Typography>
                  </Grid>
                  
               <Grid 
                 item xs={12}
                 sx={{
                display:"flex",
                justifyContent:"space-between",
                }}>

                  <Grid item xs = {8} sx={{ paddingLeft:"5px" }} >
                   {plan.nombre === "Free" && <Typography p="5px"
                   sx={{fontWeight: "600"}}
                   >{t("Plans.Operate safely within our platform")}</Typography>}

                    {plan.nombre !== "Free" && plan.nombre !== "Custom" &&(
                      <Grid paddingY="5px">
                    <Typography sx={{fontWeight: "600"}}>
                    {plans[0]?.tipoPromocion === "CARGA" && `${plan.cantidadAOperar} ${t("Plans.Loads")}`}
                    {plans[0]?.tipoPromocion === "FLOTA" && `${t("Plans.Up to")} ${plan.cantidadAOperar} ${t("Plans.Loads")}`} 
                    </Typography>
                    <Typography sx={{fontWeight: "600"}}>{`${plan.almacenamiento} Gb ${t("Plans.Digital documentation")}`}</Typography>
                    </Grid>
                    )
                    }
                     <Box display = 'flex' justifyContent = 'flex-start'>
                      <IconButton onClick = {() => handleClick(plan, index)}>
                        <Typography variant = 'h4' color = {theme.palette.primary.main}>
                        {plan.nombre==='Custom'?t('Button.Contac-an-advisor'):t('Button.View-details')}
                        </Typography>
                      </IconButton>
                    </Box>
                  </Grid>

                  <Grid item xs = {4}>
                    <Typography variant = 'h3' align="end" paddingRight="5px">

                      { plan.nombre !== "Custom" && `$${plan.monto} ${plan.moneda.simbolo}` }
                    </Typography>
                  </Grid>
                  </Grid>
                
              </Grid>
            )
          }
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box mt = {2} pr={3} display = 'flex' justifyContent = 'flex-end'>
        <Button
          onClick = {() => handleContinue(choosenPlan)}
          variant = "outlined"
          color = "primary"
          size = 'small'
          bgColor="#fff"
          sx = {{ width: '150px', p: 1 }}
        >
          <Typography variant = 'h4'>
            {t('Button.Continue')}
          </Typography>
        </Button>
      </Box>
      </Paper>
      </Grid>
      
    </DialogContainerPlans>
  );
};

export default ModalEnableModule;