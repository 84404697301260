import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import OrderView from '../marketplace/dashboard/OrderView';
import { useActionHook } from '../marketplace/dashboard/cargas/DetalleDeCarga/actionsHook';
import ShowAlert from '../marketplace/components/ShowAlert';
import SelectdViewContext from '../../contexts/SelectdViewContext';
import ActionButton from '../marketplace/dashboard/cargas/components/ActionButton';
import { getPublicOrderDetail } from '../../api/marketplace';
import { listarContraofertasRecibidas } from '../../api/contraoferta';
import useAuth from '../../hooks/useAuth';

const PublicOrderView = () => {
  const { authenticatedData, isAuthenticated } = useAuth();

  const [infoSelected, setInfoSelected] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [noOrderError, setNoOrderError] = useState(false);

  const { infoSelectd } = useContext(SelectdViewContext);
  const { id: orderId } = useParams();

  const [ options, setOptions ]  = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ title: '', msg: '' });

  function showAlertModal(alertTitle, alertMsg) {
    setAlert({
      title: alertTitle,
      msg: alertMsg
    });
    setShowAlert(true);
  }

  function reloadOrderInActions (orderToReload, actions) {
    let newActions = {};
    actions.forEach(a => {
      newActions = {
        ...newActions,
        [a.title.toLowerCase()]: {
          ...a,
          action: () => a.action(orderToReload, showAlertModal)
        }
      }
    }
    );
    return newActions;
  }

  async function getOffersReceived (orderIdToSearch) {
    try {
      const { data: offersFound } = await listarContraofertasRecibidas(orderIdToSearch);
      // setOfertasRecibidas(offersFound);
      return offersFound;
    } catch (error) {
      // setOfertasRecibidas([]);
      return [];
    }
  }

  function getOfferStatus (offers) {
    const { idEmpresa } = authenticatedData;

    let oferto = 0;

    for (let i = 0; i < offers?.length || 0; i++) {
      if (offers[i].estado === 'ACEPTADO') 
        return offers[i].creador.idEmpresa === idEmpresa ? 'OFERTA ACEPTADA' : 'OFERTA RECHAZADA - CERRADA';
      if (offers[i].creador.idEmpresa === idEmpresa) {
        if (offers[i].estado === 'PENDIENTE') return 'OFERTA PENDIENTE';
        oferto++;
      } 
    }

    return oferto > 0 ? 'OFERTA RECHAZADA - ABIERTA' : 'ABIERTA';
  }

  async function loadOrder() {
    try {
      const { data: orderFound } = await getPublicOrderDetail(orderId);
      setInfoSelected(orderFound);
      if (orderFound.id && isAuthenticated) {
        if (orderFound.recibirOfertas || orderFound.estadoPedidoNombre === 'Ofertado' ) {
          const historialDeOfertas = await getOffersReceived(orderFound.id);
          orderFound.historialOfertas = historialDeOfertas;
          orderFound.estatus = getOfferStatus(historialDeOfertas);
        }
        setInfoSelected(orderFound);
      }
      else setNoOrderError('El pedido no existe');
    } catch (e) {
      console.log(e);
      setServerError('Error del servidor');
    }
  }

  useEffect(() => {
    if (orderId && orderId.length > 0) {
      loadOrder();
    }
  }, [orderId]);

  const { aceptar, contactar, preguntar, contraoferta, compartir } = useActionHook(infoSelectd, loadOrder);

  function showOfferStatus(status) {
    let mensaje = '';
    switch (status) {
      case "OFERTA ACEPTADA":
        mensaje = 'Tu oferta ya ha sido aceptada.';
        break;
      case "OFERTA RECHAZADA - CERRADA":
        mensaje = 'Ya no se reciben nuevas ofertas en esta publicacion.'
        break;
      case "OFERTA PENDIENTE":
        mensaje = 'Ya has realizado una oferta. Espera a la respuesta del dador.'
        break;
      case "OFERTA RECHAZADA - ABIERTA":
      default:
        mensaje = '';
    }
    return mensaje;
  }

  function getOptions (orderFound) {
    const optionsWithOrder = reloadOrderInActions(orderFound, [ aceptar, contactar, preguntar, contraoferta, compartir ]);
    let optionsToAdd = [optionsWithOrder.contactar, optionsWithOrder.preguntar, optionsWithOrder.compartir];
    if (!orderFound.recibirOfertas) optionsToAdd = [ optionsWithOrder.aceptar, ...optionsToAdd ];
    else optionsToAdd = [ 
      orderFound?.estatus?.includes('ABIERTA') ? 
        optionsWithOrder.contraoferta :
        {
          title: 'Contraoferta',
          header: 'Atencion',
          body: showOfferStatus(orderFound.estatus),
          buttonLabel: 'aceptar',
          action: () => {},
        }
      ,
      ...optionsToAdd
    ];
    setOptions(optionsToAdd);
  }

  useEffect(() => {
    if (infoSelected && infoSelected.id) {
      getOptions(infoSelected);
    }
  }, [infoSelected]);

  return (
    <>
      <OrderView
        infoSelectd = {infoSelected}
        serverError = {serverError}
        setServerError = {setServerError}
        noOrderError = {noOrderError}
        reloadOrder = {loadOrder}
      >
        {' '}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', float: 'rigth' }}>
          <ActionButton options={options} handleClose={() => {}} />{' '}
        </Box>
      </OrderView>
      <ShowAlert
        open = {showAlert}
        handleClose = {() => setShowAlert(false)}
        msg = {alert.msg}
        title = {alert.title}
      />
    </>
  )
};

export default PublicOrderView;
