import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import TableCard from '../../../components/table-card/TableCard';
import { getOtByDriverId } from '../../../api/viaje';
import useAuth from '../../../hooks/useAuth';

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  // const { id: driverId } = useParams();
  const { authenticatedData } = useAuth();
  // console.log(authenticatedData);
  const [travel, setTravel] = useState(null);
  const [driverId, setDriverId] = useState('');

  async function getTravels(id) {
    try {
      const { data } = await getOtByDriverId(driverId);
      setTravel(data.content);
    } catch (e) {
      console.log(e);
    }
  }

  function setId() {
    setDriverId(authenticatedData.id);
  }

  useEffect(() => {
    setId();
    getTravels(driverId);
  }, [driverId]);

  const { order, orderBy, onRequestSort } = props;

  const headCells = [
    {
      id: 'viaje',
      numeric: false,
      disablePadding: false,
      label: 'Nro de Viaje',
    },
    {
      id: 'destino',
      numeric: false,
      disablePadding: false,
      label: 'Destino',
    },
    {
      id: 'inicio',
      numeric: false,
      disablePadding: false,
      label: 'Inicio',
    },
    {
      id: 'fin',
      numeric: false,
      disablePadding: false,
      label: 'Fin',
    },
    {
      id: 'km',
      numeric: false,
      disablePadding: false,
      label: 'Kms',
    },
    {
      id: 'estado',
      numeric: false,
      disablePadding: false,
      label: 'Estado',
    },
  ];

  return (
    <TableHead sx={{ height: '54px' }}>
      <TableRow>
        {!travel && (
          <TableCell
            key={headCells.id}
            align={headCells.numeric ? 'right' : 'left'}
            padding={headCells.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCells.id ? order : false}
          >
            <Box display="flex" alignItems="center" justifyContent="center" width={1} p={2}>
              <Typography variant="h4" fontWeight="600">
                No se registran viajes
              </Typography>
            </Box>
          </TableCell>
        )}
        {travel &&
          headCells.map((headCell) => (
            <TableCell key={headCell.id}>
              <Typography variant="h3">{headCell.label}</Typography>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const HistoricTravels = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [travel, setTravel] = useState(null);
  const [travelId, setTravelId] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { authenticatedData } = useAuth();
  const [driverId, setDriverId] = useState('');

  const handleRequestSort = (evenet, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function setId() {
    setDriverId(authenticatedData.id);
  }

  async function getTravels(id) {
    try {
      const { data } = await getOtByDriverId(driverId);
      setTravel(data?.content);

      const getTravelId = data?.content?.map((row) => row.id);
      setTravelId(getTravelId);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setId();
    getTravels(driverId);
  }, [driverId]);

  const navigate = useNavigate();

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - travel.length) : 0;

  return (
    <TableCard title="Historial de viajes" custompadding="0px">
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={travel?.lenght}
          />
          <TableBody>
            {travel &&
              stableSort(travel)
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow key={labelId}>
                      <TableCell fontWeight="600">{row.nroViaje}</TableCell>
                      <TableCell>{row.ubicacionFinal.direccion}</TableCell>
                      <TableCell>{row.partida.slice(0, 10)}</TableCell>
                      <TableCell>{row.llegada.slice(0, 10)}</TableCell>
                      <TableCell>{row.kms}</TableCell>
                      <TableCell>{row.estadoViaje}</TableCell>
                    </TableRow>
                  );
                })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableCard>
  );
};

// HistoricTravels.propTypes = {
//   driver: PropTypes.arrayOf(PropTypes.object),
// };

export default HistoricTravels;
