import axios from 'axios';
import { PALLET_TYPE } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getPalletTypes = async () => {
  return axios.get(`${PALLET_TYPE}/listado`);
};

export const createPalletType = async (nombre) => {
  return axios.post(`${PALLET_TYPE}/guardar`, { nombre });
};

export const getPalletTypesPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${PALLET_TYPE}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};
export const getAllPalletTypesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${PALLET_TYPE}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deletePalletType = async (palletId) => {
  return axios.delete(`${PALLET_TYPE}/eliminar?id=${palletId}`);
};
