export const CreateItems = (t) => {
  return [
    {
      id: 'nombreUsuario',
      label: t('Configuration.Name'),
      xs: 7,
      required: true,
    },
    {
      id: 'apellidoUsuario',
      label: t('Configuration.LastName'),
      xs: 7,
      required: true,
    },
    {
      id: 'mailEmpresa',
      label: t('Configuration.Mail'),
      xs: 7,
      required: true,
    },
    {
      id: 'identificadorFiscalEmpresa',
      label: t('Loads.Setting.Tax-ID'),
      xs: 7,
      required: true,
    },
    {
      id: 'nombreEmpresa',
      label: 'Nombre de empresa',
      xs: 7,
      required: true,
    },
    {
      id: 'razonSocialEmpresa',
      label: t('Loads.Setting.Business-name'),
      xs: 7,
      required: true,
    },
    {
      id: 'direccion',
      label: t("Configuration.Address"),
      placeholder: t('Fleet.Home.New-So.Enter-address'),
      xs: 7,
      hideLabel: true,
      required: true
    },
    {
      id: 'direccionInfo',
      label: '',
      xs: 7,
      // onlyError: true
    },
    {
      id: 'telefonoUsuario',
      label: t('Configuration.Phone'),
      xs: 7,
      placeholder: 'e.g +54 911 1234-5678',
      required: true,
    },
    {
      id: 'actividadPrincipal',
      label: t('Loads.Setting.Main-activity'),
      xs: 7,
      hideLabel: true,
      required: true,
    },
  ];
};

