import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box,  Grid, Typography } from '@mui/material';
import {  useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FeatherIcon from 'feather-icons-react';
import useAuth from '../../hooks/useAuth';
import { menuItemProps, menuTypographyProps, menuIconsProps, buttonProps, disabledMenuItemProps, disabledButtonProps } from './StyleProps';


const HeaderShortcut = ({ path, handleClose, xs, title, icon, isFeatherIcon, isLogout, authenticatedData, modulo, disabled }) => {

  const navigate = useNavigate();
  const { logout } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const userModules = auth.authenticatedData.modulosActivos;
  const modulos = useSelector((state) => state.CustomizerReducer.modulosActivos); 
  const modulosSeguro = !modulos || modulos.length === 0 ? userModules : modulos;

  const showAlert = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    });
  }

  function handleNavigate(name) {
    if (authenticatedData.rol.id === '5' && modulo) {
      showAlert('No tiene permisos para acceder a esta página', 'warning');
      return;
    }
    if (!modulosSeguro.includes(name) && modulo) {
      return;
    }
    handleClose();
   // navigate(getPath ? getPath(authenticatedData.actividadPrincipal) : path);
    navigate(path)
  }

  function handleLogout () {
    logout();
    navigate("/auth/login")
  }


  
  return (
    <Grid
      item
      xs={xs}
      sx={
        disabled
          ? { ...disabledButtonProps }
          : {
              ...buttonProps,
              '&:hover': {
                '.imgIcon': {
                  filter: `invert(100%)`,
                },
                '.titleIcon': {
                  color: 'white',
                },
              },
            }
      }
      onClick={isLogout ? handleLogout : disabled ? null : () => handleNavigate(modulo)}
    >
      <Box
        sx={
          disabled
            ? { ...disabledMenuItemProps, opacity: 0.5}
            : {
                ...menuItemProps,
              }
        }
      >
        {!isFeatherIcon ? (
          <img
            src={icon}
            style={{
              width: 40,
              height: 40,
            }}
            className="imgIcon"
            alt={`${title}-menu-icon`}
          />
        ) : (
          <FeatherIcon
            color={disabled ? 'grey' : null}
            icon={icon}
            {...menuIconsProps}
            className="titleIcon"
          />
        )}
        <Typography {...menuTypographyProps} className="titleIcon">
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};

export default HeaderShortcut;