import { useTranslation } from 'react-i18next';

export const FormUserItems = () => {
  const [t] = useTranslation();
  return [
    {
      id: 'mail',
      label:  t('Mail'), 
      xs: 12,
      disabled: true,
    },
    {
      id: 'nombre',
      label: t('Name'),
      xs: 12,
    },
    {
      id: 'apellido',
      label: t('LastName'),
      xs: 12,
    },
    {
      id: 'telefono',
      label: t('Phone'),
      xs: 12,
    },
    {
      id: 'pais',
      label: t('Configuration.CountryUser'),
      xs: 12,
    },
    {
      id: 'ubicacion',
      extraProperty: 'direccion',
      label: t('Address'),
      placeholder: t('Select.Reference'),
      xs: 12,
    },
  ];
};

export const FormPasswordItems = () => {
  const [t] = useTranslation();
  return [
    {
      id: 'newPassword',
      label: t('NewPass'),
      xs: 12,
      type: 'password',
    },
    {
      id: 'repeatPassword',
      label: t('RepeatPassword'),
      xs: 12,
      type: 'password',
    },
  ];
};
