import React from 'react';
import {
  Box,
  Grid,
  Paper,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FolderBusiness from '../../../assets/images/logos/FolderBusiness.png';
import FolderPremium from '../../../assets/images/logos/FolderPremium.png';

const Storage = ({ promotions, handleContinue }) => {
  const [t] = useTranslation();
  const theme = useTheme(); 
  return (
    <Grid container spacing={2}>
      { promotions && promotions?.map((promo) => {
        return (
          <Grid item xs={12} md={6}>
            <Paper
              variant="outlined"
              sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                flexDirection: 'column',
                mt: 2,
                borderTop: `16px solid ${theme.palette.warning.main}`,
              }}
            >
              <Box
                minHeight="200px"
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                flexDirection="column"
              >
                <Box
                  height="150px"
                  width="150px"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.warning.main,
                  }}
                >
                  {promo?.nombre === 'Premium' ? (
                    <img
                      alt="Icono del modulo"
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      src={FolderPremium}
                    />
                  ) : (
                    <img
                      alt="Icono del modulo"
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      src={FolderBusiness}
                    />
                  )}
                </Box>
              </Box>
              <Typography variant="h2" mt={1} textAlign="center">
                {promo?.nombre}
              </Typography>
              <Typography variant="h3" mt={1} textAlign="center">
              &#40; ${promo?.monto} &#41;
              </Typography>
              <Typography variant="body1" mt={1} textAlign="center" fontStyle="italic">
                {promo?.info}
              </Typography>
              <Box sx={{ wordBreak: 'break-word' }}>
                <Typography variant="h3" sx={{ mt: 4 }}>
                  {promo?.almacenamiento} GB
                </Typography>
              </Box>
              <ToggleButton
                fullWidth
                value={promo?.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.warning.main,
                  '&:hover': {
                    backgroundColor: theme.palette.warning.main,
                  },
                }}
                onClick={() => handleContinue(promo?.id)}
              >
                <Typography
                  variant="h2"
                  textAlign="center"
                  letterSpacing="2px"
                  fontWeight={900}
                  color="whitesmoke"
                >
                  {t('Button.Buy-Storage')}
                </Typography>
              </ToggleButton>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Storage;
