import React, { createContext, useEffect, useState } from 'react';

import { getOrderDetail } from '../../../../api/order';

const OsContext = createContext({});

// const dataExamples = {
//   alta: '2024-06-20T17:16:37.740Z',
//   direccion: 'string', // si
//   done: true, // ??
//   id: 'string', // si
//   idCarga: 'string', // si
//   idViaje: 'string', // hace falta?
//   isUbicacionDestino: true, // a que hace referencia a si ahora como se ordeno es el ultimo?? xq cuando se edita el origin y el destino se pueden modificar
//   isUbicacionOrigen: true, // a que hace referencia a si ahora como se ordeno es el primero?? xq cuando se edita el origin y el destino se pueden modificar
//   latitud: 0, // si
//   llegada:  // esto es mobile
//   llegadaEstimada: '2024-06-20T17:16:37.740Z', // si // setear esta en el route info // es la sumatoria de todos los tiempos '2024-06-20T17:16:37.740Z',
//   longitud: 0, // si
//   nroCarga: 'string', // ?? hace falta
//   nroViaje: 'string', // ?? hace falta
//   orden: 0, // si
//   tiempoServicio: 0, // esto solo si es un punto de control que tengo tiempo // si
//   tiempoServicioReal: 0, // // esto es mobile
//   tiempos: [
//     // sumatorias de tiempos adicionales agregados  // si
//     {
//       descripcion: 'string', // si
//       id: 'string', // no mando
//       idWaypoint: 'string', // solo se manda cuando ya existe, no cuando se crea uno nuevo
//       tiempoServicio: 0, // si
//     },
//   ],
//   wp: [
//     // esto es null si es un punto de control solo lo tienen los puntos de carga y descarga // los puntos ubicacionDesde y ubicacionHasta pueden
//     // ser o no un punto de carga y descarga, y no tienen en un ninguna lado la propiedad  wp
//     {
//       done: true,
//       id: 'string',
//       idCarga: 'string',
//       idPedido: 'string',
//       idWaypoint: 'string',
//       instruccion: {
//         alta: '2024-06-20T17:16:37.740Z',
//         archivos: ['string'],
//         baja: '2024-06-20T17:16:37.740Z',
//         checkList: {
//           descripcion: 'string',
//           emailResponsable: 'string',
//           id: 'string',
//           nombre: 'string',
//           nombreEmpresa: 'string',
//           nombreResponsable: 'string',
//           plantillaChecklist: {
//             asignada: true,
//             emailCreador: 'string',
//             id: 'string',
//             idCreador: 'string',
//             idEmpresa: 'string',
//             items: [
//               {
//                 emailCreador: 'string',
//                 id: 'string',
//                 idCreador: 'string',
//                 idEmpresa: 'string',
//                 nombre: 'string',
//                 nombreCreador: 'string',
//                 orden: 0,
//                 razonSocialEmpresa: 'string',
//               },
//             ],
//             nombre: 'string',
//             nombreCreador: 'string',
//             razonSocialEmpresa: 'string',
//           },
//           realizado: true,
//           respuestaItems: [
//             {
//               checked: true,
//               id: 'string',
//               idEmpresa: 'string',
//               item: {
//                 emailCreador: 'string',
//                 id: 'string',
//                 idCreador: 'string',
//                 idEmpresa: 'string',
//                 nombre: 'string',
//                 nombreCreador: 'string',
//                 orden: 0,
//                 razonSocialEmpresa: 'string',
//               },
//               marcado: '2024-06-20T17:16:37.740Z',
//               nombre: 'string',
//               orden: 0,
//               revisadoPor: 'string',
//             },
//           ],
//           telefonoResponsable: 'string',
//         },
//         comentario: 'string',
//         descripcion: 'string',
//         hecho: true,
//         id: 'string',
//         modificacion: '2024-06-20T17:16:37.740Z',
//       },
//       isCarga: true,
//       nroCarga: 'string',
//       nroPedido: 'string',
//     },
//   ],
// };

export const OsProvider = ({ children }) => {
  const [wayPointsToEdit, setWayPointsToEdit] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalPoints, setOriginalPoints] = useState(null);
  const [wayPoints, setWayPoints ] = useState(null)
  const [carga, setCarga] = useState(null)

  // logica traer informacion de carga
  async function loadOrder(id) {
    try {
      const { data: order } = await getOrderDetail(id);
        setCarga(order);
        setOriginalPoints(order?.wayPoints);
        if(order.wayPoints[0].llegadaEstimada){
          setStartDate(new Date (order.wayPoints[0].llegadaEstimada))
        }
    } catch (e) {
      console.log(e);
    }
  }



  // logica waypoint 
  const getwayPoints = () => {
    const puntos = wayPointsToEdit?.map((waypoint, index) => {
      return {
        direccion: waypoint.address,
        id: waypoint?.id,
        idUbicacion:waypoint?.idUbicacion,
        idCarga: carga.id,
        latitud: waypoint.lat,
        longitud: waypoint.lng,
        orden: index,
        tiempoTransito: index === 0 ? null : routeInfo?.[index - 1]?.durationText,
        llegadaEstimada: index === 0 ? startDate : routeInfo?.[index - 1]?.tiempoEstimado,
        llegadaEstimadaStr:index === 0 ? startDate : routeInfo?.[index - 1]?.tiempoEstimado,
        tiempoServicio: waypoint?.timePuntoControl || null,
        tiempos:
          index === 0
            ? null
            : routeInfo?.[index - 1]?.tiempoAdicional.length > 0 && routeInfo?.[index - 1]?.tiempoAdicional?.map((tiempo) => ({
                descripcion: tiempo.descripcion,
                tiempoServicio: tiempo.tiempoServicio,
                idWaypoint: waypoint?.id || null,
                id:tiempo?.id || null
              })) || null,
        wp:
          waypoint?.type === 'PUNTO DE CONTROL'
            ? null
            : (() => {
              const matchingWp = originalPoints.find((wp) => {
                return wp.id === waypoint.id;
              });
              return matchingWp ? matchingWp.wp : null;
            })(),
      };
    });
    setWayPoints(puntos)
    setEndDate(routeInfo?.[routeInfo.length - 1]?.tiempoEstimado || null)
  }
  
  
  useEffect(()=>{
    getwayPoints()
  },[wayPointsToEdit, routeInfo, startDate])

  const data = {
    wayPointsToEdit,
    setWayPointsToEdit,
    routeInfo,
    setRouteInfo,
    startDate,
    setStartDate,
    setOriginalPoints,
    wayPoints,
    endDate,
    loadOrder,
    carga
  };




  return <OsContext.Provider value={data}>{children}</OsContext.Provider>;
};

export default OsContext;
