import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Slide, Paper, Typography, Grid, Backdrop , CircularProgress} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import ActiveUsersList from './UsersList-active';
import PendingUsersList from './UserList-pending';
import CustomSearchBox from '../../../components/forms/custom-elements/CustomSearchbox';
import ModalInviteUser from './ModalInviteUser';

import {
  getActiveUsersList,
  getPendingUsersList,
  inviteUser,

} from '../../../api/company';
import { CheckPermissions } from '../../../utils/relatedToPermissions';
import useAuth from '../../../hooks/useAuth';

const initialValues = {
  rolSeleccionado: null,
  email: '',
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddUser = () => {
  const [t] = useTranslation();
  const auth = useAuth();
  const p = auth?.allPermissions;
  const [pendingUsersList, setPendingUsersList] = useState();
  const [activeUsersList, setActiveUsersList] = useState();
  const [activeUsersListCopy, setActiveUsersListCopy] = useState();
  const [url, setUrl] = useState('https://app?.followlsn.com/auth/register?usuario=');
  const [openInviteUser, setOpenInviteUser] = useState(false);
  const [reload, setReload] = useState(false)

  const [loadingLists, setLoadingLists] = useState(true);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const loadLists = async () => {
    setLoadingLists(true);
    try {
      const [pendingUserResponse, activeUserResponse] = await Promise.allSettled([
        getPendingUsersList(),
        getActiveUsersList(),
      ]);
      setPendingUsersList(pendingUserResponse.value?.data || []);
      setActiveUsersList(activeUserResponse.value?.data || []);
      setActiveUsersListCopy(activeUserResponse.value?.data || []);
    } catch (e) {
      console.log(e);
      setPendingUsersList([]);
      setActiveUsersList([]);
      setActiveUsersListCopy([]);
    } finally {
      setLoadingLists(false);
    }
  };

  function showAlert(msg, alertType) {
    enqueueSnackbar(msg, {
      variant: alertType,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    });
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup?.object().shape({
      rolSeleccionado: Yup?.object().required('El rol es requerido').nullable(),
      email: Yup?.string().required('El email es requerido').email('Email inválido'),
    }),
    onSubmit: async () => {
      setLoading(true);
      try {
        const respUrl = await inviteUser(formik.values.email.replace("+","%2B"), formik.values.rolSeleccionado.id);
        setUrl(respUrl.data.link.slice(0, respUrl.data.link.indexOf('=') + 1));
        const pendingUser = await getPendingUsersList();
        setPendingUsersList(pendingUser.data);
        showAlert('Invitacion enviada', 'success');
        formik.resetForm();
        setOpenInviteUser(false);
      } catch (error) {
        console.error(new Error(error));
        showAlert(error?.response?.data?.error || 'Error en el servidor', 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  const handleOpenInviteUser = () => {
    setOpenInviteUser(true);
  };

  const handleCloseInviteUser = () => {
    formik.resetForm();
    setOpenInviteUser(false);
  };

  const searchValueValid = (item, searchValue) => {
    return item.toLowerCase().includes(searchValue.toLowerCase());
  };

  const filter = (searchValue) => {
    if (searchValue === '') setActiveUsersList(activeUsersListCopy);
    else
      setActiveUsersList(
        activeUsersList.filter((item) => {
          return (
            searchValueValid(item.nombre, searchValue) ||
            searchValueValid(item.mail, searchValue) ||
            searchValueValid(item.apellido, searchValue)
          );
        }),
      );
  };

  const loadNewInformation = async () => {
    const pendingUser = await getPendingUsersList();
    setPendingUsersList(pendingUser.data);
    const activeUser = await getActiveUsersList();
    setActiveUsersList(activeUser.data);
  };

  React.useEffect(() => {
    loadLists();
    return () => {
      setReload(false)
    }
  }, [reload]);

  return (
    <Box>
      <Box sx={{ mt:1,mr: 2, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <Button
          disabled={!CheckPermissions(auth, [p?.ROOT,p?.CRE_EMP,p?.CRE_USU])}
          onClick={handleOpenInviteUser}
          size="small"
          color="primary"
          sx={{ width: '180px', p: "2px" }}
          variant="contained"
          startIcon={<EmailIcon style={{ fontSize: '24px' }} />}
        >
          <Typography variant = 'h4' textAlign = 'center'>
          {t('Configuration.Invite')}
          </Typography>
        </Button>
      </Box>

      <PendingUsersList
        users={pendingUsersList}
        url={url}
        setUrl={setUrl}
        loadNewInformation={loadNewInformation}
        showAlert={showAlert}
        loadingList={loadingLists}
        setReload={setReload}
      />
   
      
        <Grid sx={{ mx: 2, display:"flex", justifyContent:"end"}} >
        <Paper sx={{display:"inline-block", p:"0.5rem", borderRadius:"5px"}}>
          <CustomSearchBox
            placeHolder={t("Loads.Outset.Search...")}
            width={350}
            doSearch={(text) => {
              filter(text);
            }}
          />
        </Paper>
        </Grid>
     


      <ActiveUsersList
        users={activeUsersList}
        loadNewInformation={loadNewInformation}
        loadingList={loadingLists}
      />

      <ModalInviteUser
        open={openInviteUser}
        Transition={Transition}
        handleClose={handleCloseInviteUser}
        formik={formik}
        setUrl={setUrl}
        setPendingUsersList={setPendingUsersList}
        loading={loading}
      />
      <Backdrop sx={{ color: '#fff', zIndex:(theme)=> theme.zIndex.modal + 1 }} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  );
};

export default AddUser;
