import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueueIcon from '@mui/icons-material/Queue';
import TocIcon from '@mui/icons-material/Toc';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import StarsIcon from '@mui/icons-material/Stars';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AccountTreeIcon from '@mui/icons-material/AccountTree';


import Info from '../../../components/SVG/Info';
import Team from '../../../components/SVG/Team';
import Bronze from '../../../components/SVG/Bronze';
import Silver from '../../../components/SVG/Silver';
import Gold from '../../../components/SVG/Gold';
import Diamond from '../../../components/SVG/Diamond';
import fleet from '../../../assets/images/welcome/flota.png';
import cargas from '../../../assets/images/welcome/cargas.png';
import marketplace from '../../../assets/images/welcome/marketplace.png';
import DriverApp from '../../../components/SVG/DriverApp';
import AdminApp from '../../../components/SVG/AdminApp';

import { getPromotionsList } from '../../../api/planPromotions';



export const appsToShow = [
  {
    id: 'driverApp',
    label: 'Aplicación para conductores',
    androidLink: '',
    iosLink: '',
    img: <DriverApp />,
  },
  {
    id: 'adminApp',
    label: 'Aplicación de gestión',
    androidLink: '',
    iosLink: '',
    img: <AdminApp />,
  },
];

export const mainItemCompanyData = {
  header: 'Completa la información de tu empresa',
  body: 'Completa los datos de tu empresa para que tus clientes y proveedores puedan contactarte. Agrega una imagen, persona de contacto y comparte tu link personalizado.',
  btnLabel: 'Ir a perfil >>>',
  img: <Info />,
};

export const mainItemTeam = {
  header: 'Añade a tu equipo',
  body: 'Puedes agregar todos los usuarios que necesites para tu empresa. Crea el rol que desees asignarle y envia una invitación a su correo electrónico.',
  btnLabel: 'Gestinonar roles >>>',
  img: <Team />,
};

const quantityLoads = {
  id: 'NUEVA_CARGA',
  label: '',
  getIcon: (isAvailable, theme) => (
    <QueueIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const quantityTravels = {
  id: 'NUEVO_VIAJE',
  label: '',
  getIcon: (isAvailable, theme) => (
    <QueueIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const dashboard = {
  id: 'Tablero de comando',
  label: 'Tablero de comando',
  getIcon: (isAvailable, theme) => (
    <DashboardIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const kanban = {
  id: 'Kanban de pedidos',
  label: 'Kanban de pedidos',
  getIcon: (isAvailable, theme) => (
    <TocIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const travelsKanban = {
  id: 'Kanban de viajes',
  label: 'Kanban de viajes',
  getIcon: (isAvailable, theme) => (
    <TocIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const digitalDocumentation = {
  id: 'documents',
  label: 'Documentación digital',
  getIcon: (isAvailable, theme) => (
    <HistoryEduIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const trackingViaApp = {
  id: 'tracking',
  label: 'Seguimiento vía app',
  getIcon: (isAvailable, theme) => (
    <TrackChangesIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const confirmation = {
  id: 'Confirmación carga y descarga',
  label: 'Confirmación carga y descarga',
  getIcon: (isAvailable, theme) => (
    <ThumbUpAltIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const favoritesNetwork = {
  id: 'favorites',
  label: 'Red de favoritos',
  getIcon: (isAvailable, theme) => (
    <StarsIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const ordersCalendar = {
  id: 'Calendario de pedidos',
  label: 'Calendario de pedidos',
  getIcon: (isAvailable, theme) => (
    <CalendarMonthIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const travelsCalendar = {
  id: 'Calendario de viajes',
  label: 'Calendario de viajes',
  getIcon: (isAvailable, theme) => (
    <CalendarMonthIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const orderOptimization = {
  id: 'Optimización de pedidos',
  label: 'Optimización de pedidos',
  getIcon: (isAvailable, theme) => (
    <ShowChartIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const assetAvailability = {
  id: 'Disponibilidad de activos',
  label: 'Disponibilidad de activos',
  getIcon: (isAvailable, theme) => (
    <PlaylistAddCheckIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

const outsourcedFleets = {
  id: 'Flotas tercerizadas',
  label: 'Flotas tercerizadas',
  getIcon: (isAvailable, theme) => (
    <AccountTreeIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

/* hardcodeo para Marketplace
 */

const toMarketplace = {
  id: 'Bonificado',
  label: 'Bonificado',
  getIcon: (isAvailable, theme) => (
    <DashboardIcon
      style={{
        fontSize: '48px',
        color: isAvailable ? theme.palette.success.main : theme.palette.error.main,
      }}
    />
  ),
};

export const planstoMarketplace = [
  {
    id: '456789',
    monto: 0,
    nombre: 'Bonificado',
    paquetes: [
      {
        id: '78913',
        descripcion: 'Bonificado',
        nombre: 'Bonificado',
        plantillasAcceso: [{ tipoAcceso: 'Bonificado', nombre: 'Bonificado' }],
      },
    ],
  },
];

export const ModulesToShow = () => {
  const [modules, setModules] = useState(null);
  const [t]=useTranslation()

  const modulesWhithoutPlans = [
    {
      id: 'CARGA',
      label: t('Icons.Modulo Cargas'),
      info: t('Icons.LeadsDesc'),
      icon: cargas,
      isImg: true,
      accesses: [
     
      ],
    },
    {
      id: 'FLOTA',
      label: t('Icons.Modulo Flotas'),
      info: t('Icons.FleetDesc'),
      icon: fleet,
      isImg: true,
      accesses: [
        quantityTravels,
        dashboard,
        travelsKanban,
        digitalDocumentation,
        trackingViaApp,
        confirmation,
        favoritesNetwork,
        assetAvailability,
        travelsCalendar,
        outsourcedFleets,
      ],
    },
    {
      id: 'MARKETPLACE',
      label: 'Marketplace',
      info: t('Icons.MarketDesc'),
      icon: marketplace,
      isImg: true,
      accesses: [toMarketplace],
    },
  ];
  useEffect(() => {
    const modulos = ['CARGA', 'FLOTA', 'MARKETPLACE'];

    const fetchPlanPromotions = async (modulo) => {
      try {
        const { data } = await getPromotionsList(modulo);
        if (modulo === 'CARGA') {
          modulesWhithoutPlans[0].plans = data !== '' ? data:[];
        } else if (modulo === 'FLOTA') {
          modulesWhithoutPlans[1].plans = data === '' ? []:data.sort((a, b) => {
           const orden = ["Free", "Basic", "Starter", "Business", "Enterprise", "Custom"];
           return orden.indexOf(a.nombre) - orden.indexOf(b.nombre);
          });
          
        } else {
          modulesWhithoutPlans[2].plans = data;
          if (data === '') {
            modulesWhithoutPlans[2].plans = planstoMarketplace;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchData = async (m) => {
      await Promise.all(m.map((modulo) => fetchPlanPromotions(modulo)));
      setModules(modulesWhithoutPlans);
    };

    fetchData(modulos);
  }, [t]);




  return modules;

  /* await Promise.all(['CARGA', 'FLOTA', 'MARKETPLACE'].map((modulo) => getPlanPromotions(modulo))); */
};
