import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Card,
  Divider,
  Box,
  Grid,
  Button,
  Container,
  Typography,
  Chip,
  useTheme,
  useMediaQuery,
  Paper,
  IconButton,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Info from '@mui/icons-material/Info';
import PageContainer from '../../../../components/container/PageContainer';
import Breadcrumb from '../../../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import LabelWithIcon from '../../../../components/LabelWithIcon';
import OrderLineState from './OrderLineState';

const OrderDetail = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const chipStyle = { fontSize: '12px', letterSpacing: '1px', color: theme.palette.text.secondary };
  const detailOrder = location.state && location.state.orderDetail;
  console.log(detailOrder);
  
  const handleGoBack = () => {
    navigate('/flota/orden-mantenimiento');
  };

  return (
    <PageContainer title={`Orden #${detailOrder?.nroOrden}`}>
      {/* <Breadcrumb title={`Orden #${detailOrder.nroOrden}`}/> */}
      <Card sx={{ p: 0 }}>
        <Box sx={{ bgcolor: 'primary.main', px: 4, py: 3 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Box>
              <Typography variant="h3" color="white" mb={1}>
                {t('OM.Detail.DetailOf')}
              </Typography>
              <Typography variant="h2" color="white" mb={1}>
                {`${t('OM.orderN')} #${detailOrder?.nroOrden} [${t(`OM.Status.${detailOrder?.estado}`)}]`}
              </Typography>
            </Box>
            <Info
              style={{
                color: 'white',
                fontSize: '36px',
              }}
            />
          </Box>
        </Box>

        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {detailOrder?.estado === 'Pendiente' ? (
                <LabelWithIcon
                  title={`${t('OM.Detail.RequestDate')}:`}
                  subTitle={detailOrder?.alta?.slice(0, 10)}
                  featherIcon="calendar"
                />
              ) : (
                <LabelWithIcon
                  title={`${t('OM.stimatedStart')}:`}
                  subTitle={detailOrder?.fechaInicioEstimada?.slice(0, 10)}
                  featherIcon="calendar"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {detailOrder?.estado === 'Pendiente' ? (
                <LabelWithIcon
                  title={`${t('OM.Detail.AskedFor')}:`}
                  subTitle={detailOrder?.pedidaPor}
                  featherIcon="user"
                />
              ) : (
                <LabelWithIcon
                  title={`${t('OM.stimatedEnd')}:`}
                  subTitle={detailOrder?.fechaFinEstimada?.slice(0, 10)}
                  featherIcon="calendar"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={`${t('OM.Detail.VehicleType')}:`}
                subTitle={`${detailOrder?.tipoVehiculo}`}
                featherIcon="truck"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={`${t('OM.Detail.Domain')}:`}
                subTitle={
                  detailOrder?.vehiculo
                    ? `${detailOrder?.vehiculo?.dominio}`
                    : detailOrder?.remolque?.dominio
                }
                featherIcon="credit-card"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={`${t('OM.Detail.ExecutionType')}:`}
                subTitle={detailOrder?.tipoEjecucion}
                featherIcon="tool"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={`${t('OM.Detail.OMType')}:`}
                subTitle={detailOrder?.tipoOM}
                featherIcon="cpu"
              />
            </Grid>
            {detailOrder?.estado !== 'Pendiente' ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon
                  title={`${t('OM.Detail.AskedFor')}:`}
                  subTitle={detailOrder?.pedidaPor}
                  featherIcon="user"
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon
                  title={`${t('OM.Detail.AprovedBy')}:`}
                  subTitle={detailOrder?.aprobadoPor ? detailOrder?.aprobadoPor : '---'}
                  featherIcon="user-check"
                />
              </Grid>
            )}
            {detailOrder?.estado !== 'Pendiente' ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon
                  title={`${t('OM.Detail.AprovedBy')}:`}
                  subTitle={detailOrder?.aprobadoPor ? detailOrder?.aprobadoPor : '---'}
                  featherIcon="user-check"
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Divider textAlign="center" sx={{ mt: 2, mb: 3 }}>
            <Chip label={t('OM.Detail.OrderStatus')} sx={chipStyle} />
          </Divider>
          <Grid
            container
            spacing={2}
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} md={12}>
              <Paper sx={{ p: 1 }} variant="elevation">
                <OrderLineState detailOrder={detailOrder} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} align="center">
          <Button
            type="cancel"
            size="small"
            sx={{
              width: '300px',
              mt: 1,
              mb: 2,
              py: 1.2,
              borderRadius: 3,
              mr: 2,
            }}
            variant="contained"
            onClick={handleGoBack}
          >
            <Typography variant="h3">{t('Button.Back')}</Typography>
          </Button>
        </Grid>
      </Card>
    </PageContainer>
  );
};

export default OrderDetail;
