import { getCompanies } from "../../../api/company"
import { getCountries } from "../../../api/country"

function sortList(current, next, sortBy) {
    const first = current[sortBy]?.toUpperCase() || '';
    const second = next[sortBy]?.toUpperCase() || '';
    if (first > second) return 1;
    if (second > first) return -1;
    return 0;
  }

export const getList = async () => {
    const [
        countriesResponse,
        companiesResponse,
    ] = await Promise.allSettled([
        getCountries(),
        getCompanies()
    ]);
    
    const list = {
        countryList: countriesResponse?.value?.data.sort((c, n) => sortList(c, n, 'nombre')),
        companyList: companiesResponse?.value?.data.sort((c, n) => sortList(c, n, 'razonSocial')),
    actividadPrincipal: [
        {
            id: "TRANSPORTADOR",
            label: "Empresa de transporte",
            nombre:"Empresa de transporte",
            nombreIngles:"Transport company",
            nombrePortugues:"Empresa de transporte"
        },
        {
            id: "DADOR",
            label: "Dador de carga",
            nombre:"Dador de carga",
            nombreIngles:"Charge giver",
            nombrePortugues:"Doador de carga"
        },
        {
            id: "AUTONOMO",
            label: "Transportista autónomo",
            nombre:"Transportista autónomo",
            nombreIngles:"Autonomous carrier",
            nombrePortugues:"Transportador autônomo"
          },
          /* {
              id: "FORWARDER",
              label: "Forwarder"
            },
            {
                id: "ADUANA",
                label: "Despachante de Aduana"
            },
            {
                id: "CHOFER",
                label: "Chofer"
            },
            {
                id: "OTRO",
                label: "Otro"
            }, */
        ],
    };

    return list
};