import React from 'react';
import { Box, Typography } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

const LabelWithIcon = ({ icon, featherIcon, title, subTitle, sxSubTitle = {}, sxTitle = {}, align = 'left', iconColor, children, sxChildrenBox = {} }) => {
  return (
    <>
      <Box 
        display = 'flex'
        alignItems = 'center'
        justifyContent = { align === 'left' ? 'flex-start' : align === 'center' ? 'center' : 'flex-end' }
      >
        {
          icon ? 
            <>
          {icon}
            </>
          :
          featherIcon ?
          <FeatherIcon icon = {featherIcon} size = '16px' color = {iconColor || 'default'} />
          :
          <FeatherIcon icon = 'info' size = '16px'/>
        }
        <Typography variant = "h4" fontWeight = "600" ml = {1} sx = {sxTitle}>
          {title}
        </Typography>
      </Box>
      {
        !children ?
        <Typography sx = {sxSubTitle} textAlign = {align}>{subTitle}</Typography>
        :
        <Box display = 'flex' flexDirection = 'row' alignItems = 'center' sx = {sxChildrenBox}>
          {
            subTitle &&
            <Typography sx = {sxSubTitle} textAlign = {align} mr = {1}>{subTitle}</Typography>
          }
          {children}
        </Box>
      }
    </>
  );
};

export default LabelWithIcon;
