import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';

import ShowQuestion from './ShowQuestion';

const Chat = ({ unaPregunta, setModalAction, setAquestion, authenticatedData, sortFunction }) => {
  const [t] = useTranslation();

  const [hilo, setHilo] = useState(false);
  const [avatar1, setAvatar1] = useState();
  const [avatar2, setAvatar2] = useState();
  const newTheme = createTheme({
    palette: {
      primary: {
        main: '#ffbf3c',
      },
    },
  });

  const avatarfunction = () => {
    unaPregunta.respuesta.sort(sortFunction).forEach((e, index) => {
      if (e.idRemitente) {
        if (e.idRemitente === unaPregunta.idRemitente) setAvatar1('');
        if (e.idRemitente === unaPregunta.idDestinatario && index !== 0) setAvatar2('');
      }
    });
  };

  const AnswerOrNewMessage = () => {
    setAquestion(unaPregunta);
    setModalAction(true);
  };

  const handleClickThread = () => {
    setHilo(!hilo);
  };

  useEffect(() => {
    if (!hilo) {
      setAvatar1(unaPregunta.idRemitente);
      setAvatar2(unaPregunta.idDestinatario);
    }
    if (hilo) avatarfunction();
  }, [hilo]);

  return (
    <Box
      key={`box-chat-${unaPregunta.id}`}
      sx={{
        pt: 0,
        pb: unaPregunta.respuesta.length === 0 ? 1 : 0.8,
        mb: 2.5,
        borderRadius: '16px 16px 16px 16px',
      }}
    >
      <ShowQuestion
        from={unaPregunta.nombreRemitente}
        question={unaPregunta.pregunta}
        avatar={avatar1 === unaPregunta.idRemitente}
        toRespond={unaPregunta}
        setModalAction={setModalAction}
        setAquestion={setAquestion}
        authenticatedData={authenticatedData}
      />
      {unaPregunta.respuesta.length > 0 && (
        <ShowQuestion
          from={unaPregunta.nombreDestinatario}
          question={unaPregunta.respuesta.sort(sortFunction)[0].mensaje}
          avatar={avatar2 === unaPregunta.idDestinatario}
          isAnswer
        />
      )}
      {hilo &&
        unaPregunta.respuesta.sort(sortFunction).map((unaRespueta, index, array) => {
          let chat1 = '';
          let chat2 = '';

          array.forEach((e, i) => {
            if (e.idRemitente === unaPregunta.idRemitente) chat1 = e.id;
            if (e.idRemitente === unaPregunta.idDestinatario && i !== 0) chat2 = e.id;
          });

          return (
            <Fragment key={`respuesta-${unaRespueta.id}-${index.toString()}`}>
              {unaPregunta.idRemitente === unaRespueta.idRemitente && (
                <ShowQuestion
                  from={unaPregunta.nombreRemitente}
                  question={unaRespueta.mensaje}
                  avatar={unaRespueta.id === chat1}
                />
              )}
              {unaPregunta.idDestinatario === unaRespueta.idRemitente &&
                unaPregunta.respuesta[0].id !== unaRespueta.id && (
                  <ShowQuestion
                    from={unaPregunta.nombreDestinatario}
                    question={unaRespueta.mensaje}
                    avatar={unaRespueta.id === chat2}
                    isAnswer
                  />
                )}
            </Fragment>
          );
        })}
      {unaPregunta.respuesta.length < 1 && authenticatedData?.id === unaPregunta.idDestinatario && (
        <ThemeProvider theme={newTheme}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              sx={{ position: 'absolute', left: '82%', ml: 1, mb: -1 }}
              size="small"
              onClick={AnswerOrNewMessage}
              endIcon={<SendIcon />}
            >
              {t('Marketplace.Chat.ReplyMessage')}
            </Button>
          </Box>
        </ThemeProvider>
      )}

      {unaPregunta.respuesta.length > 0 && (
        <>
          {unaPregunta.respuesta.length === 1 &&
            (authenticatedData?.id === unaPregunta.idDestinatario ||
              authenticatedData?.id === unaPregunta.idRemitente) && (
              <ThemeProvider theme={newTheme}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                    justifyContent: 'flex-start',
                    pt: 2,
                  }}
                >
                  <Button
                    sx={{ position: 'relative', left: '12%', ml: 1 }}
                    onClick={AnswerOrNewMessage}
                    variant="text"
                    size="small"
                    endIcon={<SendIcon />}
                  >
                    {t('Marketplace.Chat.NewMessage')}
                  </Button>
                </Box>
              </ThemeProvider>
            )}

          {unaPregunta.respuesta.length > 1 &&
            (authenticatedData?.id === unaPregunta.idDestinatario ||
              authenticatedData?.id === unaPregunta.idRemitente) && (
              <ThemeProvider theme={newTheme}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                    justifyContent: 'flex-start',
                    pt: 2,
                  }}
                >
                  {hilo && (
                    <Button
                      sx={{ position: 'relative', left: '12%', ml: 1 }}
                      onClick={AnswerOrNewMessage}
                      variant="text"
                      size="small"
                      endIcon={<SendIcon />}
                    >
                      {t('Marketplace.Chat.NewMessage')}
                    </Button>
                  )}

                  <Button
                    sx={{ position: 'relative', left: hilo ? '32.3%' : '45.8%' }}
                    onClick={handleClickThread}
                    variant="text"
                    size="small"
                    endIcon={!hilo ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  >
                    {!hilo ? t('Marketplace.Chat.ViewThread') : t('Marketplace.Chat.CloseThread')}
                  </Button>
                </Box>
              </ThemeProvider>
            )}
        </>
      )}
      {unaPregunta.respuesta.length > 1 &&
        authenticatedData?.id !== unaPregunta.idDestinatario &&
        authenticatedData?.id !== unaPregunta.idRemitente && (
          <ThemeProvider theme={newTheme}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                pt: 2,
              }}
            >
              <Button
                onClick={handleClickThread}
                variant="text"
                size="small"
                endIcon={!hilo ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              >
                {!hilo ? t('Marketplace.Chat.ViewThread') : t('Marketplace.Chat.CloseThread')}
              </Button>
            </Box>
          </ThemeProvider>
        )}
    </Box>
  );
};

export default Chat;
