import { FormControl, FormControlLabel, NativeSelect, Switch, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencies } from '../../../../../api/currency';
import useFetch from '../../../../../hooks/useFetch';

const ContraOferta = ({ publicacion }) => {
  const [t] = useTranslation(); 
  const [monedas] = useFetch(getCurrencies);
  const opcionesMoneda = () => {
    return monedas.map((unaMoneda) => {
      return <option value={unaMoneda.id}>{unaMoneda.simbolo}</option>;
    });
  };

  return (
    <>
      <TextField id="valor" label={`${t('Fleet.Home.New-So.Amount')}`} defaultValue={publicacion.valor} multiline maxRows={4} fullWidth />{' '}
      <FormControl id="formMoneda" fullWidth sx={{ mt: 2 }}>
        <NativeSelect
          defaultValue={null}
          inputProps={{
            name: 'Moneda',
            id: 'Moneda',
          }}
        >
          {opcionesMoneda()}
        </NativeSelect>
      </FormControl>
      <TextField
        id="comentario"
        label= {`${t('Types.Comentario')}`}
        multiline
        maxRows={4}
        fullWidth
        sx={{ mt: 2 }}
      />
      <FormControlLabel
          control={
            <Switch
          aria-label="Incluye Iva"
          id="switchIva"
          size="small"
          />
        }
        label={`${t('Marketplace.Actions.Includes IVA')}`}
        labelPlacement="start"
        sx={{ mt: 2, ml: 0 }}
        />
    </>
  );
};

export default ContraOferta;
