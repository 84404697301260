import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FRONT_URL } from '../../../../../config';

const Compartir = () => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: orderId } = useParams();
  const URL = `${FRONT_URL}/marketplace/detalle/${orderId}`;

  return (
    <>
      <Typography id="compartir">
        {' '}
        {URL}
        <IconButton
          sx={{ float: 'right' }}
          color="primary"
          variant="contained"
          onClick={() => {
            navigator.clipboard.writeText(URL);
            enqueueSnackbar(t("Configuration.CopiedClipboard"), {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              autoHideDuration: 4000,
            });
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Typography>
    </>
  );
};

export default Compartir