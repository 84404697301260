import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React, { forwardRef, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DeleteAccount = ({ open, handleClose, handleConfirm, disabled }) => {
  const [infoSelectd, setInfoSelectd] = useState(null);
  const { id: driverId } = useParams();
  const [t] = useTranslation();

  const handleCloseOutside = (event, reason) => {
    if (reason && reason === 'backdropClick' && disabled) return;
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseOutside}
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" variant="h4">
        ¿Está seguro que desea eliminar su cuenta?
      </DialogTitle>
      <Divider />
      <DialogActions>
          <Button onClick={handleClose} variant="contained" disabled={disabled}>
            <Box sx={{ fontSize: 25 }}>{t('Button.Cancel')}</Box>
          </Button>
          <Button variant="contained" onClick={handleConfirm} disabled={disabled}>
            {t('Button.Confirm')}
          </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteAccount.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DeleteAccount;
