import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Card, Button, Grid, IconButton, Typography, Slide } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useFormik, FieldArray, FormikProvider } from 'formik';

import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { formProfileValidation } from '../utils/validation';
import { FormCompanyItems, FormContactItems } from '../mocks/forms';
import { getInitialValues } from '../mocks/initialValues';
import useAuth from '../../../hooks/useAuth';

// api
import { getCompany, updateCompany, updateAvatar } from '../../../api/company';
import { getCountries } from '../../../api/country';
import { getCurrencies } from '../../../api/currency';

// components
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import ActivitySelectProfile from './ActivitySelectProfile';
import AddressField from './AddressField';

import { BASE_URL } from '../../../config';
import { GetAutocomplete } from '../../../utils/forms';
import { getDataPost } from '../mocks/postMock';
import GoogleMapsContext from '../../../contexts/MapContext';
import ProfileDocumentsModal from './ProfileDocumentsModal';
import ModalDrawerDocument from '../../fleet/documents/components/ModalDrawerDocument';
import { CheckPermissions } from '../../../utils/relatedToPermissions';
import {
  ViewAttachment,
  updateDocument,
  createDocument,
  deleteDocument,
  getCompanyProfileDocuments,
} from '../../../api/documents';
import { ALERT_TYPE } from '../../../constants/data';
import NewDocumentModal from '../../../components/modals/NewDocumentModal';
import ModalConfirm from '../../../components/modals/ModalConfirm';

const ERROR_FILE_EXTENSION = 'Only jpeg and png images are allowed';
const ERROR_FILE_UPLOAD = 'Error uploading image';
const SUCCESS_FILE_UPLOAD = 'The image has been changed successfully';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ProfileCreate = () => {
  const [t, i18n] = useTranslation();
  const auth = useAuth();
  const p = auth?.allPermissions;
  const { google } = useContext(GoogleMapsContext);
  const [editable, setEditable] = useState(false);
  const { authenticatedData } = useAuth();
  const authID = authenticatedData.idEmpresa;
  const [company, setCompany] = useState();
  const [searchBox, setSearchBox] = useState();

  const searchBoxRefs = useRef([]);

  const companyId = authenticatedData?.idEmpresa || null;
  const arrayItems = ['empresa', 'contacto'];

  const [countries, setCountries] = useState();
  const [currency, setCurrency] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const [loadingUploadFile, setloadingUploadFile] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [updatedAvatarTime, setUpdatedAvatarTime] = useState(1);
  const [delayImg, setDelayImg] = useState(false);
  const [documentos, setDocumentos] = useState([]);

  const [openModalDocs, setOpenModalDocs] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [choosenDocument, setChoosenDocument] = useState(null);

  const [viewModal, setViewModal] = useState(false);

  const [loadingUpload, setLoadingUpload] = useState(false);
   const [loading, setLoading] = useState(false);

  const onLoad = (ref) => {
    setSearchBox(ref);
  };
  const onLoad2 = (ref, index) => {
    searchBoxRefs.current[index] = ref;
  };

  const getDataCompany = async () => {
    if (companyId !== null) {
      const result = await getCompany(companyId);
      setCompany(result.data);
    }
  };

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const tieneDuplicados = (array) => {
    let duplicados = false;

    array.forEach((elemento, indice) => {
      for (let i = indice + 1; i < array.length; i++) {
        if (elemento.latitud === array[i].latitud && elemento.longitud === array[i].longitud) {
          duplicados = true;
        }
      }
    });

    return duplicados;
  };

  const formik = useFormik({
    initialValues: getInitialValues(company),
    enableReinitialize: true,
    validationSchema: formProfileValidation,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const newCompanyValues = getDataPost(values);
        // verificar que no haya direcciones repetidas
        const allUbicaciones = [newCompanyValues.ubicacion, ...newCompanyValues.ubicaciones];
        if (tieneDuplicados(allUbicaciones)) {
          showMessage('No se permiten direcciones repetidas', 'error');
          return;
        }
        await updateCompany(newCompanyValues);
        showMessage(t('Configuration.CompanySucces'), 'success');
        getDataCompany();
        setEditable(false);
      } catch (error) {
        showMessage(error.message || t('Configuration.ServerError'), 'error');
        setStatus({ success: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    },
  });

  const onPlacesChanged = (property) => {
    const places = searchBox.getPlaces();
    const place = places && places[0];
    const lat = place?.geometry?.location?.lat() || 0;
    const lng = place?.geometry?.location?.lng() || 0;

    if (place) {
      formik.setFieldValue(`${property.id}.latitud`, lat);
      formik.setFieldValue(`${property.id}.longitud`, lng);
      formik.setFieldValue(`${property.id}.select`, true);
      formik.setFieldValue(`${property.id}.direccion`, place.formatted_address);

      const { address_components: addressComponents } = place;

      /* eslint-disable no-continue */
      for (let i = 0; i < addressComponents.length; i++) {
        const componente = addressComponents[i];
        if (!componente.types) continue;
        if (componente.types.includes('country')) {
          formik.setFieldValue(`${property.id}.pais`, {
            nombre: componente.long_name,
            // id: componente.short_name
          });
        }
        if (componente.types.includes('administrative_area_level_1'))
          formik.setFieldValue(`${property.id}.provincia`, componente.long_name);
        if (componente.types.includes('administrative_area_level_2'))
          formik.setFieldValue(`${property.id}.localidad`, componente.long_name);
        if (componente.types.includes('postal_code'))
          formik.setFieldValue(`${property.id}.codigoPostal`, componente.long_name);
      }
    }
  };

  const getTextField = (property, value, name, disabled = false, handleKeyDown) => {
    const handleChange = (e) => {
      if (property.id === 'ubicacion' && formik.values.ubicacion.select) {
        formik.setFieldValue(`ubicacion.latitud`, null);
        formik.setFieldValue(`ubicacion.longitud`, null);
        formik.setFieldValue(`ubicacion.select`, false);
        return formik.handleChange(e);
      }
      return formik.handleChange(e);
    };

    if (property.id !== 'monto') {
      return (
        <Grid>
          <CustomFormLabel htmlFor={property.id}>
            {t(`Configuration.${property.label}`)} {property.requerido && '*'}
          </CustomFormLabel>
          <CustomTextField
            id={property.id}
            name={name}
            onBlur={formik.handleBlur}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={value || ''}
            error={Boolean(formik.touched[property.id] && formik.errors[property.id])}
            helperText={
              property.id === 'ubicacion' && formik.touched[property.id]
                ? formik.errors[property.id]?.direccion
                : formik.touched[property.id] && formik.errors[property.id]
            }
            variant="outlined"
            disabled={disabled || !editable}
            placeholder={editable && property.placeholder}
            fullWidth
            autoComplete="off"
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: (theme) => theme.palette.text.primary,
              },
            }}
          />
        </Grid>
      );
    }
    return null;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const searchBoxField = (property, value, name) => {
    if (google === 'Error loading maps') return google;
    if (google === 'Loading Maps') return google;
    return (
      <Grid container xs={12}>
        <Grid item xs={6} sx={{ paddingRight: '2rem' }}>
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={() => {
              onPlacesChanged(property);
            }}
          >
            {getTextField(property, value, name, false, handleKeyDown)}
          </StandaloneSearchBox>
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: '2rem' }}>
          <CustomFormLabel htmlFor={property.id}>{t(`Configuration.Referency`)}</CustomFormLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            disabled={!editable}
            value={formik.values.ubicacion.referencia}
            onChange={(e) => {
              formik.setFieldValue(`${property.id}.referencia`, e.target.value);
            }}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: (theme) => theme.palette.text.primary,
              },
            }}
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const abortController = new AbortController();
    const getApiproduct = async () => {
      const { signal } = abortController;

      try {
        const { data } = await getCountries(null, signal);
        const respCurrencies = await getCurrencies();
        setCountries(data);
        setCurrency(respCurrencies.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getApiproduct();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    getDataCompany();
  }, []);

  useEffect(() => {
    if (company && countries && !formik.values.paisSelect) {
      const idPais = company.idPais || company.pais?.id || company?.ubicacion?.pais?.id;
      formik.setFieldValue(
        'paisSelect',
        countries.find((c) => c.id === idPais),
      );
    }

    if (company?.idMoneda && currency && !formik.values.selectCurrency) {
      const idMoneda = company?.idMoneda;
      formik.setFieldValue(
        'selectCurrency',
        currency.find((c) => c.id === idMoneda),
      );
    }

    if (
      company?.idMoneda &&
      currency &&
      !formik.values.selectCurrencyMonto &&
      !formik.values.selectCurrency
    ) {
      const idMoneda = company.costoPorKilometro?.moneda?.id;
      formik.setFieldValue(
        'selectCurrencyMonto',
        currency.find((c) => c.id === idMoneda),
      );
    }
  }, [currency, countries, company, formik]);

  const validateFile = (file) => {
    return file && ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
  };

  const forceRerenderAvatar = async () => {
    setDelayImg(true);
    await new Promise((resolve) => setTimeout(resolve, 50));
    setDelayImg(false);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('profile_avatar', file);

    setloadingUploadFile(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const result = await updateAvatar(formData);
      if (result.data.path) {
        showMessage(t(SUCCESS_FILE_UPLOAD), 'success');
        getDataCompany();
        setUpdatedAvatarTime((value) => value + 1);
        forceRerenderAvatar();
      } else throw new Error(t('Configuration.ImageError'));
    } catch (e) {
      console.log(e);
      showMessage(t(ERROR_FILE_UPLOAD), 'error');
    } finally {
      setloadingUploadFile(false);
    }
  };

  const handleUploadFile = (e) => {
    const [fileToUpload] = e.target.files;
    if (validateFile(fileToUpload)) {
      uploadFile(fileToUpload);
    } else {
      e.target.value = null;
      showMessage(t(ERROR_FILE_EXTENSION), 'error');
    }
  };

  const handleCopyToClipboard = async (value) => {
    await navigator.clipboard.writeText(value);
    showMessage(t('Configuration.LinkSucces'), 'success');
  };

  async function loadDocuments() {
    try {
      const { data } = await getCompanyProfileDocuments();
      if (Array.isArray(data)) {
        setDocumentos(data);
      } 
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    loadDocuments();
  }, []);

  async function handleDeleteDocument() {
    setLoadingDelete(true);
    try {
      await deleteDocument(choosenDocument.id);
      showMessage(t('Loads.Documents_View.On_Delete_Msg'), ALERT_TYPE.SUCCESS);
    } catch (e) {
      console.log(e);
      showMessage(
        e?.response?.data?.message || e?.response?.data?.error || t('Errors.Server'),
        ALERT_TYPE.ERROR,
      );
    } finally {
      setChoosenDocument(null);
      setLoadingDelete(false);
      setShowConfirmDelete(false);
      loadDocuments();
    }
  }

  function handleOptions(option, document, imgIndex = 0) {
    switch (option) {
      case t('Button.Open'):
        window.open(ViewAttachment(document.archivos[imgIndex]), '_blank');
        break;
      case t('Button.Edit'):
        setViewModal(false);
        setChoosenDocument(document);
        setOpenModalCreate(true);
        break;
      case t('Button.Delete'):
        setViewModal(false);
        setChoosenDocument(document);
        setShowConfirmDelete(true);
        break;
      default:
        console.log('ok');
    }
  }

  async function handleUploadDocument(
    newDocument,
    filesToUpload,
    hasDate,
    shared,
    isEdit,
    isCompany,
  ) {
    setLoading(true)
    const formData = new FormData();
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append('adjuntos', filesToUpload[i]);
    }
    let query = `titulo=${encodeURIComponent(newDocument.titulo)}`;
    query = `${query}&compartir=${shared === null || shared === undefined ? false : shared}`;

    if (newDocument.descripcion.length > 0)
      query = `${query}&descripcion=${encodeURIComponent(newDocument.descripcion)}`;

    if (hasDate) query = `${query}&vencimiento=${format(newDocument.vencimiento, 'yyyy-MM-dd')}`;
    else query = `${query}&vencimiento= `;

    if (isCompany) query = `${query}&idEmpresa=${authID}`;

    setLoadingUpload(true);
    try {
      if (isEdit) {
        formData.append('archivos', newDocument.archivos);
        query = `${query}&id=${newDocument.id === undefined ? null : newDocument.id}`;

        await updateDocument(query, formData);
      } else {
        await createDocument(query, formData);
      }
      showMessage(
        isEdit ? t('Alerts.UPDATE.SUCCESS') : t('Alerts.CREATE.SUCCESS'),
        ALERT_TYPE.SUCCESS,
      );
      loadDocuments();
      setOpenModalCreate(false);
      setChoosenDocument(null);
    } catch (e) {
      console.log(e);
      showMessage(isEdit ? t('Alerts.UPDATE.ERROR') : t('Alerts.CREATE.ERROR'), ALERT_TYPE.ERROR);
    } finally {
      setLoadingUpload(false);
      setChoosenDocument(null);
      setLoading(false)
    }
  }

  return (
    <>
      <Box width="100%">
        {/* breadcrumb */}
        <Card>
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            {t('Loads.Setting.Company-data')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            mt={2}
            mb={2}
          >
            {!delayImg && (
              <Avatar
                key={updatedAvatarTime}
                sx={{
                  bgcolor: (theme) => theme.palette.primary.main,
                  height: '150px',
                  width: '150px',
                  fontSize: '70px',
                }}
                src={
                  company && company.pathFoto
                    ? `${BASE_URL}/${company.pathFoto}?${updatedAvatarTime}`
                    : ''
                }
              >
                {company && company.razonSocial && company.razonSocial.slice(0, 2).toUpperCase()}
              </Avatar>
            )}
            {editable && (
              <IconButton sx={{ mt: 2 }} component="label" disabled={loadingUploadFile}>
                <Typography
                  color={!loadingUploadFile ? 'primary' : 'disabled'}
                  fontSize="20px"
                  letterSpacing="2px"
                >
                  {!loadingUploadFile
                    ? t('Configuration.ChangePhoto')
                    : t('Configuration.UpdatePhoto')}
                </Typography>
                <input type="file" hidden onChange={handleUploadFile} />
              </IconButton>
            )}
          </Box>
          <Box p={2}>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                {arrayItems.map((item) => {
                  const items = item === 'empresa' ? FormCompanyItems() : FormContactItems();
                  return (
                    <div key={item}>
                      {item === 'contacto' && (
                        <Typography
                          variant="h2"
                          sx={{ textAlign: 'center', marginTop: 10, marginBottom: 2 }}
                        >
                          {t('Loads.Setting.Contact-information')}
                        </Typography>
                      )}

                      <Grid container spacing={2} alignItems="center">
                        {items.map((property) => {
                          const value = property.extraProperty
                            ? formik.values[property.id][property.extraProperty]
                            : formik.values[property.id];
                          const name = property.extraProperty
                            ? `${property.id}.${property.extraProperty}`
                            : property.id;

                          return (
                            <Grid item container direction="column" xs={property.xs} key={name}>
                              <Grid item>
                                <Grid item container direction="column" xs={property.xs} key={name}>
                                  {name === 'paisSelect' &&
                                    GetAutocomplete(property, formik, countries, {
                                      getOptionLabel: (option) => {
                                        return (option && option.nombre) || '';
                                      },
                                      placeholder: t('Configuration.SelectCountry'),
                                      disabled: !editable,
                                      sx: {
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          WebkitTextFillColor: (theme) =>
                                            theme.palette.text.primary,
                                        },
                                      },
                                    })}

                                  {name === 'selectCurrency' &&
                                    GetAutocomplete(property, formik, currency, {
                                      getOptionLabel: (option) => {
                                        if (i18n.language === 'es' && option) return option.nombre;
                                        if (i18n.language === 'pt' && option)
                                          return option.nombrePortugues;
                                        if (i18n.language === 'en' && option)
                                          return option.nombreIngles;
                                        return '';
                                      },
                                      placeholder: editable && property.placeholder,
                                      disabled: !editable,
                                      sx: {
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          WebkitTextFillColor: (theme) =>
                                            theme.palette.text.primary,
                                        },
                                      },
                                    })}

                                  {name === 'monto' && (
                                    <Grid container spacing={2}>
                                      <Typography variant="h3" sx={{ marginLeft: 2, marginTop: 2 }}>
                                        {t('Configuration.KmCost')}
                                      </Typography>
                                    </Grid>
                                  )}

                                  {(name === 'monto' || name === 'selectCurrencyMonto') && (
                                    <Grid container>
                                      <Grid item xs={12}>
                                        {name === 'monto' && (
                                          <>
                                            <CustomFormLabel htmlFor={property.id}>
                                              {t(`Configuration.${property.label}`)}
                                            </CustomFormLabel>
                                            <CustomTextField
                                              id={property.id}
                                              name={name}
                                              onBlur={formik.handleBlur}
                                              onChange={formik.handleChange}
                                              value={value}
                                              error={Boolean(
                                                formik.touched[property.id] &&
                                                  formik.errors[property.id],
                                              )}
                                              helperText={
                                                formik.touched[property.id] &&
                                                formik.errors[property.id]
                                              }
                                              onKeyDown={handleKeyDown}
                                              variant="outlined"
                                              disabled={false || !editable}
                                              placeholder={editable && property.placeholder}
                                              fullWidth
                                              autoComplete="off"
                                              sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                  WebkitTextFillColor: (theme) =>
                                                    theme.palette.text.primary,
                                                },
                                              }}
                                            />
                                          </>
                                        )}
                                      </Grid>
                                      <Grid item xs={12}>
                                        {name === 'selectCurrencyMonto' &&
                                          GetAutocomplete(property, formik, currency, {
                                            getOptionLabel: (option) => {
                                              if (i18n.language === 'es' && option)
                                                return option.nombre;
                                              if (i18n.language === 'pt' && option)
                                                return option.nombrePortugues;
                                              if (i18n.language === 'en' && option)
                                                return option.nombreIngles;
                                              return '';
                                            },
                                            placeholder: editable && property.placeholder,
                                            disabled: !editable,
                                            sx: {
                                              '& .MuiInputBase-input.Mui-disabled': {
                                                WebkitTextFillColor: (theme) =>
                                                  theme.palette.text.primary,
                                              },
                                            },
                                          })}
                                      </Grid>
                                    </Grid>
                                  )}

                                  {property.extraProperty === 'direccion' &&
                                    searchBoxField(property, value, name)}

                                  {property.id === 'ubicaciones' && (
                                    <Grid item xs={property.xs} key={property.id}>
                                      <FieldArray
                                        name="ubicaciones"
                                        render={(arrayHelpers) => (
                                          <>
                                            <AddressField
                                              formik={formik}
                                              arrayHelpers={arrayHelpers}
                                              onLoad={onLoad2}
                                              searchBoxRefs={searchBoxRefs}
                                              editable={editable}
                                            />
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  )}

                                  {!property.extraProperty && name === 'actividadPrincipal' && (
                                    <ActivitySelectProfile
                                      value={value}
                                      name={name}
                                      label={t('Loads.Setting.Main-activity')}
                                      disabled={!editable}
                                      property={property}
                                      formik={formik}
                                    />
                                  )}
                                  {name === 'linkMarketplace' && (
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={10} lg={10}>
                                        {getTextField(property, value, name, true)}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        md={2}
                                        lg={2}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          alignItems: 'flex-end',
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            handleCopyToClipboard(formik.values.linkMarketplace)
                                          }
                                          fullWidth
                                        >
                                          {t('Button.Copy')}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {!property.extraProperty &&
                                    name !== 'selectCurrency' &&
                                    name !== 'selectCurrencyMonto' &&
                                    name !== 'ubicaciones' &&
                                    name !== 'paisSelect' &&
                                    name !== 'actividadPrincipal' &&
                                    name !== 'mpPublico' &&
                                    name !== 'linkMarketplace' &&
                                    getTextField(property, value, name, false, handleKeyDown)}
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  );
                })}

                <Box>
                  {editable ? (
                    <Box display="flex" justifyContent="space-between">
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          formik.resetForm();
                          // startInitialLocations();
                          setEditable(false);
                        }}
                        sx={{
                          mt: 4,
                        }}
                      >
                        {t('Button.Cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          mt: 4,
                        }}
                      >
                        {t('Button.Save')}
                      </Button>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="space-between">
                      <Button
                        variant="contained"
                        onClick={() => setEditable(true)}
                        sx={{
                          mt: 4,
                        }}
                      >
                        {t('Button.Edit')}
                      </Button>
                      <Button
                        startIcon={<DescriptionIcon />}
                        variant="outlined"
                        onClick={() => setOpenModalDocs(true)}
                        sx={{ mt: 4 }}
                      >
                        {t('Menu.Documents')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>
            </FormikProvider>
          </Box>
        </Card>
      </Box>

      <ModalDrawerDocument
        viewModal={viewModal}
        setViewModal={setViewModal}
        document={choosenDocument}
        options={
          choosenDocument &&
          choosenDocument.archivos?.length > 0 &&
          CheckPermissions(auth, [p?.ROOT, p?.CRE_EMP, p?.EDI_DOC])
            ? [t('Button.Open'), t('Button.Edit'), t('Button.Delete')]
            : choosenDocument &&
              choosenDocument.archivos?.length > 0 &&
              !CheckPermissions(auth, [p?.ROOT, p?.CRE_EMP, p?.EDI_DOC])
            ? [t('Button.Open')]
            : [t('Button.Edit'), t('Button.Delete')]
        }
        handleOptions={handleOptions}
        setOpenModalDocs={setOpenModalDocs}
      />

      <ModalConfirm
        open={showConfirmDelete}
        handleClose={() => {
          setShowConfirmDelete(false);
        }}
        handleConfirm={handleDeleteDocument}
        msg={`${t('Loads.Documents_View.Document_Title')}: ${choosenDocument?.titulo}`}
        title={t('Loads.Documents_View.Confirm_Delete')}
        disabled={loadingDelete}
      />

      <ProfileDocumentsModal
        documentos={documentos}
        open={openModalDocs}
        Transition={Transition}
        handleClose={() => {
          setOpenModalDocs(false);
          if (openModalCreate === true) {
            setOpenModalDocs(false);
          }
        }}
        setViewModal={setViewModal}
        setChoosenDocument={setChoosenDocument}
        setOpenModalCreate={setOpenModalCreate}
        loadingFiles={loadingUpload}
      />
      <NewDocumentModal
        open={openModalCreate}
        Transition={Transition}
        handleClose={() => setOpenModalCreate(false)}
        handleUploadDocument={handleUploadDocument}
        disabled={loadingUploadFile}
        documentToEdit={choosenDocument}
        loading={loading}
      />
    </>
  );
};

export default ProfileCreate;
