import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt-BR';
import enLocale from 'date-fns/locale/en-US';
import {
  THEME_COLOR,
  NAVBAR_BG,
  SIDEBAR_BG,
  DIRECTION,
  DARK_THEME,
  DISABLE_DATETIME,
  VEHICLE_OPTION,
  ROW_TO_EDIT,
  CALENDAR_TRANSLATION,
  ACTIVE_MODULES,
} from '../constants';

const INIT_STATE = {
  activeDir: 'ltr',
  activeNavbarBg: '#0b70fb', // This can be any color,
  activeSidebarBg: '#ffffff', // This can be any color
  activeMode: 'light', // This can be light or dark
  activeTheme: 'DEFAULT', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
  SidebarWidth: 240,
  disableDateTime: null,
  vehicleOption: '',
  dataRowSelected: null,
  calenderTranslation:
    localStorage.getItem('language') === 'en'
      ? enLocale
      : localStorage.getItem('language') === 'pt'
      ? ptLocale
      : esLocale,
  allNCM: [],
  typePallets: [],
  modulosActivos: [],
};

const CustomizerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'FETCH_DATA_NCM':
      return { ...state, allNCM: action.payload };
    case 'FETCH_DATA_PALLET':
      return { ...state, typePallets: action.payload };
    case ACTIVE_MODULES:
      return { ...state, modulosActivos: action.payload };
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      };
    case DARK_THEME:
      return {
        ...state,
        activeMode: action.payload,
      };
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      };
    case THEME_COLOR:
      return {
        ...state,
        activeTheme: action.payload,
      };
    case DIRECTION:
      return {
        ...state,
        activeDir: action.payload,
      };

    case DISABLE_DATETIME:
      return {
        ...state,
        disableDateTime: action.payload,
      };

    case VEHICLE_OPTION:
      return {
        ...state,
        vehicleOption: action.payload,
      };

    case ROW_TO_EDIT:
      return {
        ...state,
        dataRowSelected: action.payload,
      };

    case CALENDAR_TRANSLATION:
      return {
        ...state,
        calenderTranslation: action.payload,
      };

    default:
      return state;
  }
};

export default CustomizerReducer;
