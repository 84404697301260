import React from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography, Checkbox, FormControlLabel } from '@mui/material';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import CargaCardItem from './CargaCardItem';
import TableCard from '../../../../components/table-card/TableCard';
import useAuth from '../../../../hooks/useAuth';

export const CargaCard = ({ publicacion, handleDetalle, comparator, setComparator }) => {
  const [t, i18n] = useTranslation();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const dropdownButton = (
    <>
      <IconButton aria-label="detalle" onClick={() => handleDetalle(publicacion)}>
        <SearchIcon />
      </IconButton>
    </>
  );

  const prioritarioSubheader = (
    <Box sx={{ display: 'flex', alignContent: 'center' }}>
      <CrisisAlertIcon sx={{ m: 1 }} />
      <Typography sx={{ m: 1 }}> {t("Marketplace.Priority")}</Typography>
    </Box>
  );
  const periodoCarga = publicacion.periodoDeCarga;
  const periodoDescarga = publicacion.periodoDeDescarga;


  const subtitulefechaInicio = (periodo) => {
    if (periodo?.fechaInicio) {
      const fechaObjeto = new Date(periodo.fechaInicio);
      
      const fechaFormateada = fechaObjeto.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC' // Ignora la zona horaria
      });
      return fechaFormateada
    }
    return format(new Date(periodo.inicio), 'dd/MM/yyyy');
  }

  const subtituleHoraInicio = (periodo) => {
    if (periodo?.fechaInicio) {
      const horaMinutos = periodo.fechaInicio.split('T')[1].slice(0, 5);
      return horaMinutos;
    }
    return format(new Date (periodo?.horaInicio),'HH:mm');
  }

  const subtitulefechaFin = (periodo) => {
    if (periodo?.fechaFin) {
      const fechaObjeto = new Date(periodo?.fechaFin);
      const fechaFormateada = fechaObjeto.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC' // Ignora la zona horaria
      });
      return fechaFormateada
    }
    return format(new Date(periodo?.finalizacion), 'dd/MM/yyyy');
  }



  const subtituleHoraFin = (periodo) => {
    if (periodo?.fechaFin) {
      const horaMinutos = periodo?.fechaFin.split('T')[1].slice(0, 5);
      return horaMinutos;
    }
    return format(new Date (periodo?.horaFinalizacion),'HH:mm');
  }

  const fechaDeCargaInicio = `${t('Fleet.Inactive-Periods.Start')}: ${subtitulefechaInicio(periodoCarga)} (${
    subtituleHoraInicio(periodoCarga)
  } hs)`;
  const fechaDeCargaFin = `${t('Fleet.Inactive-Periods.End')}: ${subtitulefechaFin(periodoCarga)} (${
    subtituleHoraFin(periodoCarga)
  } hs)`;
  const fechaDeDescargaInicio = `${t('Fleet.Inactive-Periods.Start')}: ${subtitulefechaInicio(periodoDescarga)} (${
    subtituleHoraInicio(periodoDescarga)
  } hs)`;
  const fechaDeDescargaFin = `${t('Fleet.Inactive-Periods.End')}: ${
    subtitulefechaFin(periodoDescarga)
  } (${subtituleHoraFin(periodoDescarga)} hs)`;

  let tipoVehiculo;
  let tipoVehiculoText;

  if (publicacion.tipoCamion) {
    tipoVehiculo = publicacion.tipoCamion;
    tipoVehiculoText = `${t('Order.TruckType')}`;
  } else if (publicacion.tipoRemolque) {
    tipoVehiculo = publicacion.tipoRemolque;
    tipoVehiculoText = `${t('Order.TrailerType')}`;
  } else {
    tipoVehiculo = publicacion.tipoBitren;
    tipoVehiculoText = `${t('Order.BitrenType')}`;
  }

  const handleCatchLoad = (load, isChecked) => {
    if (isChecked) {
      setComparator((prevComparator) => [...prevComparator, load]);
    } else {
      setComparator((prevComparator) => prevComparator.filter((item) => item !== load));
    }
  };
  

  return (
    <TableCard
      title={publicacion.nroCarga}
      action={dropdownButton}
      prioritario={publicacion.destacado}
      subheader={publicacion.destacado && prioritarioSubheader}
      custompadding="0px"
    >
      <div
        onClick={() =>
          navigate(
            isAuthenticated
              ? `/marketplace/orden/${publicacion?.id}`
              : `/marketplace/detalle/${publicacion?.id}`,
          )
        }
      >
        <Grid container padding={2} sx={{ cursor: 'pointer' }}>
          <Grid item xs={12}>
            <Typography variant="h3">{publicacion.empresaCreadora}</Typography>
          </Grid>
          <CargaCardItem
            param={`${t('Marketplace.Charging point')}`}
            value={publicacion.ubicacionDesde.direccion}
          />
          <CargaCardItem
            param={`${t('TableHeader.LoadDate')}`}
            value={fechaDeCargaInicio}
            secondValue={fechaDeCargaFin}
          />
          <CargaCardItem
            param={`${t('Marketplace.Delivery point')}`}
            value={publicacion.ubicacionHasta.direccion}
          />
          <CargaCardItem
            param={`${t('TableHeader.Delivery-Date')}`}
            value={fechaDeDescargaInicio}
            secondValue={fechaDeDescargaFin}
          />
          <Grid item xs={12} sm={6} lg={4} paddingBottom={2}>
            <Typography variant="h5">{`${t('Loads.Outset.Type-of-load')}`}</Typography>
            {publicacion.pedidos.map((unaCarga, index) => {
              return (
                <Box key={unaCarga.id}>
                  <Typography style={{ display: 'inline-block' }} variant="h6">
                    {`${t('Types.Pedido')}`} {index + 1}:
                  </Typography>{' '}
                  <Typography style={{ display: 'inline-block' }}>
                    {i18n.language === 'es'
                      ? unaCarga.tipoCarga?.nombre
                      : i18n.language === 'en'
                      ? unaCarga.tipoCarga?.nombreIngles
                      : unaCarga.tipoCarga?.nombrePortugues}
                  </Typography>
                </Box>
              );
            })}
          </Grid>

          <CargaCardItem
            param={tipoVehiculoText}
            value={
              i18n.language === 'es'
                ? tipoVehiculo?.nombre
                : i18n.language === 'en'
                ? tipoVehiculo?.nombreIngles
                : tipoVehiculo?.nombrePortugues
            }
          />
          <Box sx={{ display: 'block', ml: '85%' }}>
            <Typography variant="h4">
              {publicacion.recibirOfertas
                ? `${t('Status.Subasta')}`
                : `${publicacion.valor} ${publicacion.moneda}`}
            </Typography>
          </Box>
        </Grid>
      </div>
      <Box sx={{ display: 'block', mr: '80%', ml: 2 }}>
        <FormControlLabel
          label={t('Button.Compare')}
          control={
            <Checkbox
              color="warning"
              onChange={(e) => handleCatchLoad(publicacion, e.target.checked)}
              checked={comparator?.includes(publicacion)}
            />
          }
        />
      </Box>
    </TableCard>
  );
};
