import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';

const flexCard = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
};

const flexColummnStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const OfferCard = ({
  offer,
  handleResignOffer,
  handleAcceptOffer,
  index,
  authenticatedData,
  idEmpresaCreadora,
  loadCompany,
}) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const color =
    offer.estado === 'PENDIENTE'
      ? theme.palette.secondary.light
      : offer.estado === 'RECHAZADO'
      ? theme.palette.danger.main
      : theme.palette.success.main;

  return (
    <Card
      variant="outlined"
      sx={{
        ...flexCard,
        justifyContent: 'flex-start',
        p: 0,
        width: '100%',
        boxShadow: 'none',
        m: 0,
        bgcolor: color,
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: color }} aria-label="oferente">
            {authenticatedData?.idEmpresa === idEmpresaCreadora && (
              <Typography variant="body2">
                {offer?.creador?.nombre?.toUpperCase().slice(0, 2)}
              </Typography>
            )}
          </Avatar>
        }
        title={
          authenticatedData?.idEmpresa === idEmpresaCreadora && (
            <Typography variant="h4">{offer?.empresaNombre}</Typography>
          )
        }
        subheader={
          <>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {offer.alta ? moment(offer.alta).format('YYYY-MM-DD HH:mm') : ''}
            </Typography>
            <Typography
              onClick={() => {
                loadCompany(offer?.creador.idEmpresa);
              }}
              variant="h4"
              sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
            >
              {t('View company information')}
            </Typography>
            {offer.comentarios && offer.comentarios.length > 0 && (
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                fontStyle="italic"
                textAlign="right"
              >
                {offer.comentarios}
              </Typography>
            )}
          </>
        }
        sx={{ alignItems: 'flex-start', bgcolor: theme.palette.background.paper }}
      />

      <CardContent
        sx={{
          ...flexColummnStyle,
          p: 2,
          bgcolor: color,
          minHeight: '80px',
        }}
      >
        <Typography
          component="div"
          textAlign="center"
          color="white"
          fontSize="32px"
          fontWeight="700"
        >
          {`${offer?.valor} `}
          <strong style={{ fontSize: '18px' }}>{offer?.moneda?.simbolo || 'ARS'}</strong>
        </Typography>
        {index % 2 === 0 && (
          <Typography variant="body2" color="white" fontStyle="italic">
            ({`${t('Marketplace.Actions.Includes IVA')}`})
          </Typography>
        )}
      </CardContent>

      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          height: '100%',
          bgcolor: color,
        }}
      >
        {offer && offer.estado === 'PENDIENTE' ? (
          authenticatedData?.idEmpresa === idEmpresaCreadora && (
            <>
              <Button
                variant="contained"
                onClick={() => handleResignOffer(offer)}
                color="danger"
                size="small"
                sx={{ width: '120px' }}
                startIcon={<CloseIcon />}
              >
                <Typography variant="h5">{t('ActionListTable.Refuse')}</Typography>
              </Button>
              <Button
                variant="contained"
                onClick={() => handleAcceptOffer(offer)}
                color="success"
                size="small"
                sx={{ width: '120px' }}
                startIcon={<CheckIcon />}
              >
                <Typography variant="h5">{t('Button.Confirm')}</Typography>
              </Button>
            </>
          )
        ) : offer && offer.estado === 'RECHAZADO' ? (
          <Typography variant="h5" color="white" textAlign="center" sx={{ width: 1 }}>
            - {t('Status.OfertaRechazada')} -
          </Typography>
        ) : (
          <Typography variant="h5" color="white" textAlign="center" sx={{ width: 1 }}>
            - {t('Status.OfertaAceptada')} -
          </Typography>
        )}
      </CardActions>
    </Card>
  );
};

export default OfferCard;
