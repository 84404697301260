import { useTranslation } from 'react-i18next';

export const FormCompanyItems = () => {
  const [t] = useTranslation();
  return [
    {
      id: 'nombre',
      label: 'Name',
      xs: 12,
    },
    {
      id: 'razonSocial',
      label: 'RZCompany',
      xs: 12,
    },
    {
      id: 'actividadPrincipal',
      label: 'ActivityCompany',
      xs: 12,
    },
    {
      id: 'sitioWeb',
      label: 'Web',
      xs: 12,
      placeholder: `Ejemplos:www.ejemplo.com' o 'ejemplo.co.uk`,
    },
    {
      id: 'urlAmigable',
      label: 'Url',
      xs: 12,
    },
    {
      id: 'linkMarketplace',
      label: 'Marketplace',
      xs: 12,
    },
    {
      id: 'mailInstitucional',
      label: 'MailCompany',
      xs: 12,
    },
    {
      id: 'identificadorFiscal',
      label: 'IdCompany',
      xs: 12,
    },
    {
      id: 'paisSelect',
      label: t('Configuration.Country'),
      xs: 12,
    },
    {
      id: 'selectCurrency',
      label: t('Configuration.Select-currency'),
      xs: 12,
    },
    {
      id: 'monto',
      label: 'Amount',
      xs: 12,
    },
    {
      id: 'selectCurrencyMonto',
      label: t('Configuration.Select-currency'),
      xs: 12,
    },
    // {
    //   id: 'ubicacion',
    //   extraProperty: 'idLocalidad',
    //   label: 'State',
    //   xs: 6,
    // },
    {
      id: 'ubicacion',
      extraProperty: 'direccion',
      label: 'Address',
      placeholder: t('Configuration.AddAddress'),
      xs: 12,
      requerido:true,
    },

    {
      id: 'ubicaciones',
      extraProperty2: 'direccion',
      label: 'Address',
      placeholderDireccion: t('Configuration.placeholder Address'),
      xs: 12,
      
    },
  ];
};
export const FormContactItems = () => {
  const [t] = useTranslation();
  return [
    {
      id: 'responsable',
      label: 'Contact',
      xs: 12,
    },
    {
      id: 'telefono',
      label: 'Phone',
      requerido: true,
      xs: 12,
    },
    {
      id: 'mail',
      label: 'Mail',
      requerido: true,
      xs: 12,
    },
  ];
};
