import React from 'react'
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField, Select, MenuItem, Button,Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { sendConsult } from '../../api/consultation';


function InquiryForm({onClose}) {
  
  const [t]= useTranslation()

    const { enqueueSnackbar } = useSnackbar();

    const modules = [
      {
      label:t("Icons.Fleet"),
      title:"Flota",
      modulo:"FLOTA"
      },
      {
        label:t("Icons.Loads"),
          title:"Carga",
          modulo:"CARGA"
      },
      {
        label:t("Icons.Marketplace"),
          title:"Marketplace",
          modulo:"MARKETPLACE"
      },
  ]


    const showAlert = (msg, type) => {
      enqueueSnackbar(msg, {
        variant: type,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    }

    const initialValues = {
        descripcion: '',
        idModulo: "Flota"
      };
    
      const handleSubmit = async(values) => {
            const consultaModel={
              descripcion:values.descripcion,
              modulo: {
                nombre: values.idModulo
              },
            }
        try {
            await sendConsult(consultaModel)
            showAlert(t("send-consult.Query sent successfully"),"success")
            onClose(false)
        } catch (error) {
            console.log(error)
            showAlert(t("send-consult.Error sending the query"), 'error')

        }
        
      };
    
      const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit
      });
  return (
    <>
    <Grid sx={12} >
    <Typography variant='h3'>{t("send-consult.Select the module to which the query belongs")}</Typography>
      <Select
        id="option"
        name="idModulo"
        value={formik.values.idModulo}
        onChange={formik.handleChange}
        sx={{width:"100%",marginY:"1rem"}}
      >

        {modules?.map((module=>(
            <MenuItem value={module.title} key={module.modulo}>{module.label}</MenuItem>
        )))}
      </Select>
      </Grid>

    <Grid xs={12} >
    <Typography variant='h3'>{t("send-consult.Write your query")}</Typography>
      <TextField
        id="descripcion"
        name="descripcion"
        label={t("send-consult.Consultation")}
        multiline
        rows={4}
        value={formik.values.descripcion}
        onChange={formik.handleChange}
        sx={{width:"100%", marginY:"1rem"}}
      />
  </Grid>

      <Button onClick={formik.handleSubmit} variant="contained" color="primary" disabled={!formik.values.descripcion || !formik.values. idModulo} >
      {t("send-consult.Send")}
      </Button>
    </>
  )
}


export default InquiryForm
