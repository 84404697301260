import React, { useState } from 'react';
import { Grid, IconButton, Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import OfferCard from './OfferCard';
import DialogContainer from '../../../../../components/modals/DialogContainer';
import IntroCard from './IntroCard';
import CoverCard from './CoverCard';
import { Documento } from '../../../../fleet/documents/components/Logos';
import ModalDrawerDocument from './ModalDrawerDocument';
import { CheckPermissions } from '../../../../../utils/relatedToPermissions';
import useAuth from '../../../../../hooks/useAuth';
import { ViewAttachment } from '../../../../../api/documents';
import { getCompany } from '../../../../../api/company';

const ShowOffers = ({
  offers,
  handleResignOffer,
  handleAcceptOffer,
  authenticatedData,
  idEmpresaCreadora,
}) => {
  const auth = useAuth();
  const p = auth?.allPermissions;
  const [t] = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [choosenDocument, setChoosenDocument] = useState(null);
  const [company, setCompany] = useState(null);

  function sortOffers(oneOffer, otherOffer) {
    return new Date(otherOffer.alta) - new Date(oneOffer.alta);
  }

  const loadCompany = async (id) => {
    try {
      const { data } = await getCompany(id);
      setCompany(data);
      setOpenModal(true);
    } catch (error) {
      console.log('error');
    }
  };

  function handleOptions(option, document, imgIndex = 0) {
    switch (option) {
      case t('Button.Open'):
        window.open(ViewAttachment(document.archivos[imgIndex]), '_blank');
        break;
      default:
        console.log('ok');
    }
  }

  return (
    <>
      <Grid container spacing={4} alignItems="stretch">
        {offers?.sort(sortOffers).map((offer, index) => {
          const key = `oferta-${offer.id}-${index.toString()}`;
          if (
            authenticatedData?.idEmpresa === offer.creador.idEmpresa ||
            authenticatedData?.idEmpresa === idEmpresaCreadora
          ) {
            return (
              <Grid item xs={12} md={6} lg={4} display="flex" key={key}>
                <OfferCard
                  offer={offer}
                  handleResignOffer={handleResignOffer}
                  handleAcceptOffer={handleAcceptOffer}
                  index={index}
                  authenticatedData={authenticatedData}
                  idEmpresaCreadora={idEmpresaCreadora}
                  loadCompany={loadCompany}
                />
              </Grid>
            );
          }
          return <></>;
        })}
      </Grid>
      <DialogContainer
        open={openModal}
        title="Informacion de empresa"
        handleClose={() => setOpenModal(false)}
        maxWidth="lg"
      >
        <CoverCard company={company} />
        <IntroCard company={company} />
        <Grid>
          {company?.documentosCompartidos && (
            <>
              <Card>
                <CardContent>
                  <Typography variant="h3">{t('Menu.Documents')}</Typography>
                  {company?.documentosCompartidos?.map((doc) => {
                    return (
                      <Grid sx={{ mt: 2, width: '100px' }} key={doc.id}>
                        <IconButton
                          onClick={() => {
                            setChoosenDocument(doc);
                            setOpenDrawer(true);
                          }}
                        >
                          <Documento
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          />
                        </IconButton>
                        <Typography
                          align="center"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            wordWrap: 'break-word',
                          }}
                        >
                          {doc?.titulo}
                        </Typography>
                      </Grid>
                    );
                  })}
                </CardContent>
              </Card>
            </>
          )}
        </Grid>
      </DialogContainer>

      <ModalDrawerDocument
        viewModal={openDrawer}
        setViewModal={setOpenDrawer}
        document={choosenDocument}
        options={
          choosenDocument &&
          choosenDocument.archivos?.length > 0 &&
          CheckPermissions(auth, [p?.ROOT, p?.CRE_EMP, p?.EDI_DOC])
            ? [t('Button.Open')]
            : [t('Button.Open'), t('Button.Edit'), t('Button.Delete')]
        }
        handleOptions={handleOptions}
      />
    </>
  );
};

export default ShowOffers;
