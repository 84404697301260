import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { getRolDetails } from '../../../api/rol';

function ModalViewRole({ handleClose, viewModal, setViewModal, role }) {
  const [t] = useTranslation();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const subMsg = t('Configuration.RoleDesc');

  async function handleGetRolDetails(id) {
    setLoading(true);
    try {
      const result = await getRolDetails(id);
      setDetails(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  function handleRetry() {
    setError(false);
    handleGetRolDetails(role.id);
  }

  useEffect(() => {
    if (!viewModal) {
      setError(false);
      setLoading(false);
    }
  }, [viewModal, viewModal]);

  useEffect(() => {
    if (role && role.id && viewModal) {
      handleGetRolDetails(role.id);
    }
  }, [role]);

  const boxStyle = {
    position: 'absolute',
    width: 800,
    minHeight: 400,
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.default,
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pt: 3,
    px: 4,
    pb: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '500ms',
    overflow: 'hidden'
  };

  const boxLoadingStyle = {
    ...boxStyle,
    maxHeight: 200,
    minHeight: 200,
    width: 200,
    height: 200,
    justifyContent: 'center'
  }

  const buttonStyle = {
    color: theme.palette.text.primary,
    '&:hover' : { 
      color: theme.palette.primary.main 
    }
  };

  return (
    <Modal open={viewModal} onClose={() => { setViewModal(!viewModal) }}>
      <Box sx = { loading ? boxLoadingStyle : boxStyle }>
        {
          !loading && details &&
          <>
          <FeatherIcon icon ="info" color = {theme.palette.primary.main} size = '72' />
          <Box sx = {{ width: 1, display: 'grid', alignItems: 'center', justifyContent: 'center', gridGap: '10px'}}>
            <Typography variant = 'h2' sx = {{ textAlign: 'center', letterSpacing: '2px' }}>
              {details.nombre}
            </Typography>
            {
              subMsg &&
              <Typography sx = {{ fontSize: '15px', textAlign: 'center' }}>
                {subMsg}
            </Typography>
            }
          </Box>
          <Box width={1}>
            <List dense sx={{ width: '100%', textAlign: 'left', maxHeight: '50vh', overflowY: 'auto' }}>
              {details.permiso && details.permiso.map((permiso) => {
                return (
                  <ListItem key={permiso.id}>
                    <ListItemIcon>
                      <CheckIcon color='success'/>
                    </ListItemIcon>
                    <ListItemText primary={`${permiso.nombre}`}  primaryTypographyProps={{fontSize: '18px'}} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box sx = {{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={handleClose} sx = {buttonStyle}>{t('Button.Close')}</Button>
          </Box>
          </>
        }
        {
          loading &&
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
            <CircularProgress sx={{ color: theme.palette.primary.main }} />
          </Box>
        }
        {
          error &&
          <>
          <FeatherIcon icon ="x" color = 'crimson' size = '72' />
          <Box sx = {{ width: 1, display: 'grid', alignItems: 'center', justifyContent: 'center', gridGap: '10px'}}>
            <Typography sx = {{ fontSize: '22px', textAlign: 'center' }}>
              Error al cargar detalles del rol
            </Typography>
          </Box>
          <Box sx = {{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={handleClose} sx = {buttonStyle}>{t('Button.Close')}</Button>
            <Button onClick={handleRetry} sx = {buttonStyle}>{t('Button.Retry')}</Button>
          </Box>
          </>
        }
      </Box>
  </Modal>
  );
}

export default ModalViewRole;