import axios from 'axios';
import { COMPANY_PATH, USERS_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getCompany = async (id) => {
  return axios.get(`${COMPANY_PATH}/${id}`);
};

export const getCompanies = async () => {
  return axios.get(`${COMPANY_PATH}/listado`);
};

export const getCompaniesWithQueryAndPagination = async (searchParam,
  size,
  page,
  sortType,
  direction,)=>{
  return axios.get(`${COMPANY_PATH}/listado-page?searchParam=${encodeURIComponent(searchParam)}&size=${size}&page=${page}&sort=${sortType},${direction.toUpperCase()}`)
}

export const getCompaniesWithSort = async (size, page, sortType1, direction) => {
  return axios.get(
    `${COMPANY_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const createCompany = async (query, formData) => {
  return axios.post(`${COMPANY_PATH}/guardar?${query}`, formData);
}

export const acceptUser = async (idUser, idRol) => {
  return axios.post(`${USERS_PATH}/aceptar?id=${idUser}&idRol=${idRol}`);
};

export const updateCompany = async (company) => {
  return axios.put(`${COMPANY_PATH}/editar`, company);
};

export const deleteUser = async (id) => {
  return axios.delete(`${USERS_PATH}/eliminar?id=${id}`);
};

export const getActiveUsersList = async () => {
  return axios.get(`${COMPANY_PATH}/listado/usuario`);
};

export const getActiveUsersRolList = async (id) => {
  return axios.get(`${COMPANY_PATH}/listado/usuario?idRol=${id}`);
};

export const getPendingUsersList = async () => {
  return axios.get(`${COMPANY_PATH}/listado/solicitudes`);
};

export const getFavoriteCompaniesList = async () => {
  return axios.get(`${COMPANY_PATH}/listado/favoritas`);
};

export const inviteUser = async (mail, idRol) => {
  return axios.post(`${COMPANY_PATH}/agregar-usuario?mail=${mail}&idRol=${idRol}`);
};

export const updateAvatar = async (formData) => {
  return axios.post(`${COMPANY_PATH}/subir-foto`, formData);
};

export const getCompanyProfile = async (id) => {
  return axios.get(`${COMPANY_PATH}/perfil/${id}`);
};

export const viewCompany = async (auth) => {
  try {
    const response = await axios.get(`${COMPANY_PATH}/ver`, {
      headers: {
        Authorization: auth 
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos de la empresa:", error);
    throw error;
  }
};

export const activateModule = async (modulo,activo) => {
  return axios.post(`${COMPANY_PATH}/activar-modulo?modulo=${modulo}&activo=${activo}`);
};

export const register = (data) => {
  return axios.post(`${COMPANY_PATH}/registrar`,data);
};

export const searchCompany = (cuit) => {
  return axios.get(`${COMPANY_PATH}/buscar-por-cuit?cuit=${cuit}`);
};

