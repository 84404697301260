import React from 'react';
import {
  Typography
} from '@mui/material';

function EnchancedTextButton({ onClick, text, disabled, size = 'auto', style }) {
  return (
    <Typography 
      onClick={!disabled ? onClick : undefined}
      sx = {{ cursor: !disabled ? 'pointer' : 'default', fontSize: size, textAlign: 'center',
        '&:hover': { color: (theme) => !disabled ? theme.palette.primary.main : theme.palette.text.disabled },
        ...style }}
      disabled  
    >
      {text}
    </Typography>
  );
}

export default EnchancedTextButton;