import { React } from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, Select } from '@mui/material';

const HeaderBarWithSort = ({
  cantidadCargas,
  ordenar,
  setCargas,
  modificacionDeOrden,
  setModificacionDeOrden,
  permitirOrdenar = true,
}) => {
  const [t] = useTranslation();
  const handleChange = (event) => {
    const nuevoOrden = ordenar(event.target.value);
    setCargas(nuevoOrden);
    setModificacionDeOrden(!modificacionDeOrden);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="100%">
        <Toolbar disableGutters>
          <Typography color="white" m={0}>
            {cantidadCargas ?? 0} {t('Menu.Orders')}
          </Typography>
          {permitirOrdenar && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="white" m={0}>
                  {t('Marketplace.Actions.OrderBy')}
                </Typography>
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  <Select
                    labelId="selector-de-ordenamiento"
                    id="selector-de-ordenamiento"
                    defaultValue=""
                    onChange={handleChange}
                    label="Age"
                    sx={{ color: '#FFF', pl: 1 }}
                  >
                    <MenuItem value={1}>{t('Marketplace.Actions.Load')}</MenuItem>
                    <MenuItem value={2}>{t('Marketplace.Actions.Delivery')}</MenuItem>
                    <MenuItem value={3}>{t('Marketplace.Actions.Added')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default HeaderBarWithSort;
