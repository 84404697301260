import React from 'react';
import { Grid, Box, Card, CardContent, Typography, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import profilecover from '../../../../../assets/images/backgrounds/banner-01.png';
import { BASE_URL } from '../../../../../config';
import { stringToColor } from './util/stringToColor';

const CoverCard = ({ company }) => {
  const [t] = useTranslation();

  return (
    <>
      <Card
        sx={{
          padding: '0',
        }}
      >
        <img
          srcSet={`${profilecover} 1x, ${profilecover} 2x`}
          alt={profilecover}
          width="100%"
          height="150px"
        />
        <CardContent
          sx={{
            pt: '24px',
            pb: '28px',
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              lg={4}
              sm={12}
              xs={12}
              sx={{
                order: {
                  xs: '2',
                  sm: '2',
                  lg: '1',
                },
              }}
            >
              {company && company.isFollow && (
                <Grid
                  container
                  spacing={0}
                  sx={{
                    mt: {
                      xs: 1,
                    },
                    mb: {
                      xs: 1,
                    },
                  }}
                >
                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xs={4}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.grey.A200,
                      }}
                    >
                      <FeatherIcon icon="clipboard" width="20" />
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight="600"
                      sx={{
                        lineHeight: '1.2',
                      }}
                    >
                      938
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="h5"
                      fontWeight="400"
                      sx={{
                        lineHeight: '1.2',
                      }}
                    >
                      Pedidos
                      <br />
                      Operados
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xs={4}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.grey.A200,
                      }}
                    >
                      <FeatherIcon icon="truck" width="20" />
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight="600"
                      sx={{
                        lineHeight: '1.2',
                      }}
                    >
                      3,586
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="h5"
                      fontWeight="400"
                      sx={{
                        lineHeight: '1.2',
                      }}
                    >
                      Viajes
                      <br />
                      Finalizados
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xs={4}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.grey.A200,
                      }}
                    >
                      <FeatherIcon icon="package" width="20" />
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight="600"
                      sx={{
                        lineHeight: '1.2',
                      }}
                    >
                      2,659
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="h5"
                      fontWeight="400"
                      sx={{
                        lineHeight: '1.2',
                      }}
                    >
                      Cargas
                      <br />
                      Transportadas
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid
              item
              lg={4}
              sm={12}
              xs={12}
              sx={{
                order: {
                  xs: '1',
                  sm: '1',
                  lg: '2',
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  mt: '-90px',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      backgroundImage: 'linear-gradient(#FFF,#FF8300)',
                      padding: '4px',
                      borderRadius: '50%',
                      border: (theme) =>
                        `${theme.palette.mode === 'dark' ? '3px solid #FF8300' : '3px solid #fff'}`,
                      width: '110px',
                      height: '110px',
                      overflow: 'hidden',
                      margin: '0 auto',
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: `${stringToColor(`${company?.razonSocial}`)}`,
                        borderRadius: '50%',
                        width: '96px',
                        height: '96px',
                        border: (theme) =>
                          `${
                            theme.palette.mode === 'dark' ? '4px solid #FF8300' : '4px solid #fff'
                          }`,
                      }}
                      src={
                        company && company.pathFoto
                          ? `${BASE_URL}${company.pathFoto}`
                          : `${company?.razonSocial.slice(0, 2).toUpperCase()}`
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'block',
                    }}
                  >
                    <Typography
                      fontWeight="500"
                      sx={{
                        fontSize: '20px',
                        textAlign: 'center',
                      }}
                    >
                      {company.razonSocial}
                    </Typography>
                    {company.nombre}
                    <Typography
                      color="textSecondary"
                      variant="h6"
                      fontWeight="400"
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {t(`Login.${company.actividadPrincipalNombre}`)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CoverCard;
