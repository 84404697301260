import React from 'react';
import {
  Card,
  Alert,
  Box,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InfoWindow, Marker } from '@react-google-maps/api';
import TruckIcon from '../../../../assets/images/icons/cargo/truck.png';
import UbiIcon from '../../../../assets/images/icons/cargo/ubicación.png';
import Company from '../../../../assets/images/icons/cargo/empresa.png';

import Map from '../../../../components/Map';

const CardMap = ({
  travels,
  setShowInfoWindow,
  showInfoWindow,
  emptyResult,
  onMapLoad,
  searchText,
  userLocation,
  loggedLocation,
  variableZoom,
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const showInfo = (key) => {
    if (key === showInfoWindow) {
      return;
    }
    setShowInfoWindow(key);
  };

  const infoRender = (numero, destino, transporte, descripcion, location, idPedido) => {
    return (
      <InfoWindow onCloseClick={() => setShowInfoWindow(null)} position={location}>
        <Card style={{ margin: 3 }}>
          <CardHeader title={`${t("Loads.Planning_View.Order-number")} #${numero}`} />
          <Divider />
          <CardContent variant="outlined">
            <p><b>{t("Loads.Planning_View.Destination")}</b> {destino}</p>
            <p><b>{t("Loads.Planning_View.Transport")}</b> {transporte} </p>
            <p><b>{t("Loads.Planning_View.Description")}</b> {descripcion}</p>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate(`/carga/ordenPedido/${idPedido}`)}
            >
              {t("Button.See-Detail")}
            </Button>
          </CardActions>
        </Card>
      </InfoWindow>
    );
  };

  return (
    <Card style={{ marginBottom: 100, margin: 0 }}>
      {emptyResult && searchText.length > 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert severity="info">No se encontraron resultados</Alert>
        </Box>
      ) : null}

      <Map 
        style = {{ height: 600 }}
        onMapLoad = {onMapLoad}
        zoom = {5}
      >
        <div>
          {
           
            travels.length > 0 &&
            travels?.map((travel) => {
              const {
                ubicacionInicial,
                ubicacionFinal,
                cargas,
                points,
                id,
                empresaTransportadora,
              } = travel;
              const destino = ubicacionFinal ? ubicacionFinal.direccion : 'Fuerte Olimpo, Paraguay';
              const transporte = empresaTransportadora;
              const numero = cargas && cargas?.[0]?.nroCarga;
              const idPedido = cargas && cargas[0]?.id;
              
              const descripcion = cargas && cargas[0]?.descripcion;
              let validPoints;
              
              if (points) {
                validPoints = points?.lat !== null && points?.lon !== null;

              } else {
                validPoints =
                  ubicacionInicial?.latitud !== null && ubicacionInicial?.longitud !== null;
              }
              const currentLocation = points
                ? { lat: points?.lat, lng: points?.lon }
                : { lat:ubicacionInicial?.latitud, lng: ubicacionInicial?.longitud };
              /*  const currentLocation ={ lat: -21.0415300, lng:  -57.8737700 } */
              /* () => navigate(`/carga/ordenPedido/${idPedido}`) */
              return (
                <div key={id}>
                  {validPoints && (
                    <Marker
                      onClick={() => showInfo(id)}
                      icon={{ url: TruckIcon, scaledSize: { width: 40, height: 40 } }}
                     /*  onMouseOver={() => showInfo(id)} */
                      position={currentLocation}
                    >
                      {showInfoWindow === id
                        ? infoRender(
                            numero,
                            destino,
                            transporte,
                            descripcion,
                            currentLocation,
                            idPedido,
                          )
                        : null}
                    </Marker>
                  )}
                </div>
              );
            })}
          {
            travels.length < 1 && loggedLocation && (
            <Marker
              icon={{ url: Company, scaledSize: { width: 70, height: 70 } }}
              position={{ lat: loggedLocation.latitud, lng: loggedLocation.longitud }}
            />
          )}
          {/* {travels.length < 1 &&  userLocation.loaded && userLocation.coordinates && (
            <Marker
              icon={{ url: UbiIcon, scaledSize: { width: 70, height: 70 } }}
              position={{ lat: userLocation.coordinates.lat, lng: userLocation.coordinates.lng }}
            />
          )}
          {travels.length < 1 && !loggedLocation && !userLocation.loaded && (
            <Marker
              icon={{ url: UbiIcon, scaledSize: { width: 70, height: 70 } }}
              position={{ lat: -21.04153, lng: -57.87377 }}
            />
          )} */}
        </div>
      </Map>
    </Card>
  );
};

export default CardMap;
