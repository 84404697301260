import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';

const TableCard = ({
  customWidth,
  customMargin,
  custompadding,
  customheaderpadding,
  title,
  subtitle,
  action,
  prioritario = false,
  children,
  subheader,
}) => {
  const {isAuthenticated} = useAuth()
  const {sizeFontCardHeader, heightCardHeader}= useContext(ScreenSizeContext);

  return (
    <Card
      sx={{
        width: customWidth,
        m: customMargin,
        p: custompadding,
        '& .MuiCardContent-root:last-child': {
          pb: custompadding,
        },
      }}
    >
      <CardHeader
        sx={{
          p: customheaderpadding,
         backgroundColor: prioritario && '#ffe082',
          display: 'flex',
          borderBottom: (theme) => `2px solid ${theme.palette.primary.dark}`,
          height: heightCardHeader,
        }}
        subheader={subheader}
        title={title}
        titleTypographyProps={{
          variant: 'h2',
          align: 'left',
          fontSize: sizeFontCardHeader
        }}
        subtitle={subtitle}
        action={isAuthenticated ? action || '' : ''}
      />
      {/* content area */}
      <CardContent
        sx={{
          p: custompadding
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

TableCard.propTypes = {
  custompadding: PropTypes.string,
  customheaderpadding: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.any,
  children: PropTypes.node,
};

export default TableCard;
