import React from 'react';
import PropTypes from 'prop-types';
import {
  alpha,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import InventoryIcon from '@mui/icons-material/Inventory';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import LabelWithIcon from '../LabelWithIcon';

import { getTranslation } from '../../utils/translation';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalLoadDetails = ({ open, handleClose, load }) => {


  const [t, i18] = useTranslation();
  const theme = useTheme();

  const chipStyle = { fontSize: '12px', letterSpacing: '1px', color: theme.palette.text.secondary };

  function formatDate(texto) {
    return moment(texto).format('DD/MM/YYYY');
  }

  const subtitulefechaInicio = () => {
    if (load?.periodoDeEntrega?.fechaInicio) {
      const fechaObjeto = new Date(load.periodoDeEntrega.fechaInicio);
      return fechaObjeto.toISOString().split('T')[0];
    }
    return format(new Date(load.periodoDeEntrega.inicio), 'yyyy-MM-dd');
  }

  const subtituleHoraInicio = () => {
    if (load?.periodoDeEntrega?.fechaFin) {
      const horaMinutos = load.periodoDeEntrega.fechaInicio.split('T')[1].slice(0, 5);
      return horaMinutos;
    }
    return format(new Date (load.periodoDeEntrega.horaInicio),'HH:mm');
  }

  const subtitulefechaFin = () => {
    if (load?.periodoDeEntrega?.fechaFin) {
      const fechaObjeto = new Date(load.periodoDeEntrega.fechaFin);
      return fechaObjeto.toISOString().split('T')[0];
    }
    return format(new Date(load.periodoDeEntrega.finalizacion), 'yyyy-MM-dd');
  }



  const subtituleHoraFin = () => {
    if (load?.periodoDeEntrega?.fechaFin) {
      const horaMinutos = load.periodoDeEntrega.fechaInicio.split('T')[1].slice(0, 5);
      return horaMinutos;
    }
    return format(new Date (load.periodoDeEntrega.horaFinalizacion),'HH:mm');
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      scroll = 'paper'
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      sx = {{ zIndex: '3000' }}
      maxWidth = 'lg'
    >
      <Box
        id = "modal-detalles-carga"
        width = {1} p = {2} pt = {1} pb = {1}
        sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box display = 'flex' alignItems = 'center'>
          <InventoryIcon sx = {{ mr: 1}} style = {{ color: theme.palette.primary.main }}/>
          <Typography variant = 'h4'>
          {`${t("Types.CARGA")}#${load?.nroCarga} - ${load?.nroPedido} -  ${t('Load.LoadDetails')}`}
          </Typography>
        </Box>
        <IconButton onClick = {handleClose}>
          <FeatherIcon icon = "x" />
        </IconButton>
      </Box>

      <DialogContent sx = {{ backgroundColor: alpha(theme.palette.primary.dark, 0.8) }}>
        <Paper elevation = {10} sx = {{ px: 4, py: 2 }}>
          <Grid container spacing = {2} mb = {2} px = {2}>
          {
            load?.origen &&
            <Grid item xs = {12} sm = {6}>
              <Grid container spacing = {2}>
                <Grid item xs = {12} mb = {1}>
                  <Divider textAlign = 'left'>
                    <Chip label = {t('Order.Origin')} sx = {chipStyle}/>
                  </Divider>
                </Grid>
                {
                  load.origen.direccion &&
                  <Grid item xs = {12}>
                    <LabelWithIcon
                      title = {t('Fleet.My-Travels.Details.Direction')}
                      subTitle = {load.origen.direccion}
                      featherIcon = 'map-pin'
                    />
                  </Grid>
                }
                {
                  load.origen.observacion &&
                  <Grid item xs = {12}>
                    <LabelWithIcon
                      title = {t('Fleet.My-Travels.Details.Observations')}
                      subTitle = {load.origen.observacion}
                      featherIcon = 'clipboard'
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
          }
          {
            load?.destino &&
            <Grid item xs = {12} sm = {6}>
              <Grid container spacing = {2}>
              <Grid item xs = {12} mb = {1}>
                <Divider textAlign = 'right'>
                  <Chip label = {t('Order.Destination')} sx = {chipStyle}/>
                </Divider>
              </Grid>
              {
                load.destino.direccion &&
                <Grid item xs = {12}>
                  <LabelWithIcon
                    title = {t('Fleet.My-Travels.Details.Direction')}
                    subTitle = {load.destino.direccion}
                    featherIcon = 'map-pin'
                    align = 'right'
                  />
                </Grid>
              }
              {
                load.destino.observacion &&
                <Grid item xs = {12}>
                  <LabelWithIcon
                    title = {t('Fleet.My-Travels.Details.Observations')}
                    subTitle = {load.destino.observacion}
                    featherIcon = 'clipboard'
                    align = 'right'
                  />
                </Grid>
              }
              </Grid>
            </Grid>
          }
          {
            load?.periodoDeEntrega &&
            load?.periodoDeEntrega.inicio &&
            load?.periodoDeEntrega.finalizacion &&
            <>
            <Grid item xs = {12} mb = {1}>
              <Divider>
                <Chip label = {t('Loads.Planning_View.Load-Detail.Estimated-delivery-period')} sx = {chipStyle}/>
              </Divider>
            </Grid>
            {
              load?.periodoDeEntrega &&
              <Grid item xs = {12} sm = {6}>
                <Grid container spacing = {2}>
                  <Grid item xs = {12} sm = {6}>
                    <LabelWithIcon
                      title = {t('Loads.Planning_View.Load-Detail.Start-date')}
                      // subTitle = {formatDate(load.periodoDeEntrega.inicio)}
                      subTitle={subtitulefechaInicio()}
                      featherIcon = 'calendar'
                    />
                  </Grid>
                  <Grid item xs = {12} sm = {6}>
                    <LabelWithIcon
                      title = {t('Loads.Planning_View.Load-Detail.Hour')}
                     // subTitle = {load.periodoDeEntrega.horaInicio}
                     subTitle={`${subtituleHoraInicio()}hs`}
                      featherIcon = 'clock'
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            {
              load?.periodoDeEntrega &&
              <Grid item xs = {12} sm = {6}>
                <Grid container spacing = {2}>
                  <Grid item xs = {12} sm = {6}>
                    <LabelWithIcon
                      title = {t('Loads.Planning_View.Load-Detail.Finish-date')}
                  // subTitle = {formatDate(load.periodoDeEntrega.finalizacion)}
                      subTitle = {subtitulefechaFin()}
                      featherIcon = 'calendar'
                      align = 'right'
                    />
                  </Grid>
                  <Grid item xs = {12} sm = {6}>
                    <LabelWithIcon
                      title = {t('Loads.Planning_View.Load-Detail.Hour')}
                    //  subTitle = {load.periodoDeEntrega.horaFinalizacion}
                      subTitle={`${subtituleHoraFin()}hs`}
                      featherIcon = 'clock'
                      align = 'right'
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            </>
          }
          </Grid>
          <Box margin={1}>

            <Divider textAlign = 'center' sx = {{ mb: 2 }}>
              <Chip label = {t('Load.Items')} sx = {chipStyle}/>
            </Divider>

            <Table size="small" aria-label="productos-tabla">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">{t('Load.Product')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{t('Load.Weight')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{t('Load.Volume')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{t('Load.PackageType')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{t('Load.PalletType')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{t('Load.PalletAmount')}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                load?.items?.map((itemPedido) => {
                const palletKey = Object.keys(itemPedido.pallet).length > 0 && Object.keys(itemPedido.pallet)[0];
                const cantidadPallet = palletKey && itemPedido.pallet[palletKey];
                return (
                <TableRow key={itemPedido?.producto}>
                  <TableCell>
                    <Typography color="textSecondary" fontWeight="400">
                      {itemPedido?.producto}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" fontWeight="400">
                      {itemPedido?.peso || t('Fleet.Home.New-So.SinInformar')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" fontWeight="400">
                      {itemPedido?.volumen || t('Fleet.Home.New-So.SinInformar')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" fontWeight="400">
                      {itemPedido?.tipoEmbalaje ? getTranslation(i18, itemPedido.tipoEmbalaje) : t('Fleet.Home.New-So.SinInformar')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" fontWeight="800">
                      {palletKey || '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" fontWeight="800">
                      {cantidadPallet || '-'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )})}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          size = 'small'
          onClick = {handleClose}
          sx={{
            width: '200px',
          }}
          variant = "contained"
        >
          <Typography variant = 'h3'>
          {t('Button.Confirm')}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalLoadDetails.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ModalLoadDetails;