import React from 'react';
import { InputAdornment, Tooltip } from '@mui/material';
import { Warning } from '@mui/icons-material';

import CustomTextField from './forms/custom-elements/CustomTextField';

const CustomTextFieldWithValidation = ({ value, isValid, name, onChange, placeholder = '', onFocus }) => {
  return (
    <CustomTextField
      variant="outlined"
      fullWidth
      name = {name}
      size="small"
      value = {value}
      error = {!isValid}
      onChange = {onChange}
      placeholder = {placeholder}
      autoComplete = 'off'
      onFocus={onFocus}
      InputProps = {{
        inputProps: {
          maxLength: 254,
        },
        endAdornment: !isValid &&
          <InputAdornment position = "end">
            <Tooltip
              title = "Requerido" 
              placement = 'top'
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'crimson',
                    '& .MuiTooltip-arrow': {
                      color: 'crimson',
                    },
                  },
                }}}>
              <Warning sx = {{ color: 'crimson' }}/>
            </Tooltip>
          </InputAdornment>
      }}
    />
  );
};

export default CustomTextFieldWithValidation;