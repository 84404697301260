import React, {useEffect} from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';

const ShowQuestion = ({
  from = '',
  question = '',
  avatar,
  isAnswer,
}) => {
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  return (
    <Grid container spacing={0} direction={!isAnswer ? 'row' : 'row-reverse'}>
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {avatar && (
          <>
          <Avatar
            sx={{
              mt: 1,
              mb: 0,
              backgroundColor: (theme) =>
                isAnswer ? theme.palette.primary.main : `${stringToColor(from)}`,
            }}
          >
            {from.slice(0, 2).toUpperCase()}
          </Avatar>
        <Box width={1}>
          <Typography style={{ wordWrap: 'break-word', textAlign: 'center', fontSize: '13px' }}>
            {from}
          </Typography>
        </Box>
        </> )}
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            width: 1,
            border: (theme) =>
              `3px solid ${isAnswer ? theme.palette.primary.main : stringToColor(from)}`,
              mt: 1,
            p: 2,
            backgroundColor: (theme) => (isAnswer ? '#FFECC2' : theme.palette.background.default),
            borderRadius: '16px 16px 16px 16px',
            color: (theme) => (isAnswer ? 'black' : theme.palette.text.secondary),
          }}
        >
          <Typography
            sx={{ ml: isAnswer ? 0 : 2, mr: isAnswer ? 2 : 0 }}
            style={{ wordWrap: 'break-word' }}
          >
            {question}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ShowQuestion;
