import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

const ModalConfirm = ({
  open,
  handleClose,
  handleConfirm,
  disabled,
  showOnlyConfirm = false,
  sxContentTypography = {},
}) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const handleCloseOutside = (event, reason) => {
    if (reason && reason === 'backdropClick' && disabled) return;
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleCloseOutside} fullWidth maxWidth="sm" scroll="paper">
      <Box
        width={1}
        p={2}
        pt={1}
        pb={1}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box display="flex" alignItems="center">
          <FeatherIcon icon="info" color={theme.palette.primary.main} />
          <Typography variant="h3" ml={1}>
            {t('Configuration.DeleteUser')}
          </Typography>
        </Box>
        <IconButton onClick={handleClose} disabled={disabled}>
          <FeatherIcon icon="x" />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box id="eliminar">
          <Box mx={2} display="flex" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'pre-line',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '100',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                ...sxContentTypography,
              }}
            >
              {t('Configuration.DeleteQuestion')}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {!showOnlyConfirm && (
          <Button
            onClick={handleClose}
            variant="text"
            color="primary"
            size="small"
            sx={{ pr: 2 }}
            disabled={disabled}
          >
            <Typography
              variant="h3"
              sx={{ color: !disabled ? theme.palette.primary.main : theme.palette.text.disabled }}
            >
              {t('Button.Cancel')}
            </Typography>
          </Button>
        )}
        <Button
          onClick={handleConfirm}
          variant="text"
          color="primary"
          size="small"
          sx={{ pr: 2 }}
          disabled={disabled}
        >
          <Typography
            variant="h3"
            sx={{ color: !disabled ? theme.palette.primary.main : theme.palette.text.disabled }}
          >
            {t('Button.Confirm')}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
