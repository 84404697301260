import React, { createContext, useEffect, useState } from 'react';

const ScreenSizeContext = createContext();

export const ScreenSizeProvider = ({ children }) => {
    const [screenSize, setScreenSize] = useState({
      widthScreen: window.innerWidth,
      heightScreen: window.innerHeight,
      heightKPI: window.innerWidth > 1600? 400: window.innerWidth >= 900? 375: window.innerWidth >= 600 ? 350: 300,
      isSmallScreen: window.innerWidth < 900,
      padMarByScreen:window.innerWidth > 1100? 2: window.innerWidth >= 900? 1: window.innerWidth >= 600 ? 0.5: 0,
      mxTableButtons:window.innerWidth > 1100? 1.5: window.innerWidth >= 600? 1: 0.5,
      myTableButtons: window.innerWidth >= 900? 0: 0.5,
      mMainCard: window.innerWidth > 1100? null: window.innerWidth >= 900? 1: window.innerWidth >= 600 ? 0.5: 0,
      sizeButtonByScreen: window.innerWidth < 600? "small": "medium",
      typographyVariantButton: window.innerWidth>450?'h3':'h4',
      typographyKPI: window.innerWidth>600?16: window.innerWidth>450?14: 12,
      sizeFontCardHeader: window.innerWidth >= 900? 24: window.innerWidth > 450? 22: window.innerWidth > 385 ? 20: 19,
      heightCardHeader: window.innerWidth >= 900? 64: window.innerWidth > 450? 52: window.innerWidth > 360 ? 48: 44,
      mbDivider: window.innerWidth >= 900? 0: window.innerWidth > 450? 0.4: window.innerWidth > 360 ? 0.7: 1,
      pBreadcrum: window.innerWidth>=1200?'15px': window.innerWidth>=900?'10px':  window.innerWidth>=600?'5px': '2px',
      mtBreadcrum: window.innerWidth>=1200?0: window.innerWidth>=900?-0.5: window.innerWidth>=600?-1: -2,
      mbBreadcrum: window.innerWidth>=1200?null: window.innerWidth>=900?0.5: window.innerWidth>=600?0.8: 1,
      });

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      setScreenSize({
        widthScreen: newWidth,
        heightScreen: newHeight,
        heightKPI: window.innerWidth > 1600? 400: window.innerWidth >= 900? 375: window.innerWidth >= 600 ? 350: 300,
        isSmallScreen: newWidth < 900,
        padMarByScreen:window.innerWidth > 1100? 2: window.innerWidth >= 900? 1: window.innerWidth >= 600 ? 0.5: 0,
        mxTableButtons:window.innerWidth > 1100? 1.5: window.innerWidth >= 600? 1: 0.5,
        myTableButtons: window.innerWidth >= 900? 0: 0.5,
        mMainCard: window.innerWidth > 1100? null: window.innerWidth >= 900? 1: window.innerWidth >= 600 ? 0.5: 0,
        sizeButtonByScreen: window.innerWidth < 600? "small": "medium",
        typographyVariantButton: window.innerWidth>450?'h3':'h4',
        typographyKPI: window.innerWidth>600?16: window.innerWidth>450?14: 12,
        sizeFontCardHeader: window.innerWidth >= 900? 24: window.innerWidth > 450? 22: window.innerWidth > 385 ? 20: 19,
        heightCardHeader: window.innerWidth >= 900? 64: window.innerWidth > 450? 52: window.innerWidth > 360 ? 48: 44,
        mbDivider: window.innerWidth >= 900? 0: window.innerWidth > 450? 0.4: window.innerWidth > 360 ? 0.7: 1,
        pBreadcrum: window.innerWidth>=1200?'15px': window.innerWidth>=900?'10px':  window.innerWidth>=600?'5px': '2px',
        mtBreadcrum: window.innerWidth>=1200?0: window.innerWidth>=900?-0.5: window.innerWidth>=600?-1: -2,
        mbBreadcrum: window.innerWidth>=1200?null: window.innerWidth>=900?0.5: window.innerWidth>=600?0.8: 1,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ScreenSizeContext.Provider value={screenSize} >
      {children}
    </ScreenSizeContext.Provider>
  );
};

export default ScreenSizeContext;
