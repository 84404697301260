const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, authenticatedData, allPermissions, subscriptions, companyData } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      authenticatedData,
      allPermissions,
      subscriptions,
      companyData
    };
  },
  LOGIN: (state, action) => {
    const { authenticatedData, allPermissions, subscriptions, companyData } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      authenticatedData,
      allPermissions,
      subscriptions,
      companyData,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    authenticatedData: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const authReducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export { authReducer };
