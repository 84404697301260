import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { getActiveUsersRolList } from '../../../api/company';

function ModalViewRole({ viewModal, setViewModal, role, setChoosenRole, setViewModalTeamRole }) {
  const [t] = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const subMsg = t('Loads.Setting.RolesSubMsg');

  async function handleGetRolTeam(id) {
    setLoading(true);
    try {
      const result = await getActiveUsersRolList(id);
      // setActiveUsersList(result.data);
      setUsers(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setUsers([]);
    setChoosenRole(null);
    setViewModalTeamRole(false);
  };

  function handleRetry() {
    setError(false);
    handleGetRolTeam(role.id);
  }

  useEffect(() => {
    if (!viewModal) {
      setError(false);
      setLoading(false);
    }
  }, [viewModal]);

  useEffect(() => {
    if (role && role.id && viewModal) {
      handleGetRolTeam(role.id);
    }
  }, [role, viewModal]);

  const boxStyle = {
    position: 'absolute',
    width: 800,
    minHeight: 400,
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.default,
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pt: 3,
    px: 4,
    pb: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '500ms',
    overflow: 'hidden',
  };

  const boxLoadingStyle = {
    ...boxStyle,
    maxHeight: 200,
    minHeight: 200,
    width: 200,
    height: 200,
    justifyContent: 'center',
  };

  const buttonStyle = {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  };

  return (
    <Modal
      open={viewModal}
      onClose={() => {
        setViewModal(!viewModal);
      }}
    >
      <Box sx={loading ? boxLoadingStyle : boxStyle}>
        {role && !loading && users && (
          <>
            <FeatherIcon icon="user" color={theme.palette.primary.main} size="72" />
            <Box
              sx={{
                width: 1,
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
                gridGap: '10px',
              }}
            >
              <Typography variant="h2" sx={{ textAlign: 'center', letterSpacing: '2px' }}>
                {role.nombre}
              </Typography>
              {subMsg && (
                <Typography sx={{ fontSize: '15px', textAlign: 'center' }}>{subMsg}</Typography>
              )}
            </Box>
            <Box width={1}>
              <List
                dense
                sx={{ width: '100%', textAlign: 'left', maxHeight: '50vh', overflowY: 'auto' }}
              >
                {users && users.length === 0 && (
                  <ListItem>
                    <ListItemText
                      primary={t('Loads.Documents_View.No_Results')}
                      primaryTypographyProps={{ fontSize: '18px' }}
                    />
                  </ListItem>
                )}
                {users.map((user) => {
                  return (
                    <ListItem key={user.id}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ bgcolor: theme.palette.primary.main }}
                          src={user.foto ? user.foto : ''}
                        >
                          {user.nombre && user.apellido
                            ? `${user.apellido[0].toUpperCase()}${user.nombre[0].toUpperCase()}`
                            : user.apellido
                            ? `${user.apellido.slice(0, 2).toUpperCase()}`
                            : user.nombre
                            ? `${user.nombre.slice(0, 2).toUpperCase()}`
                            : user.mail
                            ? `${user.mail.slice(0, 2).toUpperCase()}`
                            : 'AN'}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          user.nombre && user.apellido
                            ? `${user.apellido} ${user.nombre}`
                            : user.apellido
                            ? `${user.apellido}`
                            : user.nombre
                            ? `${user.nombre}`
                            : user.mail
                            ? `${user.mail}`
                            : 'Anonimo'
                        }
                        primaryTypographyProps={{ fontSize: '18px' }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={handleClose} sx={buttonStyle}>
                {t('Button.Close')}
              </Button>
            </Box>
          </>
        )}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: theme.palette.primary.main }} />
          </Box>
        )}
        {error && (
          <>
            <FeatherIcon icon="x" color="crimson" size="72" />
            <Box
              sx={{
                width: 1,
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
                gridGap: '10px',
              }}
            >
              <Typography sx={{ fontSize: '22px', textAlign: 'center' }}>
                {t('Loads.Setting.RolesSubMsg')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={handleClose} sx={buttonStyle}>
                {t('Button.Close')}
              </Button>
              <Button onClick={handleRetry} sx={buttonStyle}>
                {t('Button.Retry')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default ModalViewRole;
