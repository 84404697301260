import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import Breadcrumb from '../../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import useFetch from '../../../hooks/useFetch';
import useAuth from '../../../hooks/useAuth';

// filter local data
// import Loads from './data.json';
import { filterData } from './utils/filter';

// api
import { getListOfTravelTrackingLoad } from '../../../api/viaje';
import { getCompany } from '../../../api/company';

// components
import PageContainer from '../../../components/container/PageContainer';
import CardMap from './components/CardMap';
import CustomSearchBox from '../../../components/forms/custom-elements/CustomSearchbox';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';
import { mtTypographyTableButtons, sxTableButtons } from '../../../constants/styleTableButton';

const MapView = ({ parent }) => {
  const [travels, , refetch, readyFetch] = useFetch(getListOfTravelTrackingLoad);
  const [map, setMap] = useState();
  const [travelsData, setTravelsData] = useState([]);
  const [travelsDataCopy, setTravelsDataCopy] = useState([]);
  const [emptyResult, setEmptyResult] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const { authenticatedData } = useAuth();
  const [t] = useTranslation();
  const {padMarByScreen, widthScreen, mxTableButtons, myTableButtons, mMainCard, typographyVariantButton}= useContext(ScreenSizeContext);
  /* const [cordsCompany, setCordsCompany] = useState(); */
  /*  const [permissionLocation, setPermissionLocation]=useState(false) */
  const BCrumb = [
    {
      to: '/carga/inicio',
      title: t("Loads.Loads"),
    },
    {
      title: t("Loads.Outset.Map"),
    },
  ];
  const [userLocation, setUserLocation] = useState({
    loaded: false,
    coordinates: { lng: '', lat: '' },
  });
  const [variableZoom, setVariableZoom] = useState(4);

  const onMapLoad = (mapLoaded) => {
    setMap(mapLoaded);
  };

  const filter = async (text) => {
    setSearchText(text);
    try {
      // api filter
      // const result = await getCargoSearch(text);

      // filter localdata
      const result = filterData(text, travelsDataCopy);
      setTravelsData(result);

      setEmptyResult(false);
    } catch (error) {
      setEmptyResult(true);
      setTimeout(() => {
        setSearchText('');
      }, 5000);
    }
  };

  navigator.permissions.query({ name: 'geolocation' }).then((result) => {
    // if (result.state === 'granted') {
    //   console.log(result);
    // } else if (result.state === 'prompt') {
    //   console.log(result);
    // }
    // Don't do anything if the permission was denied.
  });

  useEffect(() => {
    if (travels?.length > 0) {
      setTravelsData(travels);
      setTravelsDataCopy(travels);
    } else if (travels && Object.keys(travels).length > 0) {
      setTravelsData([]);
      setTravelsDataCopy([]);
    }
  }, [travels]);

  useEffect(() => {
  
    if (readyFetch && map && travelsData) {
      const bounds = new window.google.maps.LatLngBounds();
      if (travelsData.length > 0) {
        travelsData.map((item) => {
          const { points, ubicacionInicial } = item;

          if (points && points.lat && points.lon) {
            return bounds.extend({
              lat: points.lat,
              lng: points.lon,
            });
          }

          if (ubicacionInicial?.latitud && ubicacionInicial?.longitud) {
            return bounds.extend({
              lat: ubicacionInicial?.latitud,
              lng: ubicacionInicial?.longitud,
            });
          }
          return null
        });
        setVariableZoom(5);
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        map.setZoom(5);
      } else if (
        // travels.mensaje &&
        authenticatedData.ubicacion?.latitud &&
        authenticatedData.ubicacion?.longitud
      ) {
        bounds.extend({
          lat: authenticatedData.ubicacion.latitud,
          lng: authenticatedData.ubicacion.longitud,
        });
        setVariableZoom(5);
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        map.setZoom(5);
        // } else if (travels.mensaje && !('geolocation' in navigator)) {
      } else if (!('geolocation' in navigator)) {
        setUserLocation({
          loaded: true,
          error: { code: 0, message: 'su dispositivo no soporta geolocalización' },
        });
        bounds.extend({
          lat: -21.04153,
          lng: -57.87377,
        });
        setVariableZoom(5);
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        map.setZoom(5);
        // } else if (travels.mensaje) {
      } else {
        navigator.geolocation.getCurrentPosition(
          (location) => {
            bounds.extend({
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            });
            setVariableZoom(5);
            map.fitBounds(bounds);
            map.panToBounds(bounds);
            map.setZoom(5);

            setUserLocation({
              loaded: true,
              coordinates: {
                lng: location.coords.longitude,
                lat: location.coords.latitude,
              },
            });
          },
          (error) => {
            setVariableZoom(13);
            bounds.extend({
              lat: -21.04153,
              lng: -57.87377,
            });
            map.fitBounds(bounds);
            map.panToBounds(bounds);
            map.setZoom(13);

            setUserLocation({
              loaded: true,
              error,
            });
          },
        );
      }
    }
  }, [map, travelsData]);

  return (
    <>
      {parent !== 'true' ? (
        <PageContainer title={t('Loads.Outset.Map')} description="Map view travels active">
          <Box display="flex" alignItems="center">
            <Breadcrumb items={BCrumb} />
          </Box>
          <Grid item xs={12} >
          <Grid container p={padMarByScreen} m={mMainCard}>
            <Grid item p={0} xs={widthScreen>450?5:12} sm={6} md={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{...sxTableButtons, mx:mxTableButtons, my:myTableButtons}}
                endIcon={<RefreshIcon style = {{ fontSize: '24px' }} sx={{ mr:widthScreen>450? 0.5:0.4, ml:widthScreen>420? 0:-0.6}}/>}
                onClick={() => refetch()}
              >
                <Typography variant = {typographyVariantButton} sx={{ mt:mtTypographyTableButtons}}>
                {t('Poster.Update')}
                </Typography>
              </Button>
            </Grid>
            <Grid item  p={1} pt={widthScreen>650?1.5:1} xs={widthScreen>450?7:12} sm={6} md={6}>
              <CustomSearchBox
                placeHolder={t('Board.Search')}

                width={500}
                doSearch={(text) => {
                  filter(text);
                }}
              />
            </Grid>
            </Grid>
            <CardMap
              travels={travelsData}
              setShowInfoWindow={setShowInfoWindow}
              showInfoWindow={showInfoWindow}
              emptyResult={emptyResult}
              onMapLoad={onMapLoad}
              searchText={searchText}
              loggedLocation={authenticatedData.ubicacion}
              userLocation={userLocation}
              variableZoom={10}
            />
          </Grid>
        </PageContainer>
      ) : (
        <>
          {' '}
          <Grid item xs={12}  p={padMarByScreen} m={mMainCard}>
            <Grid container p={padMarByScreen} m={mMainCard}
            display="flex"
            justifyContent="space-between" 
            alignItems="center"
            >
              <Grid item p={0} xs={widthScreen>450?5:12} sm={6} md={6}>
              <Button
                endIcon={<RefreshIcon sx={{mr:widthScreen>450? 0.5:0.4, ml:widthScreen>420? 0:-0.6}}/>}
                variant="contained"
                color="primary"
                sx={{...sxTableButtons, mx: 0, my: myTableButtons}}
                onClick={() => refetch()}
              >
                <Typography variant = {typographyVariantButton} sx={{ mt:mtTypographyTableButtons}}>
                {t('Poster.Update')}
                </Typography>
              </Button>
              </Grid>
              <Grid item  p={1} pt={widthScreen>650?1.5:1} xs={widthScreen>450?7:12} sm={6} md={6}>
              <CustomSearchBox
                placeHolder={t('Loads.Outset.Search...')}
                width={widthScreen>=1500? 500: widthScreen>700? 600:300}
                doSearch={(text) => {
                  filter(text);
                }}
              />
              </Grid>
            </Grid >
            <CardMap
              travels={travelsData}
              setShowInfoWindow={setShowInfoWindow}
              showInfoWindow={showInfoWindow}
              emptyResult={emptyResult}
              onMapLoad={onMapLoad}
              searchText={searchText}
              loggedLocation={authenticatedData.ubicacion}
              userLocation={userLocation}
              variableZoom={variableZoom}
            />
          </Grid>{' '}
        </>
      )}
    </>
  );
};

export default MapView;
