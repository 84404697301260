import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import DashedCircularProgressbar from './DashedCircularProgressbar';

import WEIGHT from '../assets/images/logos/weight.svg';
import VOLUME from '../assets/images/logos/volume.svg';
import PALLET from '../assets/images/logos/pallet.svg';

const flex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}

const PercentageDetails = ({ loads, vehicleSelected, subVehicleSelected }) => {
  const [t] = useTranslation();
  function getVehicleDetails (v, subType) {
    let pallet = null;
    let palletAmount = 0;
    const palletKeys = subType && subType.pallet && Object.keys(subType.pallet);
    if (palletKeys && palletKeys.length > 0) {
      const [ palletName ] = palletKeys;
      pallet = palletName;
      palletAmount = subType.pallet[pallet];
    }

    const detailsToReturn = {
      peso: subType.peso || 0,
      volumen: subType.volumen || 0,
      tipoPallet: pallet,
      cantidadPallet: palletAmount || 0,
      nombre: v.nombre,
      subTipo: subType.nombre
    }

    return detailsToReturn;
  }

  function getData (initialLoads, newVehicleDetails) { 
    const loadsToCalculate = [];

    for (let i = 0; i < initialLoads.length; i++) {

      const itemsPedido = initialLoads[i]?.itemPedido;

      for (let j = 0; j < itemsPedido?.length; j++) {

        const totalPalletsKeys = itemsPedido[j].pallet && Object.keys(itemsPedido[j].pallet);
        let totalPallets = 0;

        for (let k = 0; k < totalPalletsKeys?.length || 0; k++) {
          totalPallets += itemsPedido[j].pallet[totalPalletsKeys[k]];
        }

        loadsToCalculate.push({
          pesoTotal: itemsPedido[j].peso || 0,
          volumenTotal: itemsPedido[j].volumen || 0,
          cantidadPallet: totalPallets,
        });
      }
    }

    const totalData = loadsToCalculate.reduce((acum, load) => {
      return  {
          peso: acum.peso + load.pesoTotal,
          volumen: acum.volumen + load.volumenTotal,
          pallets: acum.pallets + load.cantidadPallet,
        }
      }, {
        peso: 0,
        volumen: 0,
        pallets: 0,
    });

    const percentagePeso = newVehicleDetails.peso > 0 ? Math.ceil(totalData.peso / newVehicleDetails.peso * 100) : 0;
    const percentageVolumen = newVehicleDetails.volumen > 0 ? Math.ceil(totalData.volumen / newVehicleDetails.volumen * 100) : 0;
    const percentagePallets = newVehicleDetails.cantidadPallet > 0 ? Math.ceil(totalData.pallets / newVehicleDetails.cantidadPallet * 100) : 0;
 
    return {
      peso: {
        total: totalData.peso,
        percentage: newVehicleDetails.peso > 0 ? 
          percentagePeso > 100 ? 'EXCEDIDO' : percentagePeso :
          newVehicleDetails.peso === 0 && totalData.peso > 0 ?
            'ERROR' :
            0
      },
      volumen: {
        total: totalData.volumen,
        percentage: newVehicleDetails.volumen > 0 ? 
          percentageVolumen > 100 ? 'EXCEDIDO' : percentageVolumen :
          newVehicleDetails.volumen === 0 && totalData.volumen > 0 ?
            'ERROR' :
            0
      },
      pallets: {
        total: totalData.pallets,
        percentage: newVehicleDetails.cantidadPallet > 0 ?
          percentagePallets > 100 ? 'EXCEDIDO' : percentagePallets :
          newVehicleDetails.cantidadPallet === 0 && totalData.pallets > 0 ?
            'ERROR' :
            0
      }
    };
  }
 
  const vehicle = getVehicleDetails(vehicleSelected, subVehicleSelected); 
   const data = getData(loads, vehicle); 

  return (
    <Grid container spacing = {2}>
      <Grid item xs = {12} sm = {4} sx = {flex}>
        <Typography variant = 'h4' textAlign = 'center' mb = {1}>{t('Types.PesoOcupado')}</Typography>
        <Box width={0.7}>
          {
            vehicle && vehicle.peso && vehicle.peso >= 1 ?
            <DashedCircularProgressbar
              type = 'PESO-MINI' 
              value = {data.peso.percentage === 'EXCEDIDO' ? 101 : data.peso.percentage}
              showOnlyPercentage
              bg = {WEIGHT}
            />
            :
            <DashedCircularProgressbar
              type = 'PESO-MINI-DISABLED'
              value = {0}
              showOnlyPercentage
              disabled
              bg = {WEIGHT}
            />
          }
        </Box>
      </Grid>

      <Grid item xs = {12} sm = {4} sx = {flex}>
        <Typography variant = 'h4' textAlign = 'center' mb = {1}>{t('Types.VolumenOcupado')}</Typography>
        <Box width={0.7}>
            {
              vehicle && vehicle.volumen && vehicle.volumen >= 1 ?
              <DashedCircularProgressbar
                type = 'VOLUMEN-MINI' 
                value = {data.volumen.percentage === 'EXCEDIDO' ? 101 : data.volumen.percentage}
                showOnlyPercentage
                bg = {VOLUME}
              />
              :
              <DashedCircularProgressbar
                type = 'VOLUMEN-MINI-DISABLED'
                value = {0}
                showOnlyPercentage
                disabled
                bg = {VOLUME}
              />
            }
        </Box>
      </Grid>
      
      <Grid item xs = {12} sm = {4} sx = {flex}>
        <Typography variant = 'h4' textAlign = 'center' mb = {1}>{t('Types.PalletOcupado')}</Typography>
        <Box width={0.7}>
          
          {
            vehicle && vehicle.tipoPallet && vehicle.cantidadPallet >= 1 ?
            <DashedCircularProgressbar
              type = 'PALLET-MINI'
              value = {data.pallets.percentage === 'EXCEDIDO' ? 101 : data.pallets.percentage}
              showOnlyPercentage
              bg = {PALLET}
            />
            :
            <DashedCircularProgressbar
              type = 'PALLET-MINI-DISABLED'
              value = {0}
              showOnlyPercentage
              disabled
              bg = {PALLET}
            />
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default PercentageDetails;