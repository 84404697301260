import React, { useContext } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { validAutocomplete } from '../utils/list';
import { GetAutocomplete, getTextField } from '../../../utils/forms';
import GoogleMapsContext from '../../../contexts/MapContext';

const FormDefault = ({
  formItems,
  formik,
  listValues,
  onLoad,
  onPlacesChanged,
  isMultiple,
  index,
  openModal,
}) => {
  const [t] = useTranslation();
  const { google } = useContext(GoogleMapsContext);
  const style = { margin: 1, display: 'flex', flexDirection: 'row', justifyContent: 'between' };

  const searchBoxField = (property) => {
    if (google === 'Error loading maps') return google;
    if (google === 'Loading Maps') return google;
    return (
      <StandaloneSearchBox
        onLoad={(ref) => onLoad(ref)}
        onPlacesChanged={() => {
          onPlacesChanged(property);
        }}
      >
        <Grid container spacing={2}>
          {getTextField({ ...property, xs: 12 }, formik, {
            placeholder: `${t('Fleet.Home.New-So.Enter-address')}`,
          })}
        </Grid>
      </StandaloneSearchBox>
    );
  };

  return (
    <Grid item xs={12}>
      {formItems.map((property) => {
        const propertyCopy = isMultiple
          ? {
              ...property,
              array: 'invitaciones',
              key: index,
              field: property.id,
              hideLabel: true,
              id: `invitaciones[${index}].${property.id}`,
              multiple: true,
            }
          : {
              ...property,
              hideLabel: true,
            };

        const { id, onlyError, showSearchCompany } = property;
        const validProperty = validAutocomplete(id);

        if (showSearchCompany)
          return (
            <Box
              sx={{
                width: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 3,
              }}
            >
              <IconButton onClick={openModal}>
                <Typography variant="h4">
                  {t('Login.¿No encuentras tu empresa? Prueba...')}
                  <Typography color="primary" variant="h4">
                    {t('Configuration.Fiscal Identifier')}.
                  </Typography>
                </Typography>
              </IconButton>
            </Box>
          );

        return (
          <Grid container key={property.id} sx={{ ...style }} spacing={2}>
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <CustomFormLabel
                htmlFor={property.id}
                variant="h5"
                letterSpacing={1}
                sx={{
                  mt: 0,
                  mb: 0,
                }}
              >
                {`${property.label}${property.required ? '*' : ''}${
                  property.label.length > 0 ? ':' : ''
                }`}
              </CustomFormLabel>
            </Grid>
            {property.id === 'direccion' ? (
              <Grid item xs={7}>
                {searchBoxField(property)}
              </Grid>
            ) : validProperty ? (
              GetAutocomplete(property, formik, listValues[validProperty.listValue], {
                getOptionLabel: (option) => {
                  if (
                    option.id === 'CREAR_EMPRESA' ||
                    option.id === 'UNIRSE_EMPRESA' ||
                    option.id === 'MARKETPLACE'
                  )
                    return (option && t(`Login.${option[validProperty.optionLabel]}`)) || '';
                  return (option && option[validProperty.optionLabel]) || '';
                },
                isOptionEqualToValue: (option, value) => option.id === value.id,
                placeholder:
                  `${validProperty.placeholder ? t(`Login.${validProperty.placeholder}`) : ''}` ||
                  `${t('Button.SelectAnOption')}`,
                sx: {
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: (theme) => theme.palette.text.primary,
                  },
                },
              })
            ) : onlyError ? (
              <Typography sx={{ fontSize: '12px', ml: 4 }} color="crimson">
                {formik.touched[property.id] && formik.errors[property.id]}
              </Typography>
            ) : (
              getTextField(propertyCopy, formik, {
                placeholder:
                  `${propertyCopy.placeholder ? t(`Login.${propertyCopy.placeholder}`) : ''}` || '',
                sx: {
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: (theme) => theme.palette.text.primary,
                  },
                },
              })
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FormDefault;
