import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Card,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

// hooks
import useAuth from '../../../hooks/useAuth';
import useFetch from '../../../hooks/useFetch';

// api
import { getUser, updateUserAvatar, updateUser } from '../../../api/users';
import { getCountries } from '../../../api/country';

// components
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';

// utils
import { FormPasswordValidation, FormProfileValidation } from '../utils/validation';
import { FormUserItems, FormPasswordItems } from '../mocks/forms';
import { getInitialValues } from '../mocks/initialValues';
import { getDataPost } from '../mocks/postMock';
import { BASE_URL } from '../../../config';
import { GetAutocomplete, GetAutoCompleteAsComponent } from '../../../utils/forms';
import GoogleMapsContext from '../../../contexts/MapContext';

// constants
const UserProfile = () => {
  const [t] = useTranslation();
  const { google } = useContext(GoogleMapsContext);
  const [editable, setEditable] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const { authenticatedData } = useAuth();
  const [user, setUser] = useState();
  const [searchBox, setSearchBox] = useState();
  const { id: userId } = authenticatedData || {};
  const [countries] = useFetch(getCountries);

  const { enqueueSnackbar } = useSnackbar();

  const [loadingUploadFile, setloadingUploadFile] = useState(false);

  const [updatedAvatarTime, setUpdatedAvatarTime] = useState(1);
  const [delayImg, setDelayImg] = useState(false);

  const smUp = useMediaQuery((th) => th.breakpoints.up('sm'));
  const theme = useTheme();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const getUserData = async () => {
    if (userId !== null) {
      const result = await getUser(userId);
      setUser(result.data);
    }
  };

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const formik = useFormik({
    initialValues: getInitialValues(user),
    enableReinitialize: true,
    validationSchema: !editPassword ? FormProfileValidation(t) : FormPasswordValidation(t),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const data = getDataPost(values) 
      try {
        if(data?.ubicacion !==null && (!data?.ubicacion?.latitud || !data?.ubicacion?.longitud))  throw new Error("La dirección no es válida");
        await updateUser(data);

        if (!editPassword) {
          showMessage(t('Select.UpdateSuccess'), 'success');
          setEditable(false);
        } else {
          showMessage(t('Select.PassUpdateSuccess'), 'success');
          setEditPassword(false);
          formik.resetForm();
        }
        getUserData();
      } catch (error) {
        showMessage(
          error?.response?.data?.error || error?.message || t('Configuration.ServerError'),
          'error',
          console.log(error),
        );
        setStatus({ success: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    },
  });

  const onPlacesChanged = (property) => {
    const places = searchBox.getPlaces();
    const place = places && places[0];
    const lat = place?.geometry?.location?.lat() || 0;
    const lng = place?.geometry?.location?.lng() || 0;

    if (place) {
      formik.setFieldValue(`${property.id}.latitud`, lat);
      formik.setFieldValue(`${property.id}.longitud`, lng);
      formik.setFieldValue(`${property.id}.direccion`, place.formatted_address);

      const { address_components: addressComponents } = place;

      /* eslint-disable no-continue */
      for (let i = 0; i < addressComponents.length; i++) {
        const componente = addressComponents[i];
        if (!componente.types) continue;
        if (componente.types.includes('country')) {
          formik.setFieldValue(`${property.id}.pais`, {
            nombre: componente.long_name,
            // id: componente.short_name
          });
        }
        if (componente.types.includes('administrative_area_level_1'))
          formik.setFieldValue(`${property.id}.provincia`, componente.long_name);
        if (componente.types.includes('administrative_area_level_2'))
          formik.setFieldValue(`${property.id}.localidad`, componente.long_name);
        if (componente.types.includes('postal_code'))
          formik.setFieldValue(`${property.id}.codigoPostal`, componente.long_name);
      }
    }
    /* eslint-enable */
  };

  const getTextField = (property, value, name, disabled = false) => {

    const handleChange=(e)=>{
      if(property.id ==="ubicacion"){
        formik.setFieldValue(`ubicacion.latitud`, null)
        formik.setFieldValue(`ubicacion.longitud`, null)
        formik.setFieldValue(`ubicacion.select`, false)
        return formik.handleChange(e)
      }
      return formik.handleChange(e)
    }

    return (
      <Grid item xs={property.xs} key={property.id}>
        <CustomFormLabel htmlFor={property.id}>
          {t(`Configuration.${property.label}`)}
        </CustomFormLabel>
        <CustomTextField
          id={property.id}
          name={name}
          onKeyDown={handleKeyDown}
          onBlur={formik.handleBlur}
          onChange={handleChange}
          value={value || ''}
          error={Boolean(formik.touched[property.id] && formik.errors[property.id])}
         // helperText={formik.touched[property.id] && formik.errors[property.id]}
          variant="outlined"
          disabled={property.disabled || disabled || (!editable && !editPassword)}
          placeholder={property.placeholder}
          fullWidth
          autoComplete="off"
          type={property.type}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.primary,
            },
          }}
        />
      </Grid>
    );
  };

  const searchBoxField = (property, value, name) => {
    if (google === 'Error loading maps') return google;
    if (google === 'Loading Maps') return google;
    return (
      <StandaloneSearchBox
        onLoad={onLoad}
        onPlacesChanged={() => {
          onPlacesChanged(property);
        }}
      >
        {getTextField(property, value, name)}
      </StandaloneSearchBox>
    );
  };
  

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (user && user.idPais && formik && formik.values && !formik.values.paisSelect && countries) {
      formik.setFieldValue(
        'paisSelect',
        countries.find((c) => c.id === user.idPais),
      );
    }
  }, [user, formik, countries]);

  const validateFile = (file) => {
    return file && ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
  };

  const forceRerenderAvatar = async () => {
    setDelayImg(true);
    await new Promise((resolve) => setTimeout(resolve, 50));
    setDelayImg(false);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('profile_avatar', file);

    setloadingUploadFile(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const result = await updateUserAvatar(formData);
      if (result.data.path) {
        showMessage(t('Select.ImgUpdateSuccess'), 'success');
        getUserData();
        setUpdatedAvatarTime((value) => value + 1);
        forceRerenderAvatar();
      } else throw new Error(t('Select.NoImg'));
    } catch (e) {
      console.log(e);
      showMessage(t('Select.ErrorImg'), 'error');
    } finally {
      setloadingUploadFile(false);
    }
  };

  const handleUploadFile = (e) => {
    const [fileToUpload] = e.target.files;
    if (validateFile(fileToUpload)) {
      uploadFile(fileToUpload);
    } else {
      e.target.value = null;
      showMessage(t('Select.ErrorExtension'), 'error');
    }
  };


  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {!editPassword && !editable && <Grid item sm={3} />}
        <Grid
          item
          xs={12}
          sm={!editPassword && !editable ? 6 : 12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          mt={3}
          mb={2}
        >
          <Typography variant="h2" sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
            {t('Configuration.SubTitleUser')}
          </Typography>
          {!delayImg && (
            <Avatar
              key={updatedAvatarTime}
              sx={{
                bgcolor: theme.palette.primary.main,
                height: '150px',
                width: '150px',
                fontSize: '70px',
              }}
              src={user && user.pathFoto ? `${BASE_URL}${user.pathFoto}?${updatedAvatarTime}` : ''}
            >
              {user && user.nombre && user.nombre.slice(0, 2).toUpperCase()}
            </Avatar>
          )}
          {editable && (
            <IconButton sx={{ mt: 2 }} component="label" disabled={loadingUploadFile}>
              <Typography
                color={!loadingUploadFile ? 'primary' : 'disabled'}
                fontSize="20px"
                letterSpacing="2px"
              >
                {!loadingUploadFile
                  ? t('Configuration.ChangePhoto')
                  : t('Configuration.UpdatePhoto')}
              </Typography>
              <input type="file" hidden onChange={handleUploadFile} />
            </IconButton>
          )}
        </Grid>
        {!editPassword && !editable && (
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent={smUp ? 'flex-end' : 'center'}
            alignItems={smUp ? 'flex-start' : 'center'}
          >
            <IconButton
              onClick={() => setEditPassword(true)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <VpnKeyIcon
                style={{ fontSize: 30, color: theme.palette.text.secondary, marginRight: '5px' }}
              />
              <Typography variant="h4" color={theme.palette.text.secondary} textAlign="center">
                {' '}
                {t('Configuration.ChangePass')}
              </Typography>
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Box p={2}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            {!editPassword &&
              FormUserItems().map((property) => {
                const value = property.extraProperty
                  ? formik.values[property.id][property.extraProperty]
                  : formik.values[property.id];
                const name = property.extraProperty
                  ? `${property.id}.${property.extraProperty}`
                  : property.id;

                if (name === 'pais')
                  return (
                    <GetAutoCompleteAsComponent
                      key={`country-field-${name}`}
                      property={property}
                      formik={formik}
                      listItems={countries}
                      props={{
                        getOptionLabel: (option) => {
                          return (option && t(`Country.${option.nombre}`)) || '';
                        },
                        isOptionEqualToValue: (option, current) => option.id === current.id,
                        placeholder: t('Select.Country'),
                        disabled: !editable,
                        sx: {
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: theme.palette.text.primary,
                          },
                        },
                      }}
                    />
                  );

                if (property.extraProperty === 'direccion')
                  return (
                    <Grid item xs={property.xs} key={`address-field-${name}`}>
                      {searchBoxField(property, value, name)}
                    </Grid>
                  );

                return getTextField(property, value, name);
              })}

            {editPassword && (
              <>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography variant="h3" sx={{ textAlign: 'center' }}>
                    {t('Configuration.ChangePass')}
                  </Typography>
                </Grid>
                {FormPasswordItems().map((property, index) => (
                  <Fragment key={`grid-item-password-${property.id}-${index.toString()}`}>
                    <Grid item xs={1} sm={2} md={3} lg={4} />
                    <Grid item xs={10} sm={8} md={6} lg={4}>
                      <Grid container spacing={2}>
                        {getTextField(property, formik.values[property.id])}
                      </Grid>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} lg={4} />
                  </Fragment>
                ))}
                <Grid item xs={12} mb={5} />
              </>
            )}
          </Grid>

          {editable || editPassword ? (
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                onClick={() => {
                  if (editable) setEditable(false);
                  if (editPassword) setEditPassword(false);
                  formik.resetForm();
                }}
                sx={{
                  mt: 4,
                }}
              >
                {t('Configuration.Cancel')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  mt: 4,
                }}
              >
                {t('Configuration.Save')}
              </Button>
            </Box>
          ) : (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => setEditable(true)}
                sx={{
                  mt: 4,
                }}
              >
                {t('Configuration.Edit')}
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </Card>
  );
};

export default UserProfile;
