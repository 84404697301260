import { getNcm } from '../../api/ncm';
import { getPalletTypes } from '../../api/pallet';
import { getCompany } from '../../api/company';
import {
  THEME_COLOR,
  NAVBAR_BG,
  SIDEBAR_BG,
  DIRECTION,
  DARK_THEME,
  DISABLE_DATETIME,
  VEHICLE_OPTION,
  ROW_TO_EDIT,
  CALENDAR_TRANSLATION,
  ACTIVE_MODULES
} from '../constants';


export const setTheme = (payload) => ({
  type: THEME_COLOR,
  payload,
});
export const setDarkMode = (payload) => ({
  type: DARK_THEME,
  payload,
});
export const setNavbarBg = (payload) => ({
  type: NAVBAR_BG,
  payload,
});

export const setSidebarBg = (payload) => ({
  type: SIDEBAR_BG,
  payload,
});

export const setDir = (payload) => ({
  type: DIRECTION,
  payload,
});

export const disableDateTime = (payload) => ({
  type: DISABLE_DATETIME,
  payload,
});

export const chooseVehicle = (payload) => ({
  type: VEHICLE_OPTION,
  payload,
});

export const selectDataRow = (payload)=> ({
  type: ROW_TO_EDIT,
  payload,
})

export const tranlateCalender = (payload)=> ({
  type: CALENDAR_TRANSLATION,
  payload
})



export const fetchNCM = () => {
  return async (dispatch) => {
    try {
      const response = await getNcm();
      dispatch({ type: "FETCH_DATA_NCM", payload: response.data });
    } catch (error) {
      console.error(error);
    }
  };
}

export const fetchGetPalletTypes = () => {
  return async (dispatch) => {
    try {
      const response = await getPalletTypes();
      dispatch({ type: "FETCH_DATA_PALLET", payload: response.data });
    } catch (error) {
      console.error(error);
    }
  };
}

export const getActiveModules = (idEmpresa) => {
  return async (dispatch) => {
    try {
      const response = await getCompany(idEmpresa);
      dispatch({ type: ACTIVE_MODULES, payload: response.data.modulosActivos });
    } catch (error) {
      console.error(error);
    }
  };
}