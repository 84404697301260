import React, { useState, useEffect } from 'react';

import Map from '../../../../../components/Map';
import DirectionRenderComponente from '../DirectionRenderComponente';
import RouteDetails from '../../../../../components/RouteDetails';

function sortWayPoints(oneW, anotherW) {
  return oneW.orden - anotherW.orden;
}

function initializeWayPoint(wayPoint) {
  return {
    lat: wayPoint.latitud,
    lng: wayPoint.longitud,
  };
}

const DetalleMap = ({ detalle, fullWidth = false }) => {
  const [map, setMap] = useState(null);
  const [pointA, setPointA] = useState(null);
  const [pointB, setPointB] = useState(null);
  const [path, setPath] = useState({
    from: { 
      lat: detalle?.ubicacionDesde?.latitud,
      lng: detalle?.ubicacionDesde?.longitud 
    },
    to: { 
      lat: detalle?.ubicacionHasta?.latitud, 
      lng: detalle?.ubicacionHasta?.longitud 
    },
    waypoints: detalle?.wayPoints?.sort(sortWayPoints).map(initializeWayPoint) || [],
    strokeColor: '#f68f54',
  });

  const [center, setCenter] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);
  const [errorNoUbicacion, setErrorNoUbicacion] = useState(false);
  
  
  function calculateMidPoint(onePoint, anotherPoint) {
    return {
      lat: onePoint.lat + (anotherPoint.lat - onePoint.lat) * 0.5,
      lng: onePoint.lng + (anotherPoint.lng - onePoint.lng) * 0.5,
    };
  }

  function onMapLoad(mapLoaded) {
    setMap(mapLoaded);
  }

  const assignPoints = (placeResult, setPoint) => {
    const locationResult = {
      lat: placeResult?.geometry?.location?.lat() || 0,
      lng: placeResult?.geometry?.location?.lng() || 0,
    };
    setPoint(locationResult);
  };

  async function timeoutUntilGoogleLoads(count) {
    if (!window.google && count > 0) timeoutUntilGoogleLoads(count - 1);
  }

  function searchMarkers(origin, destiny) {
    const originRequest = {
      query: origin,
      fields: ['name', 'geometry'],
    };
    const destinyRequest = {
      query: destiny,
      fields: ['name', 'geometry'],
    };

    if (!window.google) timeoutUntilGoogleLoads(10);

    const service = new window.google.maps.places.PlacesService(map);
    service.findPlaceFromQuery(originRequest, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const placeResult = results[0];
        assignPoints(placeResult, setPointA);
      }
    });
    service.findPlaceFromQuery(destinyRequest, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const placeResult = results[0];
        assignPoints(placeResult, setPointB);
      }
    });
  }

  function getShowMap() {
    const hayOrigen = detalle.ubicacionDesde;
    const hayOrigenDireccion = hayOrigen && hayOrigen.direccion;
    const hayOrigenCoordenadas =
      hayOrigen && detalle.ubicacionDesde.latitud !== 0 && detalle.ubicacionDesde.longitud !== 0;

    const hayDestino = detalle.ubicacionHasta;
    const hayDestinoDireccion = hayDestino && detalle.ubicacionHasta.direccion;
    const hayDestinoCoordenadas =
      hayDestino && detalle.ubicacionHasta.latitud !== 0 && detalle.ubicacionHasta.longitud !== 0;

    if (
      hayOrigenDireccion &&
      !hayOrigenCoordenadas &&
      hayDestinoDireccion &&
      !hayDestinoCoordenadas
    )
      searchMarkers(detalle.ubicacionDesde.direccion, detalle.ubicacionHasta.direccion);
    else if (!hayOrigenCoordenadas && !hayDestinoCoordenadas) setErrorNoUbicacion(true);
  }

  useEffect(() => {
    if (map) getShowMap();
  }, [detalle, map]);


  useEffect(() => {
    if (pointA && pointB) {
      setPath({
        from: { lat: pointA.lat, lng: pointA.lng },
        to: { lat: pointB.lat, lng: pointB.lng },
      });
      setCenter(calculateMidPoint(pointA, pointB));
    }

  }, [pointA, pointB]);

  if (errorNoUbicacion) <></>;

  return (
    <>
      <Map location={center} zoom={6} onMapLoad={onMapLoad}>
        <DirectionRenderComponente
          key={12379}
          index="A"
          strokeColor={path.strokeColor}
          from={path.from}
          to={path.to}
          wayPoints={path.waypoints}
          updateRouteInfo={(newRouteInfo) => setRouteInfo(newRouteInfo)}
        />
      </Map>
      {routeInfo && <RouteDetails routes={routeInfo} fullWidth={fullWidth} sx={{ mt: 2 }} />}
    </>
  );
};

export default DetalleMap;
