export const modules = {
    load: "CARGA",
    fleet:"FLOTA",
    marketplace:"MARKETPLACE",
    workflow:"WORKFLOW",
    controlTower:"CONTROLTOWER",
    bay:"BAHIA",
}

export const plans = {
    bonificado: "Bonificado",
    free:"Free",
    basic:"Basic",
    starter: "Starter",
    business: "Business",
    enterprise: "Enterprise",
    custom: "Custom"
}


