/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { StandaloneSearchBox } from '@react-google-maps/api';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';




const AddressField = ({ formik, arrayHelpers, onLoad, searchBoxRefs,editable}) => {

  const [t] = useTranslation();

  const onPlacesChanged = (property) => {
    
    const searchBox = searchBoxRefs.current[property.key];
    const places = searchBox.getPlaces();
    const place = places && places[0];
    const lat = place?.geometry?.location?.lat() || 0;
    const lng = place?.geometry?.location?.lng() || 0;
    const nameField = `ubicaciones[${property.key}]`;

    if (place) {
      formik.setFieldValue(`${nameField}.select`, true);
      formik.setFieldValue(`${nameField}.latitud`, lat);
      formik.setFieldValue(`${nameField}.longitud`, lng);
      formik.setFieldValue(property.id, place.formatted_address);
      
      const direccionInfo = place.address_components;

      for (let i = 0; i < direccionInfo.length; i++) {
        const componente = direccionInfo[i];
      
        if (componente.types && componente.types.includes('country')) {
          formik.setFieldValue(`${nameField}.pais`, {
            nombre: componente.long_name,
            // id: componente.short_name,
          });
        }
         if (componente.types && componente.types.includes('administrative_area_level_1'))
          formik.setFieldValue(`${nameField}.provincia`, componente.long_name);
         if (componente.types && componente.types.includes('administrative_area_level_2'))
          formik.setFieldValue(`${nameField}.localidad`, componente.long_name);
         if (componente.types && componente.types.includes('postal_code'))
          formik.setFieldValue(`${nameField}.codigoPostal`, componente.long_name);
      }
    }
  };

  const getTextField = (property) => {
    
    const valueProperty = property.multiple
    ? formik.values?.[property.array]?.[property.key]?.[property.field]
    : formik.values[property.id];
    
    const handleChange=(e)=>{
      if(formik.values?.[property.array]?.[property.key].select && 
        property.field=== "direccion"){
        formik.setFieldValue(`ubicaciones[${property.key}].latitud`, null)
        formik.setFieldValue(`ubicaciones[${property.key}].longitud`, null)
        formik.setFieldValue(`ubicaciones[${property.key}].select`, false)
        return formik.handleChange(e)
      }
      return formik.handleChange(e)
    }
  
      return (
        <Grid>
          <CustomFormLabel htmlFor={property.id}>
            {t(`${property.label}`)}
          </CustomFormLabel>
          <CustomTextField
            id={property.id}
            name={property.id}
            onBlur={formik.handleBlur}
            onChange={(e)=>handleChange(e)}
            value={valueProperty}
            error={Boolean(
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                    formik.errors[property.array]?.[property.key]?.[property.field],
            )}
            helperText={
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]
            }
            
            variant="outlined"
            disabled ={!editable}
            placeholder={editable && property.placeholder}
            fullWidth
            autoComplete="off"
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: (theme) => theme.palette.text.primary,
              },
            }}
          />
        </Grid>
      );
   
  };

  const searchBoxField = (property) => {
    return (
      <StandaloneSearchBox
        onLoad={(refer)=>onLoad(refer, property.key)}
        onPlacesChanged={() => {
          onPlacesChanged(property);
        }}
      >

        {getTextField({ ...property }, {
        
        })}
      </StandaloneSearchBox>
    );
  };

  

  return (
    <div>
      {formik.values.ubicaciones?.map((addressObject, index) => {
        const array = [0, 1];
        const key = index;
        const style = {
          marginBottom: 2,
          marginTop: 5,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        };

        return (
          <Grid container spacing={2} key={`addressField_${key}`}>
           { editable &&
              <Grid item xs={12} sx={{ ...style }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => arrayHelpers.remove(index)}
                  endIcon={<DeleteIcon />}
                >
                  Borrar dirección
                </Button>
              </Grid>
            }
            <Grid container xs={12} >
            {array.map((data) => {
              const addressField =
                data === 0 ? 'direccion' :  'referencia' ;
              const addressLabel =
              data === 0 ? `${t('Configuration.Address')} *` :  t('Configuration.Referency') ;

              const property = {
                id: `ubicaciones[${index}].${addressField}`,
                label: addressLabel,
                field: addressField,
                key: index,
                array: 'ubicaciones',
                multiple: true,
                disabled:!editable,
                placeholder:data === 0
                ?  t('Configuration.AddAddress')
                :  t('Configuration.AddReference')
              };
              
              return (
                <>
                  {data === 0
                    ?
                    <Grid item xs={6}  sx={{ paddingLeft: '1rem', paddingRight:"1.5rem" }}>
                     {searchBoxField(property)}
                     </Grid>
                    : 
                    <Grid item xs={6} sx={{ paddingLeft: '2.5rem' }}>
                      {getTextField({ ...property }, formik)}
                      </Grid>
                      }
                </>
              );
            })}
            </Grid>
          </Grid>
        );
      })}
{ editable &&
      <Box style={{ marginBottom: 25, marginTop: 25 }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => arrayHelpers.push({ referencia: '', direccion: '' })}
          endIcon={<AddIcon />}
        >
          {t('Configuration.AddAddre')}
        </Button>
      </Box>
}
    </div>
  );
};

export default AddressField;