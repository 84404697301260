import React, { useContext } from 'react';
import { Grid, Typography, Box, Breadcrumbs, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';

const Breadcrumb = ({
  subtitle,
  items,
  title,
  containerProps = {},
  children,
  esProcedimiento = false,
}) => {
  const {pBreadcrum, mtBreadcrum, mbBreadcrum}= useContext(ScreenSizeContext)

  return <Grid
    container
    sx={{
      p: pBreadcrum,
      mt: mtBreadcrum,
      mb: mbBreadcrum,
      ...containerProps,
    }}
  >
    <Grid item xs={12} sm={12} lg={12}>
      <Typography color="textSecondary" fontWeight="400" variant="h4">
        {subtitle}
      </Typography>

      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {items
          ? items.map((item) => (
              <div key={item.id}>
                {item.to ? (
                  <Link underline="none" color="inherit" component={NavLink} to={item.to} >
                    {item.title}
                  </Link>
                ) : item.subtitle ? (
                  <Link underline="none" color="inherit" >
                    {item.subtitle}
                  </Link>
                ) : (
                  <Typography color="textPrimary">
                    {esProcedimiento ? 'Procedimientos' : item.title}
                  </Typography>
                )}
              </div>
            ))
          : ''}
      </Breadcrumbs>
      <Typography
        fontWeight="700"
        variant="h1"
        fontSize={{xs:24, sm:30 , md:34, lg:38}}
        sx={{
          lineHeight: '1.235',
        }}
      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="flex-start" justifyContent="flex-end">
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Grid>
  </Grid>
};

Breadcrumb.propTypes = {
  subtitle: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Breadcrumb;
