import React, { createContext, useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { API_KEY_GOOGLE_MAP } from '../constants/keys';

const GoogleMapsContext = createContext();

const apiLibraries = ['places', 'geometry'];

export const GoogleMapsProvider = ({ children }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY_GOOGLE_MAP,
    libraries: apiLibraries,
  });

  const [google, setGoogle] = useState(null);

  useEffect(() => {
    if (loadError) setGoogle('Error loading maps');
    if (!isLoaded) setGoogle('Loading Maps');
    if (isLoaded && !loadError) {
      setGoogle(window.google);
    }
  }, [isLoaded, loadError]);

  return <GoogleMapsContext.Provider value={{ google }}>{children}</GoogleMapsContext.Provider>;
};

export default GoogleMapsContext;
