import axios from 'axios';
import { CURRENCY_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getCurrencies = async () => {
  return axios.get(`${CURRENCY_PATH}/listado`);
};

export const createCurrency = async (currency) => {
  return axios.post(`${CURRENCY_PATH}/guardar`, currency);
};
