import { useTranslation } from 'react-i18next';
import useAuth from '../../../../hooks/useAuth';
import barras from '../../../../assets/images/icons/dashboard/grafico-de-barras.png';
import dashboard from '../../../../assets/images/icons/dashboard/menu-lateral-dashboard.png';
import warningSign from '../../../../assets/images/icons/warningSign.png'
import { modules } from '../../../../constants/permissions';
import { CheckSubscrAndPerm } from '../../../../utils/relatedToPermissions';

const Menuitems = () => {
  const [t] = useTranslation();
  const auth = useAuth();
  const p = auth?.allPermissions;
  const { fleet: f } = modules;
  return [
    {
      title: t('Menu.control tower'),
      icon: 'hard-drive',
      href: '/torre-de-control/inicio',
      image: dashboard,
      disabled: !CheckSubscrAndPerm(auth, f, [p?.ROOT, p?.CRE_EMP, p?.LIST_CAR]),
      className: 'control',
    },
    {
      title: t('Control-panel'),
      icon: 'barras',
      href: '/torre-de-control/panelControl',
      image: barras,
      disabled: !CheckSubscrAndPerm(auth, f, [p?.ROOT, p?.CRE_EMP, p?.VER_TORRE]),
      className: '',
    },
    {
      title: t('Incidente.incidentes'),
      icon: 'warningSign',
      href: '/torre-de-control/incidentes',
      image: warningSign,
      disabled: !CheckSubscrAndPerm(auth, f, [p?.ROOT, p?.CRE_EMP, p?.VER_TORRE]),
      className: 'incidents',
    },
  ];
};
export default Menuitems;
