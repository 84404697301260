import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

const CargaCardItem = ({ param, value, secondValue }) => {
  const [t] = useTranslation();
  return (
    <Grid item xs={12} sm={6} lg={4} paddingBottom={2} >
      <Typography variant="h5">{param}</Typography>
      <Typography sx={{minWidth:"220px"}}>{value || `${t('Message.Not specified')}`}</Typography>
      <Typography>{secondValue || ""}</Typography>
    </Grid>
  );
};

export default CargaCardItem;