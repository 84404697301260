import React from 'react';
import { Grid, Popover } from '@mui/material';
import HeaderShorcut from './HeaderShortcut';

const HeaderShortcuts = ({ items, anchorEl, setAnchorEl, authenticatedData }) => {

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id="menu"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Grid
        container
        display="flex"
        sx={{
          flexDirection: items.length > 4 ? 'row' : 'column',
          cursor: 'pointer',
          width: items.length > 4 ? '20rem' : '10rem',
        }}
      >
      {
        items.map((item, index) =>
          <HeaderShorcut
            key = {`header-shorcut-item-${item.title}-${index.toString()}`}
            path = {item.path}
            getPath = {item.getPath}
            handleClose = {handleClose}
            xs = {items.length > 4 ? 6 : 12}
            title = {item.title}
            icon = {item.icon}
            isFeatherIcon = {item.isFeatherIcon}
            isLogout = {item.isLogout}
            authenticatedData = {authenticatedData}
            modulo={item.modulo}
            disabled={item.disabled}
          />
        )
      }
      </Grid>
    </Popover>
  );
};

export default HeaderShortcuts;