import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated, authenticatedData } = useAuth();

  if(!isAuthenticated){
   return <Navigate to = "/auth/login" />
  }

  if(isAuthenticated && authenticatedData && (!authenticatedData.idEmpresa || authenticatedData.estado !== "ACTIVO")){
    return <Navigate to = "/auth/solicitud" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
