import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden, Typography, Grid } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import FormDefault from '../../register/components/FormDefault';
import { CreateItems } from '../mocks/formMock';

const CreateForm = ({
  formik,
  listValues,
  file,
  handleAddFile,
  handleRemoveFile,
  onLoad,
  onPlacesChanged,
}) => {
  const [t] = useTranslation();
  const { logout } = useAuth();

  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (!file) {
      setPreview(null);
      return () => {};
    }

    const objUrl = URL.createObjectURL(file);
    setPreview(objUrl);

    return () => URL.removeObjectURL(objUrl);
  }, [file]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ m: 1, fontWeight: 500 }} align='center'>
          Registrarse a Follow®
        </Typography>
      </Grid>
{/*       <Hidden only="xs">
        <Grid item lg={3} />
      </Hidden> */}
      <Grid item xs={12} lg={12}>
        <FormDefault
          formik={formik}
          formItems={CreateItems(t)}
          listValues={listValues}
          onLoad={onLoad}
          onPlacesChanged={onPlacesChanged}
        />
        <Hidden only="xs">
          <Grid item lg={3} />
        </Hidden>
      </Grid>
    </>
  );
};

export default CreateForm;
