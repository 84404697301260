import React, { useState, useEffect } from 'react';
import { Box, Drawer, Grid, IconButton, Typography, useTheme } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import { formatToDate } from './util/date';
import EnchancedTextButton from './EnchancedTextButton';
import { ViewAttachment } from '../../../../../api/documents';

import { isValidFileType, getProvisionalImage } from './util/file';
import SIN_ARCHIVO_ASOCIADO from '../../../../../assets/images/logos/sin-archivo-asociado.png';

function ModalDrawerDocument({ viewModal, setViewModal, document, options, handleOptions }) {
  const [t] = useTranslation();
  const theme = useTheme();
  const [imgIndex, setImgIndex] = useState(0);

  useEffect(() => {
    if (!viewModal) setImgIndex(0);
  }, [viewModal]);

  return (
    <Drawer
      open={viewModal}
      onClose={() => {
        setViewModal(!viewModal);
      }}
      anchor="right"
      sx={{ zIndex: 1300 }}
    >
      <div
        style={{
          overflowY: 'auto',
          height: '100%',
          width: '50rem',
          backgroundColor: theme.palette.background.default,
          borderLeft: '1px solid #000',
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent="center"
          alignItems="center"
          justifyItems="center"
          alignContent="center"
          rowSpacing={2}
          minHeight={1}
        >
          {document && document.archivos && document.archivos.length > 0 && (
            <Grid item xs={12} align="center" mt={1}>
              <Typography variant="h3">{`${imgIndex + 1} / ${
                document.archivos.length
              }`}</Typography>
            </Grid>
          )}
          <Grid item xs={1} textAlign="right">
            <IconButton
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={() =>
                setImgIndex(imgIndex === 0 ? document.archivos.length - 1 : imgIndex - 1)
              }
              disabled={!document || !document.archivos || document.archivos.length < 2}
            >
              <FeatherIcon icon="chevron-left" size="32" />
            </IconButton>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={
                document && document.archivos && document.archivos.length > 0
                  ? isValidFileType(document.archivos[imgIndex])
                    ? ViewAttachment(document.archivos[imgIndex])
                    : getProvisionalImage(document.archivos[imgIndex])
                  : SIN_ARCHIVO_ASOCIADO
              }
              alt={
                document && document.archivos && document.archivos.length > 0
                  ? t('Loads.Documents_View.Attached_File')
                  : t('Loads.Documents_View.No_Attached_File')
              }
              style={{
                height: '50vh',
                maxWidth:
                  document && document.archivos && document.archivos.length > 0 ? '80%' : '20%',
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item xs={1} textAlign="left">
            <IconButton
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={() =>
                setImgIndex(imgIndex >= document.archivos.length - 1 ? 0 : imgIndex + 1)
              }
              disabled={!document || !document.archivos || document.archivos.length < 2}
            >
              <FeatherIcon icon="chevron-right" size="32" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography
                variant="h2"
                textAlign="center"
                width="80%"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '10',
                  WebkitBoxOrient: 'vertical',
                  wordWrap: 'break-word',
                }}
              >
                - {document?.titulo} -
              </Typography>
              {document && document.nombreUsuario && document.nombreUsuario.length > 0 && (
                <Typography variant="h3" mb={2}>
                  {t('Loads.Documents_View.Created_By')}: {document.nombreUsuario}
                </Typography>
              )}
              {document && document.descripcion && document.descripcion.length > 0 && (
                <Typography mb={2} fontStyle="italic">
                  {document.descripcion}
                </Typography>
              )}
              {document && document.vencimiento && (
                <Typography>
                  {t('Loads.Documents_View.Expires')}:{' '}
                  {formatToDate(new Date(document.vencimiento), 'dd/MM/yyyy')}
                </Typography>
              )}
            </Box>
          </Grid>
          {options && options.length > 0 && (
            <Box width={1} display="flex" justifyContent="space-evenly" mt={3} mb={1}>
              {options.map((option, index) => (
                <EnchancedTextButton
                  text={option}
                  onClick={() => handleOptions(option, document, imgIndex)}
                  style={{ fontSize: '22px', letterSpacing: '2px', width: '70px' }}
                  key={`option-text-button-${option}-${index.toString()}`}
                />
              ))}
            </Box>
          )}
        </Grid>
      </div>
    </Drawer>
  );
}

export default ModalDrawerDocument;
