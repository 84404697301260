import axios from 'axios';
import { CARGO_PATH, BASE_URL, KPI_PEDIDO_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getOrders = async () => {
  return axios.get(`${CARGO_PATH}/listado`);
};

export const getAllOrderWithPagination = async (size, page = 0) => {
  return axios.get(`${CARGO_PATH}/listado?size=${size}&page=${page}`);
};

export const getAllOrderWithSort = async (size, page, sortType1, direction) => {
  return axios.get(
    `${CARGO_PATH}/listado?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const cancel = async (id) => {
  return axios.put(`${BASE_URL}/api/pedido/cancelar/?id=${id}`);
}

export const active = async (id) => {
  return axios.put(`${BASE_URL}/api/pedido/activar/?id=${id}`);
}

export const cancelOS = async(id) => {
  return axios.put(`${CARGO_PATH}/cancelarOS/?id=${id}`);
}

export const getAllOrderWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${CARGO_PATH}/listado?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getOrderDetail = async (id) => {
  return axios.get(`${CARGO_PATH}/detalle/${id}`);
};

export const createOrder = async (order, fleet, idDuplicate, toPost) => {
  let url = `${CARGO_PATH}/guardar?flota=${fleet}`;

  if (idDuplicate) {
    url += `&idDuplicado=${idDuplicate}`;
  }
  if (toPost) {
    url += `&post=${toPost}`;
  }

  return axios.post(url, order);
};

export const updateOrder = async (data) => {
  const { favorito, order } = data;
  const dataParams = data?.favorito
    ? `favorito=${favorito}&favoritos=${data.idFavorite}&publicar=${false}`
    : `favorito=${favorito}&publicar=${true}`;
  return axios.put(`${CARGO_PATH}/editar?${dataParams}`, order);
};

export const updateOrderFP = async (data) => {
  const { order } = data;
  const dataParams = `flotaPropia=${true}&favoritos=${false}&publicar=${false}`;
  return axios.put(`${CARGO_PATH}/editar?${dataParams}`, order);
};

export const updateOrderSave = async (data) => {
  const {  order } = data;
  const dataParams = `publicar=${false}&flotaPropia=${false}&favoritos=${false}`;
  return axios.put(`${CARGO_PATH}/editar?${dataParams}`, order);
};

// publicar en marketplace o en favoritos
export const publishOrder = async (data) => {
  const { favorito, order } = data;
  const dataParams = data?.favorito
    ? `favorito=${favorito}&favoritos=${data.idFavorite}&publicar=${false}`
    : `favorito=${favorito}&publicar=${true}`;
  return axios.post(`${CARGO_PATH}/carga/guardar/publicar?${dataParams}`, order);
};

// guardar en flota propia
export const publishOrderFP = async (data) => {
  const { order } = data;
  const dataParams = `flotaPropia=${true}`;
  return axios.post(`${CARGO_PATH}/carga/guardar/publicar?${dataParams}`, order);
};

export const publishOrderSave = async (data) => {
  const { order } = data;
  const dataParams = `publicar=${false}`;
  return axios.post(`${CARGO_PATH}/carga/guardar/publicar?${dataParams}`, order);
};

export const assignFavorite = async (id, favorito) => {
  return axios.put(`${CARGO_PATH}/asignar-favorito`, null, {
    params: {
      id,
      favorito,
    },
  });
};

export const deleteOrder = async (id) => {
  return axios.delete(`${CARGO_PATH}/eliminar?id=${id}`);
};

export const getAmountOfOrderListWithFilter = async (loadType) => {
  return axios.get(`${CARGO_PATH}/contadorEstado`, { params: { estado: loadType } });
};

export const getOrderList = async () => {
  return axios.get(`${CARGO_PATH}/listar-por-estado`);
};

export const getOrderListWithFilter = async (orderType) => {
  return axios.get(`${CARGO_PATH}/listado/estado`, { params: { q: orderType } });
};

export const getOrderListByTravel = async (idTravel, idCompany) => {
  return axios.get(`${CARGO_PATH}/listarCargaPorViaje?id=${idTravel}&empresa=${idCompany}`);
};

export const getOrderListWithQuery = async (searchParam) => {
  return axios.get(`${CARGO_PATH}/listar-por-estado?search=${encodeURIComponent(searchParam)}`);
};

export const getAllOrders = async () => {
  return axios.get(`${CARGO_PATH}/listado`);
};

export const getOrderForLoad = async (idCarga) => {
  return axios.get(`${CARGO_PATH}/detalle-por-carga`, { params: { idCarga } });
};

export const unassignLoad = async (id) => {
  return axios.put(`${CARGO_PATH}/carga/remover?idCarga=${id}`);
};

// export const createOS = async (OSToSubmit) => {
//   return axios.post(`${CARGO_PATH}/nueva-os`, OSToSubmit);
// };

// flota propia
export const createOS = async (data) => {
  const { order } = data;
  const dataParams = `flotaPropia=${true}`;
  return axios.post(`${CARGO_PATH}/nueva-os?${dataParams}`, order);
};

// publicar en marketplace o en favoritos
export const publishCreateOS = async (data) => {
  const { favorito, order } = data;
  const dataParams = data?.favorito
    ? `favorito=${favorito}&favoritos=${data.idFavorite}&publicar=${false}`
    : `favorito=${favorito}&publicar=${true}`;
  return axios.post(`${CARGO_PATH}/nueva-os?${dataParams}`, order);
};

export const assignOrder = async (newOrder) => {
  return axios.put(`${CARGO_PATH}/asignar`, newOrder);
};

export const getOrderAccepted = async () => {
  return axios.get(`${CARGO_PATH}/listado/aceptado`);
};

export const createOptimizedOrder = async (order) => {
  return axios.post(`${CARGO_PATH}/guardar`, order);
};

export const reAsign = async (order) => {
  return axios.post(`${CARGO_PATH}/reasignar`, order);
};

export const listingByTrip = async (clientId,travelId) => {
  return axios.get(`${CARGO_PATH}/listado-por-viaje?idCliente=${clientId}&idViaje=${travelId}`);
};

export const acceptChanges = async (id) => {
  return axios.put(`${CARGO_PATH}/aceptar-cambio?id=${id}`);
};

export const rejectChanges = async (id, comment) => {
  return axios.put(`${CARGO_PATH}/rechazar-cambio?comentario=${comment}&id=${id}`);
};

export const getKPIOrders = async () => {
  return axios.get(`${KPI_PEDIDO_PATH}/listado`);
}