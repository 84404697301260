export const menuItemProps = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '140px',
  maxWidth: '50px',
};

export const disabledMenuItemProps = {
  cursor: 'not-allowed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '140px',
  maxWidth: '50px',
  color:'grey'
};

export const menuIconsProps = {
  width: '40',
  height: '40',
};

export const menuTypographyProps = {
  variant: 'h4',
  marginTop: '10px',
  fontWeight: '300'
};

export const buttonProps = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  borderRight: '1px solid #e0e0e0',
  borderBottom: '1px solid #e0e0e0',
  ':hover': {
    backgroundColor: 'primary.main',
  },
}

export const disabledButtonProps = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  borderRight: '1px solid #e0e0e0',
  borderBottom: '1px solid #e0e0e0',
  cursor: 'not-allowed',
}