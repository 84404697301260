export const cargoTypes = ['Solida', 'Liquida', 'Gaseosa'];
export const measurementUnit = ['Tonelada', 'Kg'];
export const vehicleTypes = ['Carguero', 'Tipo 1'];
export const trailerTypes = ['Encarpado'];
export const currency = ['Pesos Argentinos', 'Pesos Chilenos'];
export const paymentConditions = ['Transferencia'];
export const features = ['Cierre centralizado'];
export const accessories = ['Lona'];

export const driver = [{ apellido: 'Ruiz' }, { apellido: 'Lopez' }];
export const vehicles = [{ patente: 'AC045LL' }, { patente: 'AC889FE' }, { patente: 'ABC123' }];
export const documentType = [
  { tipoDocumento: 'DNI' },
  { tipoDocumento: 'CNI' },
  { tipoDocumento: 'CI' },
  { tipoDocumento: 'Pasaporte' },
];
export const orderType = [{ tipoOrden: 'Interna' }, { tipoOrden: 'Externa' }];
export const rolSelected = [{ rolSeleccionado: 'Administrador Local' }];

export const WORKFLOW_READ_ACCESS = 'Lectura';
export const WORKFLOW_WRITE_ACCESS = 'Escritura';

export const ALERT_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const WFW_PERMISSION_MSG = {
  SUCESS: 'Permisos editados con exito.',
  ERROR: 'Ocurrio un error en el servidor.',
};

export const WKF_PERMISSION_CONFIRM_DELETE = {
  USER: '¿Desea eliminar todos los permisos del usuario?',
  ORDER: '¿Desea eliminar todos los permisos sobre la orden del usuario?',
};

export const WKF_TEMPLATE_FIELD = {
  SHORT_ANSWER: { nombre: 'Respuesta Corta', id: '1' },
  LONG_ANSWER: { nombre: 'Parrafo', id: '2' },
  MULTIPLE_CHOICE: { nombre: 'Multiple Choice', id: '3' },
  CHECKBOX: { nombre: 'Checkbox', id: '4' },
  MENU: { nombre: 'Menu Desplegable', id: '5' },
  UPLOAD_FILE: { nombre: 'Subir Archivo', id: '6' },
};

export const WKF_INVALID_FILE = 'El archivo es invalido';

export const WKF_VALID_FILE_EXTENSIONS = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];

export const WKF_DOCUMENT_MSG = {
  CREATE: {
    CONFIRM: '¿Desea guardar el documento?',
    SUCESS: 'Documento guardado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  UPDATE: {
    CONFIRM: '¿Desea actualizar el documento?',
    SUCESS: 'Documento editado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  DELETE: {
    CONFIRM: '¿Desea eliminar el documento?',
    SUCESS: 'Documento eliminado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  INVALID_NAME: 'La carpeta ya contiene un documento con el nombre',
  NO_VALUE_ON_REQUIRED_FIELD: 'Falta responder campo/s obligatorio/s.',
  NO_FILENAME: 'El documento no tiene nombre.',
};

export const WKF_TEMPLATE_MSG = {
  CREATE: {
    CONFIRM: '¿Desea guardar el formulario?',
    SUCESS: 'Formulario guardado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  UPDATE: {
    CONFIRM: '¿Desea actualizar el formulario?',
    SUCESS: 'Formulario editado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  DELETE: {
    CONFIRM: '¿Desea eliminar el formulario?',
    SUCESS: 'Formulario eliminado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  FIELD_ERROR: {
    NO_TITLE: 'Sin titulo',
    NO_DESCRIPTION: 'Sin descripcion',
    NO_OPTIONS: 'Sin opciones',
  },
  INVALID_NAME: 'La carpeta ya contiene un formulario con el nombre',
  NO_FIELDS_WARNING: 'El formulario debe contener al menos un campo.',
  TEMPLATE_IN_FOLDER_ALREADY_EXISTING: 'Ya existe una plantilla en la carpeta con el nombre',
  TEMPLATE_ALREADY_EXISTING: 'Ya existe una plantilla con el nombre',
  FOLDER_ALREADY_EXISTING: 'Ya existe una carpeta con el nombre',
  NO_NAME: 'No se ha ingresado un nombre.',
  INVALID_TAG: 'No se ha ingresado una etiqueta',
  SERVER_ERROR: 'Ocurrio un error en el servidor.',
  SUCCESS_CREATE_FOLDER: 'Carpeta creada con exito',
};

export const WORKFLOW_PROCESS_STATUS = {
  COMPLETE: {
    TEXT: 'Completado',
    COLOR: '#66bb6a',
  },
  PENDING: {
    TEXT: 'Pendiente',
    COLOR: '#ffa726',
  },
  EXPIRED: {
    TEXT: 'Vencido',
    COLOR: '#f44336',
  },
  DELAYED: {
    TEXT: 'Demorado',
    COLOR: '#f44336',
  },
};

export const WKF_PROCESS_MESSAGE = {
  CREATE: {
    CONFIRM: '¿Desea guardar el proceso?',
    SUCESS: 'Proceso guardado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  UPDATE: {
    CONFIRM: '¿Desea actualizar el proceso?',
    SUCESS: 'Proceso editado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  DELETE: {
    CONFIRM: '¿Desea eliminar el proceso?',
    SUCESS: 'Proceso eliminado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  FILL: {
    CONFIRM: '¿Desea completar la asignacion con los datos presentados?',
    SUCESS: 'Asignacion complateada con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  RESIGN: {
    CONFIRM: '¿Desea rechazar la asignacion?',
    SUCESS: 'Asignacion rechazada con exito.',
    ERROR: 'Ocurrio un error en el servidor',
  },
  WARNING: {
    INVALID_USERNAME: 'El nombre de usuario ya esta en uso',
    INVALID_NAME: 'La orden ya contiene un proceso con el titulo indicado',
    NO_TITLE: 'Sin titulo',
    NO_TAG: 'Sin etiqueta',
    NO_ORDER: 'Sin orden',
    NO_TYPE: 'Sin tipo de proceso',
    NO_USER: 'Sin usuario asignado',
    NO_STEP: 'Debe contener al menos un paso el proceso',
    INVALID_STEP: 'Faltan completar campos',
  },
};

export const WKF_PROCEDURE_MSG = {
  CREATE: {
    CONFIRM: '¿Desea guardar el procedimiento?',
    SUCESS: 'procedimiento guardado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  UPDATE: {
    CONFIRM: '¿Desea actualizar el procedimiento?',
    SUCESS: 'procedimiento editado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  DELETE: {
    CONFIRM: '¿Desea eliminar el procedimiento?',
    SUCESS: 'procedimiento eliminado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  CREATE_TREE: {
    CONFIRM: 'Crear nuevo arbol de procedimiento',
    SUCESS: 'Arbol de procedimiento guardado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  CREATE_DEPARTMENT: {
    CONFIRM: 'Crear nuevo departamento',
    SUCESS: 'Departamento guardado con exito.',
    ERROR: 'Ocurrio un error en el servidor.',
  },
  WARNING: {
    INVALID_NAME: 'El arbol ya contiene un procedimiento con el titulo indicado',
    NO_TITLE: 'Sin titulo',
    NO_TREE: 'Sin arbol de procedimiento',
    NO_TAG: 'Sin etiqueta',
    NO_ORDER: 'Sin orden',
    NO_TYPE: 'Sin tipo de proceso',
    NO_USER: 'Sin usuario asignado',
    NO_HITO: 'Debe contener al menos un hito el procedimiento',
    INVALID_HITO: 'Faltan completar campos',
  },
};

export const WKF_PERMISSION_FILTER_TYPES = {
  NAME: 'Nombre',
  ETIQUETA: 'Etiquetas',
  NONE: 'Ninguno',
};

export const WKF_ORDER_STATUS = {
  OPEN: 'Abierta',
  CLOSED: 'Cerrada',
  ALL: 'Mostrar todo',
};

export const FILE_TYPES = {
  PNG: {
    type: 'image/png',
    name: 'png'
  },
  JPEG: {
    type: 'image/jpeg',
    name: 'jpeg'
  },
  JPG: {
    type: 'image/jpg',
    name: 'jpg'
  },
  PDF: {
    type: 'application/pdf',
    name: 'pdf'
  },
  DOC: {
    type: "application/msword",
    name: 'doc'
  },
  DOCX: {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    name: 'docx'
  },
  XLS: {
    type: "application/vnd.ms-excel",
    name: 'xls'
  },
  XLSX: {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    name: 'xlsx'
  }
};

export const DEFAULT_VALID_FILE_TYPES = [
  FILE_TYPES.PNG,
  FILE_TYPES.JPEG,
  FILE_TYPES.JPG,
  FILE_TYPES.PDF,
  FILE_TYPES.DOC,
  FILE_TYPES.DOCX,
  FILE_TYPES.XLS,
  FILE_TYPES.XLSX,
]