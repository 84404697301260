import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import esLocale from 'date-fns/locale/es';
import ptBRLocale from 'date-fns/locale/pt-BR';
import enLocale from 'date-fns/locale/en-US';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { tranlateCalender } from '../../redux/customizer/Action';

const TranslationButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const handeActionButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionButton = () => {
    setAnchorEl(false);
  };

  const changeToSpanish = () => {
    i18n.changeLanguage('es');
    dispatch(tranlateCalender(esLocale))
    localStorage.setItem('language', 'es');
    handleCloseActionButton();
  };
  const changeToEnglish = () => {
    i18n.changeLanguage('en');
    dispatch(tranlateCalender(enLocale))
    localStorage.setItem('language', 'en');
    handleCloseActionButton();
    handleCloseActionButton();
  };
  const changeToPortuguese = () => {
    i18n.changeLanguage('pt');
    dispatch(tranlateCalender(ptBRLocale))
    localStorage.setItem('language', 'pt');
    handleCloseActionButton();
    handleCloseActionButton();
  };

  return (
    <Box  >
      <IconButton sx={{ mr: '0.5px', display:'flex'}} onClick={handeActionButton}>
        <Typography sx={{ mr: '1px', mt:'3px' }}>{t("Header.Translation")}</Typography>
        <LanguageIcon />
      </IconButton>
      <Popover
        id="optionsLanguages"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleCloseActionButton}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={changeToSpanish}>
              <ListItemText primary={t("Header.Spanish")}  />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={changeToEnglish} >
              <ListItemText primary={t("Header.English")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={changeToPortuguese} >
              <ListItemText primary={t("Header.Portuguese")} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default TranslationButton;
