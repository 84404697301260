import React from 'react';
import { alpha, Box, Button, Dialog, DialogActions, DialogContent, IconButton, Paper, Slide, Typography, useTheme } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import Scrollbar from '../custom-scroll/Scrollbar';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DialogContainerPlans = ({
  children, open, handleClose, title, loading, dialogProps = {}, maxWidth = 'xl',
  confirmAction = null, disableConfirmAction
}) => {

  const [t] = useTranslation();
  const theme = useTheme();

  function handleCloseOutside (event, reason) {
    if (reason && reason === "backdropClick" && loading) 
      return;
    handleClose();
  }

  return (
    <Dialog
      TransitionComponent = {Transition}
      PaperProps = {{ sx: { borderRadius: '16px' } }}
      onClose = {handleCloseOutside}
      open = {open}
      fullWidth
      maxWidth = {maxWidth}
      scroll = 'paper'
      { ...dialogProps }

    >
      <Box
        id = "modal-container"
        width = {1} p = {1} px = {2}
        sx = {{ 
          display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          backgroundColor: theme.palette.background.default,
          borderBottom: `2px solid ${theme.palette.text.disabled}`
        }}
      >
        <Box display = 'flex' alignItems = 'center'>
          <FeatherIcon icon = "info" color = {theme.palette.primary.main}/>
          <Typography variant = 'h4' ml = {1}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick = { !loading ? handleClose : () => {}}>
          <FeatherIcon icon = "x" />
        </IconButton>
      </Box>

      <Scrollbar>
        <DialogContent sx = {{ backgroundColor: alpha(theme.palette.primary.dark, 0.8) }}>
          
            {children}
    
        </DialogContent>
      </Scrollbar>

      {
        confirmAction &&
        <DialogActions
          sx = {{
            backgroundColor: theme.palette.background.default,
            borderTop: `2px solid ${theme.palette.text.disabled}`
          }}
        >
          <Button
            onClick={handleClose}
            variant="text"
            color="primary"
            size="small" 
            sx={{ pr: 2 }}
            disabled = {loading}
          >
            <Typography variant="h3" sx={{ color: !loading ? theme.palette.primary.main : theme.palette.text.disabled }}>
              {t('Button.Cancel')}
            </Typography>
          </Button>
          <Button
            onClick={confirmAction}
            variant="text"
            color="primary"
            size="small"
            sx={{ pr: 2 }}
            disabled = {disableConfirmAction || loading}
          >
            <Typography
              variant="h3" 
              sx={{
                color: !loading && !disableConfirmAction ? theme.palette.primary.main : theme.palette.text.disabled
              }}
            >
              {t('Button.Confirm')}
            </Typography>
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
};

export default DialogContainerPlans;