import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { Button, CircularProgress } from "@mui/material";
import { FRONT_URL } from "../../config";
import useAuth from "../../hooks/useAuth";
import { choosePlan } from "../../api/subscription";


export default function CheckoutStripe({plan,  setSubscription = null, setStep = null, formik, form}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { authenticatedData } = useAuth();

  const showAlert = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    });
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    try {

      const suscripcion = {
        empresa: { id: authenticatedData?.idEmpresa },
        monto: plan.monto,
        idPromocion: plan.id,
        stripeSubscId: formik === null ? form.stripeSubscId:formik.values.stripeSubscId
        
      }
      if(formik) {
        formik.setFieldValue('suscripcion', suscripcion);
        formik.submitForm();
      } else {
        await choosePlan(suscripcion);
        /* navigate('/configuracion/exito') */
        if (!setSubscription) {
         showAlert(
          `Suscripcion exitosa`,
          'success',
        );
         
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: FRONT_URL,
      },
    });
console.log("error", error)
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
        }
        setSubscription('success')
        setStep(3)
      }
      
    } catch (err) {
      if (!setSubscription) { 
          showAlert(
            err?.response?.data?.error ||
            `Error al realizar la suscripción`,
            'error',
          )
        navigate('/configuracion/rechazado')   
      } else {
        setSubscription('error')
        setStep(3)
      }
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: FRONT_URL,
      },
    });
console.log("error", error)
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.value.email)}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button 
        disabled={isLoading || !stripe || !elements} 
        id="submit" 
        type="submit" 
        variant="contained"
        sx={{
          mt: 2,
          pt: '10px',
          pb: '10px',
        }}>
          <span id="button-text">
            {isLoading ? <CircularProgress size={20} variant="indeterminate" /> : "Pay now"}
          </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}