import { add, format, isValid } from 'date-fns';

export const formatToDateWithoutTimeZone = (date, formatStyle) => {
  return format(add(date, { hours: date.getTimezoneOffset() / 60 }), formatStyle);
};

export const formatToDate = (date, formatStyle) => {
  return format(date, formatStyle);
}

export const checkIfDateIsValid = (date) => {
  return isValid(new Date(date));
}

export function parseDate(date) {
  const findTime = date.indexOf(' ');
  let dateParts;
  if (findTime !== -1)
    dateParts = date.slice(0, findTime).split("/");
  else
    dateParts = date.split("/");
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

export function isSameDate(date1, date2) {
  if (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
    return true;
  return false;
}

export const adjustToLocalTime = (date, f = null) => {
  if (!f)
    return add(date, { hours: date.getTimezoneOffset() / 60 });
  return formatToDateWithoutTimeZone(date, f);
}

export const checkIfSameDay = (date1, date2) => {
  const formatedDate = parseDate(date1);
  return isValid(formatedDate) && isValid(new Date(date2)) && 
         isSameDate(formatedDate, adjustToLocalTime(new Date(date2)));
}

export const checkIfSameDayWithoutParse = (date1, date2) => {
  return isValid(new Date(date1)) && isValid(new Date(date2)) && isSameDate(new Date(date1), adjustToLocalTime(new Date(date2)));
}

export const checkIfParseDateIsValid = (date) => {
  return isValid(parseDate(date));
}