import React from 'react';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const TranslateCalendar = ({ children }) => {
  const calendarLeguaje = useSelector((state) => state.CustomizerReducer.calenderTranslation);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLeguaje}>
      {children}
    </LocalizationProvider>
  );
};

export default TranslateCalendar;
