const searchValueValid = (item, searchValue) => {
    return item.toLowerCase().includes(searchValue.toLowerCase());
}

export const filterData = (searchValue, list) => {
    const listFilter = list.filter(item => {
        const numero = item.pedidos ? item.pedidos[0]?.nroPedido : null;
        return searchValueValid(numero, searchValue)
    })

    if (listFilter.length > 0) {
        return listFilter
    }
    return list
};