import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import useFetch from '../../../hooks/useFetch';
import { getRolList, assignRol } from '../../../api/rol';

const ModalEditUser = ({ 
  userMailEdit,
  userRolEdit,
  userIdEdit,
  open,
  Transition,
  handleClose,
  loadNewInformation,
}) => {
  const [t] = useTranslation();
  const formItems = [
    {
      id: 'email',
      label: t('Configuration.Mail'),
      xs: 4,
    },
    {
      id: 'rolSeleccionadoEdit',
      label: t('Configuration.SelectRole'),
      xs: 4,
    },
  ];

  const [rolList] = useFetch(getRolList);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { rolSeleccionadoEdit: userRolEdit || '' },
    validationSchema: Yup.object().shape({
      rolSeleccionadoEdit: Yup.string().required(t('Loads.Setting.EditRoleRequired')),
    }),
    onSubmit: async () => {
      try {
        await assignRol(userIdEdit, {
          id: formik.values.rolSeleccionadoEdit,
        });
        loadNewInformation();
        formik.resetForm();
        handleClose()
      } catch (error) {
        console.error(new Error(error));
      }
    },
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={()=>{
        formik.resetForm()
        handleClose()
      }}
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" variant="h4">
        {t('Loads.Setting.EditRole')}
        <IconButton style={{ float: 'right', marginTop: -12 }} onClick={()=>{
        formik.resetForm()
        handleClose()
      }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ height: '320px' }}>
        <form onSubmit={formik.handleSubmit}>
          {formItems.map((property, index) => {
            if (property.id === 'rolSeleccionadoEdit') {
              return (
                <Grid key={`form-item-r-${property.id}-${index.toString()}`}>
                  <CustomFormLabel htmlFor={property.id}>{property.label}</CustomFormLabel>
                  <CustomSelect
                    id={property.id}
                    name={property.id}
                    onBlur={formik.handleBlur}
                    onChange={(e)=> {
                      return formik.setFieldValue("rolSeleccionadoEdit",e.target.value)
                    }}
                    value={formik.values[property.id] || ''}
                    error={Boolean(formik.touched[property.id] && formik.errors[property.id])}
                    variant="outlined"
                    fullWidth
                    size="medium"
                    required
                  >
                    {rolList?.length > 0 &&
                      rolList?.map((rol) => {
                        return (
                          <MenuItem key={rol.id} value={rol.id}>
                            {rol.nombre}
                          </MenuItem>
                        );
                      })}
                  </CustomSelect>
                </Grid>
              );
            }
            return (
              <Grid key={`form-item-${property.id}-${index.toString()}`}>
                <CustomFormLabel htmlFor={property.id}>{property.label}</CustomFormLabel>

                <CustomTextField
                  disabled
                  id={property.id}
                  name={property.id}
                  value={userMailEdit || ''}
                  helperText={formik.touched[property.id] && formik.errors[property.id]}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            );
          })}
          <Box>
            <Button
              variant="contained"
              type="submit"
              sx={{
                mt: 4,
                ml: 50,
              }}
            >
              {t('Button.Save')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
ModalEditUser.propTypes = {
  open: PropTypes.bool,
  Transition: PropTypes.object,
  handleClose: PropTypes.func,
};

export default ModalEditUser;
