import axios from 'axios';
import { CONSULTATION} from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const sendConsult = async (formData) => {
    return axios.post(`${CONSULTATION}/guardar`, formData);
};

export const getAllActiveQueriesWithQueryAndPagination = async (
    searchParam,
    size,
    page,
    sortType1,
    direction,
  ) => {
    return axios.get(
      `${CONSULTATION}/listado-page-activas?searchParam=${encodeURIComponent(
        searchParam,
      )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
    );
  };

  export const getActiveQueriesListPaged = async (size, page, sortType1, direction) => {
    return axios.get(
      `${CONSULTATION}/listado-page-activas?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
    );
  };

  export const getAllDeletedQueriesWithQueryAndPagination = async (
    searchParam,
    size,
    page,
    sortType1,
    direction,
  ) => {
    return axios.get(
      `${CONSULTATION}/listado-page-eliminadas?searchParam=${encodeURIComponent(
        searchParam,
      )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
    );
  };

  export const getDeletedQueriesListPaged = async (size, page, sortType1, direction) => {
    return axios.get(
      `${CONSULTATION}/listado-page-eliminadas?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
    );
  };

  export const deleteQuery = async(id) =>{
    return axios.delete(`${CONSULTATION}/eliminar?id=${id}`);
  };

  export const readQuery = async(id) =>{
    return axios.put(`${CONSULTATION}/leida?id=${id}`);
  };

  export const activeQuery = async(id) =>{
    return axios.put(`${CONSULTATION}/activar?id=${id}`);
  };

  export const responseQuery = async(query) => {
    return axios.put(`${CONSULTATION}/responder`, query);
  };