import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, useTheme,  Box, Paper, Button, DialogContent, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';

import useAuth from '../../../hooks/useAuth';
import PageContainer from '../../../components/container/PageContainer';
import ModalEnableModule from '../../plan/components/ModalEnableModule';
import CircularStatic from '../../workflow/documentacion/components/CircularStatic';
import { ModulesToShow } from '../../plan/mocks/items';
import Storage from '../../plan/components/Storage';
import DialogContainer from '../../../components/modals/DialogContainer';
import PlansSection from './PlansSection';

import { choosePlan } from '../../../api/subscription';
import { getCompany, deleteUser } from '../../../api/company';
import { getPromotion, getPromotionsList } from '../../../api/planPromotions';




const Plans = () => {
  const [t] = useTranslation();
  const { authenticatedData, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();


  const theme = useTheme();
  const modulesWhithoutPlans = ModulesToShow();

  const navigate = useNavigate();

  const [choosenModules, setChoosenModules] = useState({
    CARGA: {
      enabled: false,
      plan: null,
      pendingPayment: false,
      tieneBaja: false
    },
    FLOTA: {
      enabled: false,
      plan: null,
      pendingPayment: false,
      tieneBaja: false
    },
    MARKETPLACE: {
      enabled: false,
      plan: null,
      pendingPayment: false,
      tieneBaja: false
    },
  });

  const [moduleSeleceted, setModuleSelected] = useState(false);
  const [openModalEnableModule, setOpenModalEnableModule] = useState(false);
  const [storage, setStorage] = useState();
  const [storagePlan, setStoragePlan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storagePromotions, setStoragePromotions] = useState();
  const [promocion, setpromocion] =useState()

  const [open, setOpen] = useState(false)

  

  const onClose = () =>{
      setOpen(false)
  }
  const onOpen = () =>{
    setOpen(true)
 }
  const title=t("Plans.Delete account")



  function storageEnableModule(moduleToEnable) {
    setModuleSelected(moduleToEnable);
  }

  function handleClickModuleDetails(planClicked) {
    if (planClicked.nombre === 'Custom') navigate('/configuracion/contactanos');
    else navigate(`/configuracion/plan-detail?module=${moduleSeleceted.id}&plan=${planClicked.id}`);
  }

  const choosePromotion = async (idPlan) => {
    const init = async () => {
      try {
        const { data } = await getPromotion(idPlan);
        if (data.monto === 0) {
          const suscripcion = { idPromocion: idPlan, empresa: { id: authenticatedData.idEmpresa } };
          await choosePlan(suscripcion);
          if (moduleSeleceted.id === 'FLOTA') {
            window.location.replace('/flota');
            return;
          }

          if (moduleSeleceted.id === 'CARGA') {
            window.location.replace('/carga/inicio');
            return;
          }
          if (moduleSeleceted.id === 'MARKETPLACE') {
            window.location.replace('/Marketplace');
            return;
          }
        }
        navigate(`/configuracion/pay/${idPlan}?monto=${data.monto}`);
      } catch (error) {
        console.log(error);
      }
    };
    init();
  };

  const getStoragePromotions = async () => {
    try {
      const { data } = await getPromotionsList('ALMACENAMIENTO');
      setStoragePromotions(data);
    } catch (e) {
      console.log("error",e);
    }
  };

  const dataCompapy = async (idCompany) => {
    try {
      const { data } = await getCompany(idCompany);
      setpromocion(data.promocion)
      setStorage(data?.almacenado);
      setStoragePlan(data?.almacenamientoPlan);
      if (data.promocion?.length > 0) {
        let choosenModulesWithPlans = { ...choosenModules };
        for (let i = 0; i < data.promocion.length; i++) {
          choosenModulesWithPlans = {
            ...choosenModulesWithPlans,
            [data.promocion[i].tipoPromocion]: {
              enabled: true,
              plan: data.promocion[i].id,
              tieneBaja: data.promocion[i].tieneBaja,
              pendingPayment: data.promocion[i].pendientePago
            },
          };
        }
        setChoosenModules(choosenModulesWithPlans);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const planesActivos = modulesWhithoutPlans?.map((module) => {
    const isActive =
      choosenModules && choosenModules[module.id] && choosenModules[module.id].enabled;
    const choosenPlanId = isActive && choosenModules[module.id].plan;
    return choosenPlanId && module.plans.find((p) => p.id === choosenPlanId);
  });

  const planActivo = planesActivos?.find((objeto) => objeto?.tipoPromocion === moduleSeleceted.id);

  useEffect(() => {
    if (authenticatedData) {
      dataCompapy(authenticatedData.idEmpresa);
    }
    getStoragePromotions();
  }, [authenticatedData]);

  const hanldeEliminar = async() =>{
    try {
      await deleteUser(authenticatedData.id)
      onClose()
      logout()
      enqueueSnackbar(t("Plans.account deleted message"), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 8000,
      });
    
    } catch (error) {
      console.log("error")
      onClose()
      enqueueSnackbar(t("Plans.Could not delete account"), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
      
    }
   
  }

  return (
    <>
      <PageContainer title="Bienvenido a Follow" description="Seleccion de planes" >
        <Grid container spacing={4} display="flex" alignItems="stretch" >

          {/*  nuevo complementos de planes */}
        
          <Grid item xs={12} spacing={2} marginY="1rem">
         <PlansSection promocion={promocion} />
         </Grid>
        </Grid>

        <Divider/>

        <ModalEnableModule
          open={openModalEnableModule}
          handleClose={() => setOpenModalEnableModule(false)}
          handleClick={handleClickModuleDetails}
          handleContinue={choosePromotion}
          loading={loading}
          plans={moduleSeleceted?.plans || []}
          selectedPlan={moduleSeleceted && moduleSeleceted.plan}
          planActivo={planActivo}
        />
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6}>
            <Paper
              variant="outlined"
              sx={{
                p: 2, 
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                mt: 2,
                mb: 2,
                borderTop: `16px solid ${theme.palette.warning.main}`,
                borderBottom: `16px solid ${theme.palette.warning.main}`,
              }}
            >
              <Typography variant="h2" sx={{ mb: 2 }}>
                {t('Configuration.StorageSpace')}
              </Typography>
              {storagePlan === 0 ? (
                <Box sx={{ wordBreak: 'break-word' }}>
                  <CircularStatic progress={0} />
                  <Typography variant="h2" sx={{ mt: 2 }}>
                    0 mb {t('Login.of')} {storagePlan} GB
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ wordBreak: 'break-word' }}>
                  <CircularStatic progress={(storage / (storagePlan * 1024)) * 100} />
                  <Typography variant="h3" sx={{ mt: 4 }}>
                    {Math.round(storage)} mb {t('Login.of')} {storagePlan} GB
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} >    
            <Storage
              promotions={storagePromotions}
              handleEnableModule={storageEnableModule}
              choosenModules={choosenModules}
              handleContinue={choosePromotion}
            />
          </Grid>
          
        </Grid>
        <Grid  xs={12} sx={{paddingTop:"3rem", marginRight:"2rem"}} textAlign="end">
        <Button variant="contained" color="error" onClick={onOpen} sx={{width:{sm:"500px"}}}>
          {t("Plans.Delete account")}
         </Button>
        </Grid>

       <DialogContainer
       open = {open}
       handleClose = {onClose}
       title = {title}
       dialogProps = {{
         width: 'md',
         maxWidth: 'md',
       }}
       >
        <DialogContent>
          <Grid textAlign="center">
            <Typography variant='h2'>
              {t("Plans.Are you sure you want to delete your account?")}
            </Typography>
            <Typography variant='h3' sx={{py:"1rem"}}>
            {t("Plans.This action cannot be undone and all users and activity history will no longer be accessible.")}
            </Typography>
  
            <Grid container xs={12} mt="1rem" textAlign="center" >
              
              <Grid item xs={12} sm={6}  sx={{py:"1rem"}}>
                <Button variant="contained"  onClick={onClose}>
                  {t("Button.Cancel")}
                </Button>
              </Grid>

              <Grid item xs={12} sm={4} sx={{py:"1rem"}}>
                <Button  variant="contained" color="error"  onClick={hanldeEliminar}>
                  {t("Button.Confirm")}
                </Button>
              </Grid >
                
            </Grid>
          </Grid>
        </DialogContent>

       </DialogContainer>
      </PageContainer>
    </>
  );
};

export default Plans;
