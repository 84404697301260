import React from 'react';
import {  TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';


import ContraOferta from './ContraOferta';
import Compartir from './Compartir';


import { guardarContraoferta,aceptarAsignacion } from '../../../../../api/contraoferta';
import { hacerPregunta, responderPregunta } from '../../../../../api/preguntaMarket';
import { getCurrencies } from '../../../../../api/currency';

// TO DO
// Hay que crear una vista nueva de perfil que le pegue a
// empresa id

export const useActionHook = (publicacion, loadOrder, info) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const actionContactar = () => {
    const currentURL = window.location.pathname;
    if (currentURL.startsWith('/marketplace')) navigate(`/marketplace/contaco/${info}`);
    else navigate(`/flota/contacto/${info}`);
  };

  async function getCurrencyId(symbol) {
    try {
      const { data } = await getCurrencies();
      const currencyFound = data.find(c => c.simbolo === symbol);
      if (currencyFound) return currencyFound.id;
      return false;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  }

  const actionAceptar = async (order, cbOnError) => {
    const publicacionAAceptar = order || publicacion;
    try {
      const idMoneda = await getCurrencyId(publicacionAAceptar.moneda);
      if (!idMoneda) throw new Error();
      await guardarContraoferta({
        idMoneda,
        idCarga: publicacionAAceptar.id,
        isFavorito: true,
        valor: publicacionAAceptar.valor
      });
      enqueueSnackbar(t('Marketplace.Actions.Upload accepted successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
      navigate("/flota/os")
    } catch (e) {
      if (e?.response?.data?.error && cbOnError) {
        cbOnError('Informacion', e.response.data.error);
        return;
      }
      enqueueSnackbar(t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    }
  };
  const actionAceptar2 = async (order, cbOnError) => {
    const publicacionAAceptar = order || publicacion;
    try {
      const idMoneda = await getCurrencyId(publicacionAAceptar.moneda);
      if (!idMoneda) throw new Error();
      const respuesta = await aceptarAsignacion({
        idMoneda,
        idCarga: publicacionAAceptar.id,
        isFavorito: true,
        valor: publicacionAAceptar.valor
      });
      enqueueSnackbar(t('Marketplace.Actions.Upload accepted successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
      navigate(`/flota/detallePedido/${publicacionAAceptar.id}`);
    } catch (e) {
      if (e?.response?.data?.error && cbOnError) {
        cbOnError('Informacion', e.response.data.error);
        return;
      }
      enqueueSnackbar(t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    }
  };
  const actionPreguntar = async (order) => {
    const publicacionAPreguntar = order || publicacion;
    const nuevaPregunta = document.getElementById('pregunta').value;
    try {
      await hacerPregunta({ pregunta: nuevaPregunta }, publicacionAPreguntar.id);
      enqueueSnackbar(t('Marketplace.Actions.OnSuccessQuestion'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });     
      await loadOrder();
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    }
  };

  const actionResponder = (order) => {
    const publicacionAResponder = order || publicacion;
    const preguntas = publicacionAResponder.preguntas.filter((unaPregunta) => !unaPregunta.respuesta); 
    preguntas.forEach((unaPregunta) => {
      const pregunta = { ...unaPregunta };
      pregunta.respuesta = document.getElementById(`respuesta ${unaPregunta.id}`).value;
      responderPregunta(pregunta, publicacionAResponder.id);
    });
  };

  const actionVerOferente = () => {
    navigate('home');
  };

  const actionContraoferta = async (order) => {
    const publicacionAOfertar = order || publicacion;
    const valor = document.getElementById('valor').value;
    const comentarios = document.getElementById('comentario').value;
    const moneda = document.getElementById('Moneda').value;
    const incluyeIva = document.getElementById('switchIva').checked;

    try {
      await guardarContraoferta({
        valor,
        idMoneda: moneda,
        comentarios,
        incluyeIva,
        idCarga: publicacionAOfertar.id,
      });
      enqueueSnackbar(t('Marketplace.Actions.OnSuccessCounteroffer'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.error || t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    }
  };

  const aceptar = {
    name: 'Aceptar',
    header: t('Marketplace.Actions.AcceptOfferHeader'),
    title: t('Button.Request'),
    action: actionAceptar,
  };
  const aceptar2 = {
    name: 'Aceptar',
    header: t('Marketplace.Actions.AcceptOfferHeader'),
    title: t('ActionListTable.Accept'),
    action: actionAceptar2,
  };
  const contactar = {
    name: 'Contactar',
    header: t('Button.Contact'),
    // body: <Contacto info={info}/>,
    title: t('Button.Contact'),
    action: actionContactar,
  };
  const preguntar = {
    name: 'Preguntar',
    header:  t('Marketplace.Actions.AskHeader'),
    body: <TextField id="pregunta" multiline maxRows={4} fullWidth />,
    title: t('Button.Ask'),
    action: actionPreguntar,
  };
  const contraoferta = {
    name: 'Contraoferta',
    title: t('Button.Offer'),
    header: t('Marketplace.Actions.CountertofferHeader'),
    body: <ContraOferta publicacion={publicacion} />,
    action: actionContraoferta,
  };
  const compartir = {
    name: 'Compartir',
    title: t('Button.Share'),
    header: t('Marketplace.Actions.LoadLink'),
    body: <Compartir />,
  };
/*   const responder = {
    title: 'Responder',
    header: 'Preguntas',
    body: <Responder publicacion={publicacion} />,
    action: actionResponder,
  }; */
  const verOferente = {
    name: 'Ver oferente',
    title: t('Button.ViewBidder'),
    header: t('Marketplace.Actions.LoadLink'),
    // body: <Compartir />,
    action: actionVerOferente,
  };
  const verRespuesta = {
    name: 'Ver respuestas',
    title: t('Button.ViewAnswers'),
    header: t('Marketplace.Actions.LoadLink'),
    body: <Compartir />,
    action: actionResponder,
  };

  return {
    aceptar,
    aceptar2,
    contactar,
    preguntar,
    contraoferta,
    compartir,
   /*  responder, */
    verRespuesta,
    verOferente,
  };
};
