import { Button, DialogActions, Grid, Typography } from '@mui/material'
import React from 'react'
import TableCard from '../../../../../components/table-card/TableCard'

function ModalAction({ action, onClose }) {
  return (
    <TableCard title={action.header} custompadding="2" customMargin="0" customWidth={600}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={11}>
          {action.body}
        </Grid>
        {action && (
          <Grid item xs={12}>
            <DialogActions>
              {action.action && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    onClose();
                    action.action();
                  }}
                >
                  <Typography variant="body1">{action.buttonLabel || action.title}</Typography>
                </Button>
              )}
            </DialogActions>
          </Grid>
        )}
      </Grid>
    </TableCard>
  );
}

export default ModalAction