import * as Yup from 'yup';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const FormProfileValidation = (t) => {
  const yup = Yup.object().shape({
    nombre: Yup.string()
      .matches(/^\D+$/, t('Validation.Name-NoNumbers'))
      .min(4, t('Validation.La cant min es 4'))
      .max(60, t('Validation.La cant max es 60'))
      .required(t('Poster.Required-field')),
      apellido: Yup.string()
      .matches(/^[A-Za-záéíóúüñÁÉÍÓÚÜÑ\s]+$/, t('Validation.LastName-NoNumbers'))
      .min(4, t('Validation.La cant min es 4'))
      .max(60, t('Validation.La cant max es 60'))
      .required(t('Poster.Required-field')),    
    pais: Yup.object().nullable(),
    telefono: Yup.string().matches(phoneRegExp, t('Validation.Num tel no val')).nullable(),
  });

  return yup;
};

export const FormPasswordValidation = (t) => {
  const yup = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, t('Validation.Mínimo 6 caracteres'))
      .max(255)
      .required(t('Validation.La contraseña es requerida')),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('Validation.Las contraseñas no coinciden'))
      .required(t('Validation.La contraseña es requerida')),
  });
  return yup;
};
