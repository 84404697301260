import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoLight } from '../../../assets/images/logos/logo-login.svg';
import useAuth from '../../../hooks/useAuth';

const LogoIcon = () => {
  const customizer = useSelector((state) => state.CustomizerReducer);
  const { authenticatedData } = useAuth();
  const navigate = useNavigate();

  const handleLogoClick = (event) => {
    event.preventDefault();

    if (authenticatedData 
      && authenticatedData.rol 
      && authenticatedData?.rol?.id !== "5") {
      navigate('/')
    } else {
      navigate('/configuracion/usuario')
    }
  };

  return (
    <Link underline="none" to="/" onClick={handleLogoClick}>
      {customizer.activeMode === 'dark' ? <LogoLight /> : <LogoLight />}
    </Link>
  );
};

export default LogoIcon;
