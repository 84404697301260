import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  Typography,
  Chip,
  useTheme,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

const DefaultTransition = React.forwardRef((props, ref) => <Fade ref={ref} {...props} />);

const ModalConfirm = ({
  open,
  handleClose,
  handleConfirm,
  msg,
  title,
  disabled,
  showOnlyConfirm = false,
  data = null,
  sxContentTypography = {},
}) => {

  const [t] = useTranslation();
  const theme = useTheme();

  const handleCloseOutside = (event, reason) => {
    if (reason && reason === 'backdropClick' && disabled) return;
    handleClose();
  };

  function getFleetTypeTranslated(asset) {
    return asset.tipoRemolque
      ? t('Fleet.My-assets.Trailer')
      : asset?.tipoCamion
      ? t('Fleet.My-assets.Truck')
      : asset?.tipoBitren
      ? t('Fleet.My-assets.Bitren')
      : '';
  }

  function getVehicleType(vehicle) {
    const fleet = vehicle.remolque || vehicle.vehiculo || vehicle;
    return fleet.tipoRemolque ? 'A' : fleet?.tipoCamion ? 'C' : fleet?.tipoBitren ? 'B' : 'Z';
  }

  function sortVehiclesByType(a, b) {
    const aType = getVehicleType(a);
    const bType = getVehicleType(b);

    if (bType < aType) {
      return -1;
    }
    if (bType > aType) {
      return 1;
    }
    return 0;
  }

  function getVehicleLabel(vehicle) {
    const fleet = vehicle.remolque || vehicle.vehiculo || vehicle;
    return `${getFleetTypeTranslated(fleet, t)} - ${fleet.dominio?.toUpperCase() || ''}`;
  }

  function getColorLabel(vehicle) {
    const type = getVehicleType(vehicle);
    return type === 'A'
      ? theme.palette.info.main
      : type === 'B'
      ? theme.palette.error.main
      : type === 'C'
      ? theme.palette.success.main
      : theme.palette.text.disabled;
  }

  return (
    <Dialog
      TransitionComponent={DefaultTransition}
      open={open}
      onClose={handleCloseOutside}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      aria-labelledby="titulo-formulario"
      aria-describedby="scroll-dialog-description"
    >
      <Box
        id="titulo-formulario"
        width={1}
        p={2}
        pt={1}
        pb={1}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box display="flex" alignItems="center">
          <FeatherIcon icon="info" color={theme.palette.primary.main} />
          <Typography variant="h3" ml={1}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={handleClose} disabled={disabled}>
          <FeatherIcon icon="x" />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box id="formulario-descripcion">
          <Box mx={2} display="flex" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'pre-line',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '100',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                ...sxContentTypography,
              }}
            >
              {msg}
            </Typography>
          </Box>
        </Box>
        {data != null && (
          <Box mx={2} mt={1} display="flex" alignItems="center" sx={{ flexFlow: 'wrap' }}>
            {data.sort(sortVehiclesByType).map((d) => {
              return (
                <Chip
                  sx={{
                    backgroundColor: getColorLabel(d),
                    color: '#fff',
                    borderRadius: '6px',
                    mb: 0.5,
                    mr: 0.5,
                    ml: 0.5,
                  }}
                  size="small"
                  label={getVehicleLabel(d)}
                  key={d?.id}
                />
              );
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!showOnlyConfirm && (
          <Button
            onClick={handleClose}
            variant="text"
            color="primary"
            size="small"
            sx={{ pr: 2 }}
            disabled={disabled}
          >
            <Typography
              variant="h3"
              sx={{ color: !disabled ? theme.palette.primary.main : theme.palette.text.disabled }}
            >
              {t('Button.Cancel')}
            </Typography>
          </Button>
        )}
        <Button
          onClick={handleConfirm}
          variant="text"
          color="primary"
          size="small"
          sx={{ pr: 2 }}
          disabled={disabled}
        >
          <Typography
            variant="h3"
            sx={{ color: !disabled ? theme.palette.primary.main : theme.palette.text.disabled }}
          >
            {t('Button.Confirm')}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
