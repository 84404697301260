import React, { useState } from 'react';
import { Typography, Divider, Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { verifyDiscountCode } from '../../api/discountCode';

import CustomTextField from '../forms/custom-elements/CustomTextField';

const PlanDetail = ({ promo, title, total, setTotal, setCodigoDescuento }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [discountCode, setDiscountCode] = useState('');
  const [discountApplied, setDiscountApplied] = useState(false);
  const [code, setcode] = useState();

  const urlObjeto = new URL(window.location.href);
  const urlPathname = urlObjeto.pathname;
  const notShowCode = urlPathname.startsWith('/configuracion/pay');

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const handleApplyDiscount = async () => {
    try {
      const resp = await verifyDiscountCode(discountCode, promo.id);
      if (!resp.data) {
        showMessage('El codigo ingresado es incorrecto', 'error');
        setDiscountCode('');
        setDiscountApplied(false);
        return;
      }
      if (resp.data.usos === 0) {
        showMessage('Este código de descuento ya ha sido canjeado previamente', 'error');
        setDiscountCode('');
        setDiscountApplied(false);
        return;
      }
      const ApplyDiscount = promo.monto - (promo.monto * resp.data.porcentajeDescuento) / 100;
      setTotal(ApplyDiscount);
      /* setTotalDesc(ApplyDiscount) */
      setcode(resp.data);
      setCodigoDescuento(discountCode);
      setDiscountApplied(true);
      showMessage('Codigo de descuento apliacado', 'success');
      setDiscountCode('');
    } catch (error) {
      showMessage(error?.response?.data?.error || `${t('Errors.Server')}`, 'error');
      setDiscountCode('');
      setDiscountApplied(false);
    }
  };

  const gridContainerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  };

  const gridItemStyles = {
    width: '100%',
    border: '3px solid rgb(255,191,60)',
    borderRadius: '0.5rem',
    padding: '1rem',
    marginBottom: '1rem',
  };

  const textContainerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    fontWeight: 700,
  };

  return (
    <>
      {promo && (
        <Box sx={gridContainerStyles}>
          <Box sx={gridItemStyles}>
            <Typography variant="h3" component="h3">
              {title || t('subscriptions.Plan a contratar')}
            </Typography>
            <Divider />
            <Box sx={textContainerStyles}>
              <Typography variant="h3">{t('Configuration.Name')}</Typography>
              <Typography variant="h3">{promo.nombre}</Typography>
            </Box>
            <Divider />
            <Box sx={textContainerStyles}>
              <Typography variant="h3">
                {t('subscriptions.Storage_space_included_for_documentation')}
              </Typography>
              <Typography variant="h3">{promo.almacenamiento}GB</Typography>
            </Box>
            <Divider />
            <Divider />
            <Box sx={textContainerStyles}>
              <Typography variant="h3">{t('subscriptions.monthly amount included')}</Typography>
              <Typography variant="h3">{promo.cantidadAOperar}</Typography>
            </Box>
            <Divider />
            {promo.diasAlmacenamiento === null ? (
              ''
            ) : (
              <>
                <Divider />
                <Box sx={textContainerStyles}>
                  <Typography variant="h3">
                    {t('subscriptions.Storage time digital documents')}
                  </Typography>
                  <Typography variant="h3">
                    {promo.diasAlmacenamiento} {t('subscriptions.days')}
                  </Typography>
                </Box>
                <Divider />
              </>
            )}
            <Divider />
            <Box sx={textContainerStyles}>
              <Typography variant="h3">{t('subscriptions.training')}</Typography>
              <Typography variant="h3">{promo.entrenamiento} hs</Typography>
            </Box>
            <Divider />
            <Divider />
            <Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="h3"
                    sx={{
                      padding: '1rem 0',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Total
                  </Typography>
                </Grid>
                {!discountApplied && (
                  <Grid item xs={6}>
                    <Typography
                      variant="h3"
                      sx={{ color: 'green', textAlign: 'end', padding: '1rem 0' }}
                    >
                      ${promo.monto}
                    </Typography>
                  </Grid>
                )}
                {discountApplied && (
                  <Grid item xs={6} sx={{ padding: '1rem 0' }}>
                    <Grid>
                      <Typography variant="h3" sx={{ color: 'red', textAlign: 'end' }}>
                        {' '}
                        Precio sin descuento
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{ textDecoration: 'line-through', color: 'red', textAlign: 'end' }}
                      >
                        ${promo.monto}
                      </Typography>
                    </Grid>
                    <Divider />
                    <Grid>
                      <Typography variant="h3" sx={{ color: 'green', textAlign: 'end' }}>
                        {' '}
                        Precio actual{' '}
                      </Typography>
                      <Typography variant="h3" sx={{ color: 'green', textAlign: 'end' }}>
                        ${total}
                      </Typography>
                      <Typography variant="h3" sx={{ color: 'green', textAlign: 'end' }}>
                        {' '}
                        El codigo ingresado tiene un {code?.porcentajeDescuento}% descuento
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
            <>
              {title !== 'Plan actual' && (
                <>
                  <Divider />
                  <Grid container sx={{ mt: '1rem' }}>
                    <Grid item xs={6}>
                      <CustomTextField
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        sx={{ width: '15rem' }}
                        onClick={handleApplyDiscount}
                        disabled={!discountCode}
                      >
                        Aplicar código de descuento
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PlanDetail;
