import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { alpha, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';

const StyledMenu = styled(({ children, ...other }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...other}
  >
    {children}
  </Menu>
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiList-root': {
      padding: '4px 0',
    },
    '& .MuiListItem-root': {
      ...theme.typography.body1,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const ActionsListRole = ({
  handleView,
  handleEdit,
  handleDelete,
  handleViewUsers,
}) => {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* <Tooltip disableInteractive> */}
      <IconButton onClick={handleOpenMenu}>
        <MoreVertIcon />
      </IconButton>
      {/* </Tooltip> */}

      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {handleView && (
          <MenuItem
            id="view"
            onClick={() => {
              handleView();
              handleCloseMenu();
            }}
          >
           {t("Button.See")}
          </MenuItem>
        )}

        {handleEdit && (
          <MenuItem
            id="edit"
            onClick={() => {
              handleEdit();
              handleCloseMenu();
            }}
          >
            {t("Button.Edit")}
          </MenuItem>
        )}

        {handleDelete && (
          <MenuItem
            id="eliminar"
            onClick={() => {
              handleDelete();
              handleCloseMenu();
            }}
          >
            {t("Button.Delete")}
          </MenuItem>
        )}

        {handleViewUsers && (
          <MenuItem
            id="verUsuarios"
            onClick={() => {
              handleViewUsers();
              handleCloseMenu();
            }}
          >
           {t("Button.See-user")}
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

ActionsListRole.propTypes = {
  handleView: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleViewUsers: PropTypes.func
};

export default ActionsListRole;
